import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { register } from "../redux/actions/authAction";

const Register = () => {
	const { auth, alert } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();

	const initialState = {
		fullname: "",
		// username: "",
		email: "",
		password: "",
		cf_password: "",
		gender: "male",
	};
	const [userData, setUserData] = useState(initialState);
	const { fullname, username, email, password, cf_password } = userData;

	const [typePass, setTypePass] = useState(false);
	const [typeCfPass, setTypeCfPass] = useState(false);
	const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);

	useEffect(() => {
		if (auth.token) history.push("/");
	}, [auth.token, history]);

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(register(userData));
	};

	const handleCloseProfilePhotoModal = () => {
		setShowProfilePhotoModal(false);
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 8,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};

	return (
		// newcode
		// <>
		//   <Modal
		//     show={showProfilePhotoModal}
		//     onHide={handleCloseProfilePhotoModal}
		//     dialogClassName="modal-sm-30px"
		//     size="md"
		//     backdropClassName="childmodal"
		//     backdrop="static"
		//     centered
		//     // onEntered={() => props.handleEntered}
		//   >
		//     <Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
		//       <div className="row">
		//         <div className="col-11"></div>
		//         <div
		//           className="col-1"
		//           style={{ padding: "15px", cursor: "pointer" }}
		//           onClick={() => handleCloseProfilePhotoModal()}
		//         >
		//           <img src="./images/closeOne.png"></img>
		//         </div>
		//       </div>
		//       <div style={{ padding: "0px 30px 30px 30px" }}>
		//         <div
		//           style={{
		//             textAlign: "center",
		//             fontSize: "28px",
		//             fontWeight: "500",
		//             color: "#20ce8f",
		//             marginBottom: "10px",
		//           }}
		//         >
		//           Verify Account
		//         </div>
		//         <div>
		//           <input
		//             type="email"
		//             className="form-control"
		//             disabled
		//             style={{ background: "#fff" }}
		//             defaultValue="lokesh@gmail.com"
		//           ></input>
		//         </div>
		//         <div style={{ textAlign: "center", paddingTop: "10px" }}>
		//           Enter verification code
		//         </div>
		//         <div style={{ display: "flex", justifyContent: "center" }}>
		//           <input
		//             type="email"
		//             className="form-control"
		//             style={{ width: "40px", margin: "5px" }}
		//           ></input>
		//           <input
		//             type="email"
		//             className="form-control"
		//             style={{ width: "40px", margin: "5px" }}
		//           ></input>
		//           <input
		//             type="email"
		//             className="form-control"
		//             style={{ width: "40px", margin: "5px" }}
		//           ></input>
		//           <input
		//             type="email"
		//             className="form-control"
		//             style={{ width: "40px", margin: "5px" }}
		//           ></input>
		//         </div>
		//         <div
		//           style={{
		//             display: "flex",
		//             justifyContent: "center",
		//             paddingTop: "20px",
		//           }}
		//         >
		//           <Button
		//             style={{
		//               padding: "5px",
		//               background: "#20ce8f",
		//               border: "none",
		//             }}
		//           >
		//             Verify Code
		//           </Button>
		//         </div>
		//       </div>
		//     </Modal.Body>
		//   </Modal>
		//   <div style={{ backgroundColor: "#f3f2ef", height: "100vh" }}>
		//     <h4 style={{ fontSize: "28px", padding: "20px" }}>Krishigap</h4>
		//     <div className="row">
		//       <div className="col-6">
		//         <Carousel
		//           responsive={responsive}
		//           arrows={false}
		//           showDots={true}
		//           autoPlay={true}
		//           autoPlaySpeed={2000}
		//           infinite
		//         >
		//           <div>
		//             <img
		//               src="./images/Connect-img.svg"
		//               style={{ width: "400px" }}
		//             ></img>
		//             <div>
		//               <span style={{ color: "#20ce8f" }}>Connect</span>&nbsp;with
		//               peers and experts
		//             </div>
		//           </div>
		//           <div>
		//             <img
		//               src="./images/Access-img.svg"
		//               style={{ width: "400px" }}
		//             ></img>
		//             <div>
		//               <span style={{ color: "#20ce8f" }}>Access</span>&nbsp;
		//               exclusive digital resources
		//             </div>
		//           </div>
		//           <div>
		//             <img
		//               src="./images/Share-img.svg"
		//               style={{ width: "400px" }}
		//             ></img>
		//             <div>
		//               <span style={{ color: "#20ce8f" }}>Share</span>&nbsp;
		//               knowledge and experience
		//             </div>
		//           </div>
		//         </Carousel>
		//       </div>
		//       <div className="col-4">
		//         <div className="card" style={{ padding: "30px", border: "none" }}>
		//           <div
		//             style={{
		//               textAlign: "center",
		//               fontSize: "28px",
		//               fontWeight: "500",
		//             }}
		//           >
		//             Create your account
		//           </div>
		//           <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
		//             <input
		//               type="email"
		//               className="form-control"
		//               placeholder="Email address"
		//             ></input>
		//           </div>
		//           <div className="row">
		//             <div className=""></div>
		//             <div className="col pb-eform">
		//               <div className="form-outline">
		//                 <label
		//                   className="f-fm fm-w4-s40 color-7  form-label form"
		//                   htmlFor="form3Example97"
		//                   style={{ marginLeft: "0px" }}
		//                 >
		//                   Password
		//                 </label>
		//                 <div
		//                   className="input-group mb-3"
		//                   style={{ cursor: "pointer" }}
		//                 >
		//                   <input
		//                     type={typePass ? "text" : "password"}
		//                     id="txtPassword"
		//                     name="password"
		//                     className="form-control"
		//                     // value={state.password}
		//                     // onChange={handlePasswordChange}
		//                   />
		//                   <div className="">
		//                     <span
		//                       className="input-group-text"
		//                       onClick={() => setTypePass(!typePass)}
		//                     >
		//                       {typePass === true ? (
		//                         <i className="fa fa-eye" id="show_eye"></i>
		//                       ) : (
		//                         <i className="fa fa-eye-slash" id="show_eye"></i>
		//                       )}
		//                     </span>
		//                   </div>
		//                 </div>
		//                 <div className="form-notch">
		//                   <div
		//                     className="form-notch-leading"
		//                     style={{ width: "9px" }}
		//                   ></div>
		//                   <div
		//                     className="form-notch-middle"
		//                     style={{ width: "56px" }}
		//                   ></div>
		//                   <div className="form-notch-trailing"></div>
		//                 </div>
		//               </div>
		//             </div>
		//             <div className=""></div>
		//           </div>
		//           <div
		//             style={{
		//               textAlign: "center",
		//               fontSize: "15px",
		//               fontWeight: "400",
		//               paddingTop: "10px",
		//             }}
		//           >
		//             By clicking Agree & Join, you agree to the uLektz{" "}
		//             <span style={{ color: "#20ce8f", textDecoration: "underline" }}>
		//               User Agreement, Privacy Policy
		//             </span>{" "}
		//             and&nbsp;
		//             <span style={{ color: "#20ce8f", textDecoration: "underline" }}>
		//               Cookie Policy
		//             </span>{" "}
		//           </div>
		//           <div style={{ paddingTop: "10px" }}>
		//             <Button
		//               style={{
		//                 width: "-webkit-fill-available",
		//                 background: "#20ce8f",
		//                 border: "none",
		//                 padding: "15px",
		//                 fontWeight: "500",
		//               }}
		//               onClick={() => setShowProfilePhotoModal(true)}
		//             >
		//               Agree & Join
		//             </Button>
		//           </div>
		//           <hr />
		//           <div>
		//             <Button
		//               style={{
		//                 width: "-webkit-fill-available",
		//                 background: "#fff",
		//                 border: "1px solid #20ce8f",
		//                 color: "#000",
		//                 padding: "10px",
		//               }}
		//             >
		//               <i className="fa fa-google" id="show_eye"></i>&nbsp;Register with
		//               Google
		//             </Button>
		//           </div>
		//           <div style={{ textAlign: "center", paddingTop: "10px" }}>
		//             Already have a uLektz Account?{" "}
		//             <span style={{ color: "#20ce8f", textDecoration: "underline" }}>
		//               Sign in
		//             </span>
		//           </div>
		//         </div>
		//       </div>
		//     </div>
		//   </div>
		// </>
		<div
			className="auth_page"
			style={{
				// marginTop: "-28px",
				// marginBottom: "-60px",
				// backgroundImage: "url(./images/subscrib-img.jpg)",
				backgroundImage: "url(./images/login-bg3.png)",
			}}>
			<form onSubmit={handleSubmit}>
				<h3 className="text-uppercase text-center mb-4">Sign Up</h3>
				<div className="mb-3">
					<label htmlFor="fullname" className="form-label">
						Full name
					</label>
					<div className="outer-shadow hover-in-shadow form-input-wrap">
						<input
							type="text"
							className="form-control"
							id="fullname"
							onChange={handleChangeInput}
							value={fullname}
							name="fullname"
							style={{ background: `${alert.fullname ? "#fd2d6a14" : ""} ` }}
						/>
					</div>
					<small className="form-text text-danger">
						{alert.fullname ? alert.fullname : ""}
					</small>
				</div>

				{/* <div className="mb-3">
					<label htmlFor="username" className="form-label">
						User name
					</label>
					<div className="outer-shadow hover-in-shadow form-input-wrap">
						<input
							type="text"
							className="form-control"
							id="username"
							onChange={handleChangeInput}
							value={username.toLowerCase().replace(/ /g, "")}
							name="username"
							style={{ background: `${alert.username ? "#fd2d6a14" : ""} ` }}
						/>
					</div>
					<small className="form-text text-danger">
						{alert.username ? alert.username : ""}
					</small>
				</div> */}

				<div className="mb-3">
					<label htmlFor="email" className="form-label">
						Email address
					</label>
					<div className="outer-shadow hover-in-shadow form-input-wrap">
						<input
							type="email"
							className="form-control"
							id="email"
							autocomplete="user-name"
							aria-describedby="emailHelp"
							onChange={handleChangeInput}
							value={email}
							name="email"
							style={{ background: `${alert.email ? "#fd2d6a14" : ""} ` }}
						/>
					</div>
					<small className="form-text text-danger">
						{alert.email ? alert.email : ""}
					</small>
				</div>

				<div className="mb-3">
					<label htmlFor="password" className="form-label">
						Password
					</label>
					<div className="pass">
						<div className="outer-shadow hover-in-shadow form-input-wrap">
							<input
								type={typePass ? "text" : "password"}
								className="form-control"
								id="password"
								onChange={handleChangeInput}
								value={password}
								name="password"
								autocomplete="current-password"
								required
								style={{
									background: `${alert.password ? "#fd2d6a14" : ""} `,
								}}
							/>
							<small onClick={() => setTypePass(!typePass)}>
								{typePass ? "Hide" : "Show"}
							</small>
						</div>
					</div>
					<small className="form-text text-danger">
						{alert.password ? alert.password : ""}
					</small>
				</div>

				<div className="mb-3">
					<label htmlFor="cf_password" className="form-label">
						Confirm Password
					</label>
					<div className="pass">
						<div className="outer-shadow hover-in-shadow form-input-wrap">
							<input
								type={typeCfPass ? "text" : "password"}
								className="form-control"
								id="cf_password"
								onChange={handleChangeInput}
								value={cf_password}
								name="cf_password"
								autocomplete="current-password"
								required
								style={{
									background: `${alert.cf_password ? "#fd2d6a14" : ""} `,
								}}
							/>
							<small onClick={() => setTypeCfPass(!typeCfPass)}>
								{typeCfPass ? "Hide" : "Show"}
							</small>
						</div>
					</div>
					<small className="form-text text-danger">
						{alert.cf_password ? alert.cf_password : ""}
					</small>
				</div>

				<div className="d-flex justify-content-evenly  mx-0 mb-1">
					<label htmlFor="male">
						Male:
						<input
							type="radio"
							id="male"
							name="gender"
							value="male"
							defaultChecked
							onChange={handleChangeInput}
						/>
					</label>

					<label htmlFor="female">
						Female:
						<input
							type="radio"
							id="female"
							name="gender"
							value="female"
							onChange={handleChangeInput}
						/>
					</label>
				</div>

				<button
					type="submit"
					className="btn-1 w-100 d-flex outer-shadow hover-in-shadow justify-content-center"
					style={{ background: "#298E33", color: "#fff" }}>
					Register
				</button>
				<p className="my-2">
					Already have an account?{" "}
					<Link to="/login" style={{ color: "crimson" }}>
						Login Now.
					</Link>
				</p>
			</form>
		</div>
	);
};

export default Register;
