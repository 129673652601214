import React, { useState } from "react";
import Footer from "../common/footer";
import Navbar from "./Navbar";

const News = () => {
  

  const [isOpen, setisOpen] = useState(false);
  return (
    <>
      <Navbar />
      <div class="container-fluid bg-primary py-5 mb-5 page-header">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
              <h1 class="display-3 text-white animated slideInDown">News</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item">
                    <a class="text-white" href="/home">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    News
                  </li>
                </ol>
                <a href="/home" class="btn btn-success btn-sm">
                  Go Back
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-xxl py-5"
        style={{
          paddingTop: "0rem !important",
          paddingBottom: "0rem !important",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="container containercard">
                <div class="d-lg-flex">
                  <div class="card border-0 me-lg-4 mb-lg-0 mb-4">
                    <div class="backgroundEffect"></div>
                    <div class="pic">
                      {" "}
                      <img class="" src="../images/newsletter1.jpg" alt="" />
                      <div class="date">
                        {" "}
                        <span class="day">10</span>{" "}
                        <span class="month">October</span>{" "}
                        <span class="year">2023</span>{" "}
                      </div>
                    </div>
                    <div class="content">
                      <p class="h-1 mt-4">
                        Hon'ble Prime Minister Vision for Global Standards is
                        being fulfilled.
                      </p>
                      <p class="text-muted mt-3">
                        {" "}
                        India Good Agricultural Practices(IndG.A.P) became
                        equivalent to Global G.A.P...
                      </p>
                      <div class="d-flex align-items-center justify-content-between mt-3 pb-3">
                        <a href="/newsread" target="_blank">
                          <div class="btn btn-primary">
                            Read More<span class="fas fa-arrow-right"></span>
                          </div>
                        </a>
                        <div class="d-flex align-items-center justify-content-center foot">
                          {/* <!-- <p class="admin">Warm Regards</p> --> */}
                          <p class="admin">Srihari Kotela</p>
                          {/* <!-- <p class="ps-3 icon text-muted"><span class="fas fa-comment-alt pe-1"></span>3</p> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer className="jnav"></Footer>
      </div>

      
    </>
  );
};

export default News;
