import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";

export default function Termsandconditions() {
	return (
		<>
			<Navbar />
			{/* <div>
        <img
          src="../images/homebanner.jpg"
          alt="krishi-gap-logo"
          style={{ height: "500px", width: "auto" }}
        />
      </div> */}
			{/* <div
				className="container-fluid bg-primary py-5 mb-5 page-header"
				style={{}}>
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-10 text-center">
							<h1 className="display-3 text-white animated slideInDown">
								Terms &amp; Conditions
							</h1>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center">
									<li className="breadcrumb-item">
										<a className="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										className="breadcrumb-item text-white active"
										aria-current="page">
										Terms &amp; Conditions
									</li>
								</ol>
								<a href="index.php" className="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div> */}
			<div
				className="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div className="container">
					<div className="row g-5">
						<div
							className="col-lg-12 wow fadeInUp"
							data-wow-delay="0.3s"
							style={{
								visibility: "visible",
								animationDelay: "0.1s",
								animationName: "fadeInUp",
							}}>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								The Website Owner, including subsidiaries and affiliates
								(“Website” or “Website Owner” or “we” or “us” or “our”) provides
								the information contained on the website or any of the pages
								comprising the website (“website”) to visitors (“visitors”)
								(cumulatively referred to as “you” or “your” hereinafter)
								subject to the terms and conditions set out in these website
								terms and conditions, the privacy policy and any other relevant
								terms and conditions, policies and notices which may be
								applicable to a specific section or module of the website.
							</p>

							<p className="mb-4" style={{ textAlign: "justify" }}>
								Welcome to our website. If you continue to browse and use this
								website you are agreeing to comply with and be bound by the
								following terms and conditions of use, which together with our
								privacy policy govern KRISHIGAP DIGITAL SOLUTIONS PRIVATE
								LIMITED''s relationship with you in relation to this website.
							</p>

							<p className="mb-4" style={{ textAlign: "justify" }}>
								The term 'KRISHIGAP DIGITAL SOLUTIONS PRIVATE LIMITED' or 'us'
								or 'we' refers to the owner of the website whose
								registered/operational office is House No 5-106/281B, Narsing
								Municipality Manchirevula K.V Ranga Reddy K V Rangareddy
								TELANGANA 500075. The term 'you' refers to the user or viewer of
								our website.
							</p>
							<p className="content-text">
								<strong>
									The use of this website is subject to the following terms of
									use:
								</strong>
							</p>
							<div class="mx-5">
								<ul className="unorder-list">
									<li className="list-item">
										<p className="content-text list-text">
											The content of the pages of this website is for your
											general information and use only. It is subject to change
											without notice
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Neither we nor any third parties provide any warranty or
											guarantee as to the accuracy, timeliness, performance,
											completeness or suitability of the information and
											materials found or offered on this website for any
											particular purpose. You acknowledge that such information
											and materials may contain inaccuracies or errors and we
											expressly exclude liability for any such inaccuracies or
											errors to the fullest extent permitted by law.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Your use of any information or materials on this website
											is entirely at your own risk, for which we shall not be
											liable. It shall be your own responsibility to ensure that
											any products, services or information available through
											this website meet your specific requirements.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											This website contains material which is owned by or
											licensed to us. This material includes, but is not limited
											to, the design, layout, look, appearance and graphics.
											Reproduction is prohibited other than in accordance with
											the copyright notice, which forms part of these terms and
											conditions.
										</p>
									</li>

									<li className="list-item">
										<p className="content-text list-text">
											All trademarks reproduced in this website which are not
											the property of, or licensed to, the operator are
											acknowledged on the website.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Unauthorized use of this website may give rise to a claim
											for damages and/or be a criminal offense.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											From time to time this website may also include links to
											other websites. These links are provided for your
											convenience to provide further information.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											You may not create a link to this website from another
											website or document without KRISHIGAP DIGITAL SOLUTIONS
											PRIVATE LIMITED’s prior written consent.
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Your use of this website and any dispute arising out of
											such use of the website is subject to the laws of India or
											other regulatory authority.
										</p>
									</li>
								</ul>
							</div>

							<p className="mb-4" style={{ textAlign: "justify" }}>
								We as a merchant shall be under no liability whatsoever in
								respect of any loss or damage arising directly or indirectly out
								of the decline of authorization for any Transaction, on Account
								of the Cardholder having exceeded the preset limit mutually
								agreed by us with our acquiring bank from time to time.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
}
