import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/akhil_mittal.jpg"
            alt=""
          />

          <h4 className="">Nikhil Mittal</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <p>
              Providing comprehensive Legal, Secretarial and Management Advisory
              Services in the field of Company Law, Securities Laws, inbound and
              outbound Investment, Trademark, Copyrights & Patent filing, Legal
              Due Diligence, Transaction documents, FEMA Compliance, Private
              Equity, Mergers & Acquisition.
            </p>

            <b>Past</b>
            <br />

            <p>
              A Law Graduate, Masters in Commerce and a member of The Institute
              of Company Secretaries of India with a professional experience of
              over 10 years in various industries.
            </p>
            <p>
              He has hands on experience in Company Law, Securities Laws,
              inbound and outbound Investment, Trademark, Copyrights & Patent
              filing, Legal Due Diligence, FEMA Compliance, RBI matters and
              relevant compliances.
            </p>
            <p>
              He has been actively engaged in dealing with company law matters
              and obtaining approvals and registrations with various
              governmental, statutory, regulatory and administrative authorities
              like Registrar of Companies, Company Law Boards, Regional
              Directors, and Ministry of Corporate Affairs, Reserve Bank of
              India, Stock Exchanges, SEBI, DGFT and other statutory Authorities
              and representing before the said Authorities on behalf of clients.
            </p>
            <br />
            <b>Educational Qualifications</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                M.Com
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                LLB
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                CS
              </li>{" "}
              
            </ul>
           <br/>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Shashi_sareen;
