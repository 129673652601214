import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Mrs_dipti_saudagar = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            // class="img-fluid"
            src="../images/Dipti_Saudagar.jpg"
            alt=""
          />
          <h4 className="">Mrs Dipti Saudagar</h4>
          Food Safety and Compliances Expert
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Supporting in FSSAI compliance queries, Quality and FS systems
                guidance, HACCP building, any scale up challenges wrt to Baking
                technology, Product & Process Development technical aspects.
              </li>

              <br />
            </ul>
            <b>Past</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                17 years of Food Industry Experience in Innovation Strategy
                Development, Product, Process & Technology Development, Quality
                & Food Safety Systems Development, and Operations Systems
                Development in companies like Cadburry, Firmenich and General
                Mills.
              </li>
              <li>
                Key Markets Handled India, Middle East & Africa, Malaysia,
                Singapore, Thailand, Philippines.
              </li>{" "}
              <li>
                Worked with below companies like Cadbury, Firmenich, General
                Mills.
              </li>{" "}
            </ul>

            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                M.Tech in Food Technology and Engineering.
              </li>{" "}
              <li>B.Tech.</li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Mrs_dipti_saudagar;
