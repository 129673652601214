import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Problemstatement = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <h1 className="" style={{textAlign:"center"}}>Problem Statement</h1>

        <div className="row">
          <div class="col" style={{}}>
            <p class="mb-4">
              Many developing countries, including India, face significant
              challenges in adopting and complying with global food safety and
              sustainability standards. Key obstacles include:
            </p>
            <ul style={{ paddingLeft: "50px" }}>
              <h5>Gaps in Awareness and Training:</h5>{" "}
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                Limited access to training resources makes it difficult to
                implement standards across the food supply chain.
              </li>
              <h5>Shortage of Skilled Trainers:</h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                The absence of qualified trainers in crop production centers
                hinders the transfer of knowledge and the adoption of best
                practices.
              </li>
              <h5>Complexity in Navigating Compliance:</h5>{" "}
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                Farmers and medium-scale processors often struggle to interpret
                and apply international standards effectively.
              </li>
              <h5>Limited Market Awareness:</h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                Many farmers and processors lack the knowledge of prerequisites
                for entering premium markets.
              </li>
              <h5>Lack of Implementation Support:</h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                Accredited certification bodies do not provide implementation
                assistance due to conflict of interest.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Problemstatement;
