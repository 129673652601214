import React from "react";
import Footer from "../common/footer";
import Navbar from "./Navbar";
const Shippingdelivarypolicy = () => {
	return (
		<>
			<Navbar />
			<div className="container-fluid bg-primary py-5 mb-5 page-header">
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-10 text-center">
							<h1 className="display-3 text-white animated slideInDown">
								Shipping &amp; Delivary policy
							</h1>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center">
									<li className="breadcrumb-item">
										<a className="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										className="breadcrumb-item text-white active"
										aria-current="page">
										Shipping &amp; Delivary policy
									</li>
								</ol>
								<a href="index.php" className="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div
				className="col-lg-12 wow fadeInUp"
				data-wow-delay="0.3s"
				style={{
					visibility: "visible",
					animationDelay: "0.3s",
					animationName: "fadeInUp",
				}}>
				<p className="mb-4" style={{ textAlign: "justify" }}>
					No Shipping is not applicable for business.
				</p>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Shippingdelivarypolicy;
