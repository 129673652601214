import React from "react";
import { Document, Page } from "react-pdf";
const PdfViewerWithSearch = ({ fileUrl }) => {
	const pdfURL = fileUrl;
	console.log("pdfURL", pdfURL);
	console.log("fileUrl", fileUrl);
	return (
		<div>
			<Document file={pdfURL}>
				<Page pageNumber={1} />
			</Document>
		</div>
	);
};

export default PdfViewerWithSearch;
