import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Dr_dharmesh_verma = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/DharmeshVerma.jpg"
            alt=""
          />

          <h4 className="">Dr Dharmesh Verma</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <b>Independent Consultant</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Domain Expert in Basmati Rice Pre-Harvest processes,
                regenerative agriculture and machine learning for geospatial
                technology with over 25years of experience across government,
                private, and international organizations
              </li>

              <br />
            </ul>
            <b>Past</b>
            <br />
            <br />
            <b>Patanjali Organic Research Institute State Coordinator</b>
            <br />
            <br />
            <b>Global Head-Technical</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Sourced Internal Customers, Stream lined business development
                and agronomic recommendations.
              </li>
              <li>
                Liaised for regulatory affairs with concerned government
                departments to bring good products under FCO.
              </li>{" "}
            </ul>
            <br />
            <b>UPL Ltd- India</b>
            <br />
            <br />
            <b>Senior Consultant–Remote Sensing & GIS Applications</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Managed Basmati rice production estimation project for
                BEDF(APEDA) in five Basmati growing states of India using field
                and satellite images; delivered yield modeling using
                meteorological data and satellite image-derived indices.
              </li>
              <li>
                Expertise with a conceptual understanding of climate change,
                climates mart agriculture, organic farming, carbon
                sequestration, farmer training and multiple project management.
              </li>
              <li>
                Executed and managed pioneer projects on Basmati cultivation,
                varietal discrimination, acreage estimation and yield forecast
                for 85 districts of JK, Punjab, Haryana, Western UP and Himachal
                Pradesh.
              </li>{" "}
              <li>
                Trained several farmer trainers in organic farming including
                Basmati rice in Haryana under the NSDC project. Surveyed 30,000+
                Basmati growers in GI area over the span of a decade.
              </li>
            </ul>

            <b>Professional Trainings/Courses</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                ERDAS-Imagine version 8.3 by Remote Sensing Instruments,
                Hyderabad at RSAC-UP, Lucknow.
              </li>{" "}
              <li>
                Statistical methods at the Institute of Applied Statistics &
                Development Studies, Lucknow.
              </li>{" "}
              <li>
                ERDAS Imagine 8.3 at Indian Institute of Remote Sensing,
                Dehradun.
              </li>{" "}
              <li>
                Crop modeling at Sugarcane Institute, Lucknow, conducted by
                CSSRI, Karnal.
              </li>
              <li>
                Crop modeling at Sugarcane Institute, Lucknow, conducted by
                CSSRI, Karnal.
              </li>
              <li>
                Genesis of salt-affected soils at CSSRI, Karnal, sponsored by
                UPCAR.
              </li>
            </ul>
            <b>EHonors & Awards:</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                <span style={{ fontWeight: 500 }}> Chair, </span> Technical
                Session in 7th International Conf. on Desert,Desertification and
                Dry lands.
              </li>{" "}
              <li>
                <span style={{ fontWeight: 500 }}> Chair, </span> Asian Soil
                Partnership for Pillar-1, Global Soil Partnership,Food and
                Agriculture Organization of the United Nations (FAO).
              </li>{" "}
              <li>
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  Outstanding Contribution Scientist,{" "}
                </span>{" "}
                oil Conservation, Food and Agriculture Organization of the
                United Nations (FAO)
              </li>{" "}
              <li>
                {" "}
                <span style={{ fontWeight: 500 }}> Chair, </span>Technical
                Session in 1st Global Cleanup Congress.
              </li>
              <li>
                <span style={{ fontWeight: 500 }}> Expert Panelist, </span>
                NASA - South Asia Research Initiative Meeting.
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 500 }}> Chair, </span>Technical
                Session - Climate Smart Agriculture & Soil.
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  Outstanding Achievement Award - Private Sector,{" "}
                </span>
                Agriculture & Innovation
              </li>
              <li>
                {" "}
                <span style={{ fontWeight: 500 }}> Chair, </span>
                International Conf. on Agriculture & Applied Sciences - SAID
              </li>
            </ul>

            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Ph.D.(Agricultural Chemistry).
              </li>{" "}
              <li>M.Sc.(Soil Survey)</li>{" "}
              <li>B.Sc.(Agriculture & Animal Husbandry).</li>{" "}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Dr_dharmesh_verma;
