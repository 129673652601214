import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";
import LoginRequest from "./loginRequest";
import ProfileBanner from "./profileBanner";

const Mybooks = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const communitiesArray = [1, 2];
	const [value, setValue] = useState("");
	const [ebookArray, setEbookArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (auth.user) {
			getDataAPI(`getSavedBooks/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get books", res.data);
				// setCommunitiesArray(res.data.community);
				setEbookArray(res.data.books);
			});
		}
	}, [auth]);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<ProfileBanner />

					<div className="row pt-3 justify-content-around">
						{/* <div className="col-1"></div> */}
						<div
							className="col-8 card"
							style={{
								//   marginRight: "75px",
								border: "none",
								borderRadiusTop: "15px",
								//   marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-8"
									style={{ display: "flex", alignItems: "center" }}>
									My Books
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-3 uc-searchBar">
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							<div>
								{/* <div className="row">
							{communitiesArray.map((e) => {
								return (
									<div
										className="col-3"
										style={{ padding: "10px 10px 10px 10px" }}>
										<div
											className="card"
											style={{ padding: "10px 10px 10px 10px" }}>
											<img
												src="./images/digitalmarketingbook.jpg"
												style={{ width: "100%" }}></img>
											<div style={{ fontSize: "18px", fontWeight: "500" }}>
												Digital Marketing For Dummies
											</div>
											<div style={{ fontSize: "13px" }}>
												uLektz Learning Solutions Private
											</div>
											<div>
												<Rating
													emptySymbol="fa fa-star-o fa-1x"
													fullSymbol="fa fa-star fa-1x"
												/>
											</div>
											<div style={{ paddingTop: "20px" }}>
												<Button
													style={{
														background: "#20ce8f",
														width: "-webkit-fill-available",
														borderRadius: "5px",
														border: "none",
													}}>
													Read Book
												</Button>
											</div>
										</div>
									</div>
								);
							})}
						</div> */}
								<div className="row pt-3 justify-content-around">
									<div
										className="col-12 card"
										style={{
											border: "none",
											borderRadiusTop: "15px",
											borderRadius: "15px",
										}}>
										{ebookArray.length === 0 ? (
											<div
												className=""
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													padding: "30px 30px",
												}}>
												<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
													<div className="uc-NRF">
														<img
															src="../images/Search-no-results-found.svg"
															alt=""
														/>
														<h5
															style={{
																fontSize: "28px",
																fontWeight: "300",
																color: "#62DDB2",
															}}>
															No eBooks are added to my books
														</h5>
														{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
													</div>
												</ul>
											</div>
										) : (
											<>
												{ebookArray.map((e) => {
													return (
														<>
															<div key={e.id} style={{ padding: "5px" }}>
																<div
																	className="card"
																	style={{ padding: "5px" }}>
																	<div className="row">
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			<img
																				alt="book"
																				src="../images/ebook.jpg"
																				style={{ width: "100%" }}></img>
																		</div>
																		<div className="col-7">
																			<div>
																				<span className="ebook">Name:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.name}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Category:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.category}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">ISBN:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.isbn}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Subject:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.subject}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Author:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.author}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Publisher:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.publisher}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Cost:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.cost === "" ? "Free" : e.cost}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Link:</span>
																				&nbsp;
																				{/* <span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}
																			>
																				{e.link}
																			</span> */}
																				<a
																					href={
																						(e.link?.includes("https://")
																							? ""
																							: "https://") + e.link
																					}
																					rel="no-referer noreferrer"
																					target="_blank">
																					{e.link}
																				</a>
																			</div>
																		</div>
																		<div
																			className="col-2"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			{e.file !== "" && (
																				<Button
																					onClick={() => {
																						fileConversion(e.file);
																					}}
																					style={{
																						backgroundColor: "#E23F1C",
																						border: "none",
																						borderRadius: "4px",
																						marginTop: "-6px",
																						color: "#fff",
																					}}>
																					Open
																				</Button>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</>
													);
												})}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};
export default Mybooks;
