import React from "react";
import Navbar from "../components/Navbar";
import Comingsoon from "./comingsoon";

export default function Jobs() {
	return (
		<>
			<Navbar />
			<Comingsoon />
		</>
	);
}
