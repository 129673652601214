import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Dr_pandurang_gundappa_adsule = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/pandurang-gundappaad-sule.jpg"
            alt=""
          />

          <h4 className="">Dr. Pandurang Gundappa Adsule</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Presently working as international consultant for Post-Harvest
                Technology for ADB for Government of Bangladesh.
              </li>

              <br />
            </ul>
            <b>Past</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Monitoring of pesticide residues in exportable table grapes to
                EU countries, funded by APEDA.
              </li>
              <li>
                Worked as Post-Doctoral Fellow-cum-Visiting Scientist at Citrus
                Research and Education Centre of IFAS under University of
                Florida in USA in 1982-83.
              </li>{" "}
              <li>
                Underwent UNIDO’s training programme in export from Small and
                Medium Enterprises at Phuket in Thailand in 1988 and Grain
                Milling Technology in Moscow in the former USSR (1989).
              </li>{" "}
              <li>
                Organized pilot trial of export of Indian banana shipment, a
                research trial to Dubai and studied marketing of these banana in
                Dubai market to decide technical feasibility and economic
                viability.
              </li>
              <li>
                Carried various programmes for the promotion and development of
                Small Scale Agro Rural Food Processing units as Regional Deputy
                Director (Food Industry), Southern states.
              </li>{" "}
              <li>
                Worked on evaluation of fruits and vegetable varieties for their
                suitability to processing purpose like canning, freezing,
                dehydration as Scientist at CISH, Lucknow.
              </li>{" "}
            </ul>
            <br />
            <b>INTERNATIONAL EXPOSURE:</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Participated in several international workshops on monitoring of
                agro chemical residues in fruits and vegetables- USSR, Italy,
                Germany, France, Austria, USA etc.
              </li>
            </ul>
            <br />
            <b>PUBLICATIONS:</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                120 research publications out of which 40 publications are in
                foreign journals like Journal of Food Science and Technology,
                Journal of Chromatography, AOAC, Journal of Food and Agriculture
                etc.
              </li>{" "}
              <li>
                Several research publications and technical /Extension
                bulletins, brochure and videos for the benefit of farmers in
                English, Marathi, and Hindi.
              </li>{" "}
            </ul>

            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                BSc Agriculture.
              </li>{" "}
              <li>MSc Horticulture, PG School, IARI, New. Delhi.</li>{" "}
              <li>
                Ph.D., in First Class with Distinction in Horticulture at PG
                School, IARI, New. Delhi.
              </li>
              <li>
                Received G.J. Award for highest C.G.P.A. among successful
                candidates in IARI
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Dr_pandurang_gundappa_adsule;
