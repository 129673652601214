import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Dr_vijay_singh_thakur = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/Vijay-Singh-Thakur.jpg"
            alt=""
          />

          <h4 className="">Dr Vijay Singh Thakur</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                An expert on Apple and Horticulture Value chain.
              </li>

              <br />
            </ul>
            <b>Past</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Ex-Vice Chancellor, Dr. YS Parmar University of Horticulture and
                Forestry, Nauni, Solan in 2013-2016.
              </li>
              <li>
                An expert on temperate fruits, diseases and pest management,
                completed research work on 32 research projects funded
                internationally and nationally.
              </li>{" "}
              <li>
                Prepared and implemented Horticulture Development Project under
                World Bank for the state Himachal Pradesh.
              </li>{" "}
              <li>
                Handled Apple scab disease management in Himachal Pradesh.
              </li>
              <li>
                Chairman, ICAR Board Subject Matter Area (BSMA) Committee for
                Horticulture i.e. 12th September, 2018.
              </li>{" "}
              <li>
                Visiting scientist to Horticulture Research International, UK,
                Institute for Plant Protection of Hungarian Academy of Sciences,
                , Federal Centre for Breeding Research on Cultivated Plants,
                Germany, New York State Agricultural Experimental Station, USA,
                Northwest Sci-Tech University of Agriculture & Forestry, China.
              </li>{" "}
            </ul>
            <br />
            <b>Professional Experiences</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                An expert on temperate fruits, diseases and pest management,
                completed research work on 32 research projects funded
                internationally and nationally.
              </li>
              <li>
                Specialized in research on epidemiology, weather monitoring &
                disease forecasting methods, pesticides mode of action and
                application.
              </li>
              <li>
                Significant achievements in production of quality planting
                material of commercially important temperate fruits, medicinal
                plants, and floriculture have made.
              </li>{" "}
              <li>
                Established bud wood & Gene bank for fruits, integrated
                diseases, pests and nutrient management, use of bio-fertilizers
                & natural/organic farming, crop regulation and energy efficient
                canopy management.
              </li>
            </ul>
            <br />
            <b>Awards and Recognitions</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Life time Achievements award by Indian Academy of Horticulture
                Science, New Delhi for 2019.
              </li>{" "}
              <li>
                Awarded Indian National Science Academy visiting scientist
                fellowship under exchange program with Royal Society, London,
                UK.
              </li>{" "}
              <li>
                Life Time Achievement Award for overall development of
                Horticulture in general and Plant Diseases Management by YSPUHF,
                Himalayan Phytopathological Society of India.
              </li>{" "}
              <li>
                Recipient of Indian Horticulture Gold Medal in Fruit Science in
                the development of Horticulture.
              </li>
              <li>
                Received first European Commission collaborative research
                project on “Sustainable Production of Apple in Asia”. In the
                consortium UK, Germany, Hungry, France, China and India are
                partners.
              </li>
            </ul>
            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Post Doctorate from Horticulture research international East
                Malling of UK in Disease forecasting.
              </li>{" "}
              <li>
                Ph.D. on 1987 from Dr. YS Parmar University, Solan in Plant
                Pathology (Fruits).
              </li>{" "}
              <li>M.Sc. on 1983 from HPKV, Palampur in Plant Pathology.</li>{" "}
              <li>
                B.Sc. on 1981 from HPKV, Palampur in Specialization of
                Agriculture.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Dr_vijay_singh_thakur;
