import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import ImageCropper from "./imagecroppopup";
import LoginRequest from "./loginRequest";

const Resources = () => {
	const { theme, auth } = useSelector((state) => state);
	const communitiesArray = [1, 2, 3, 4, 1, 1, 1, 1, 3, 4];
	const [size, setSize] = useState("");
	const [showJoinModal, setShowJoinModal] = useState(false);
	const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);
	const [imageToCropProfilePhoto, setImageToCropProfilePhoto] =
		useState(undefined);
	const handleCloseProfilePhotoModal = () => {
		setShowProfilePhotoModal(false);
		setShowJoinModal(true);
	};

	const onUploadFileProfilePhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropProfilePhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const inputFileProfilePhoto = useRef(null);
	const onButtonClickProfilePhoto = () => {
		// `current` points to the mounted file input element
		inputFileProfilePhoto.current.click();
	};

	const [croppedImageProfilePhoto, setCroppedImageProfilePhoto] =
		useState(undefined);
	const handleCloseJoinModal = () => {
		setShowJoinModal(false);
	};
	const handleOpenjoinModal = () => {
		setShowJoinModal(true);
	};

	const handleOpenprofilePhotoModal = () => {
		setShowProfilePhotoModal(true);
		setShowJoinModal(false);
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 8,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 6,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};

	const responsive2 = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 8,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};
	const responsive3 = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 8,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};
	return (
		<>
			{auth.token ? (
				<>
					<Modal
						show={showProfilePhotoModal}
						onHide={handleCloseProfilePhotoModal}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11">
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "15px",
										}}>
										Profile Photo
									</h2>
								</div>
								<div
									className="col-1"
									style={{ padding: "15px", cursor: "pointer" }}
									onClick={() => handleCloseProfilePhotoModal()}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr />
							{imageToCropProfilePhoto ? (
								<ImageCropper
									imageToCrop={imageToCropProfilePhoto}
									onImageCropped={(croppedImage) => {
										console.log(croppedImage);
										setCroppedImageProfilePhoto(croppedImage);
									}}
									coverphoto="coverphoto"
								/>
							) : (
								<div
									style={{
										backgroundColor: "#efefef",
										height: "50vh",
										width: "34vw",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										src="./images/profile.svg"
										style={{ height: "90px" }}></img>
								</div>
							)}

							<div
								style={{
									fontSize: "13px",
									fontWeight: "300",
									textAlign: "center",
									paddingTop: "10px",
								}}>
								Recommended size - 1920 x 1080px, Max. 2 MB
							</div>
							<div
								className="row"
								style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
								<div className="col-8">
									<input
										type="file"
										id="file"
										ref={inputFileProfilePhoto}
										style={{ display: "none" }}
										onChange={onUploadFileProfilePhoto}
									/>
									<button
										onClick={onButtonClickProfilePhoto}
										style={{
											background: "#fff",
											border: "none",
											padding: "5px",
											borderRadius: "5px",
										}}>
										<img src="./images/camera.svg" alt="" />{" "}
										<span style={{ fontSize: "12px" }}>Select Photo</span>
									</button>
									{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#fff",
												border: "none",
												height: "35px",
												color: "#000",
												border: "1px solid #000",
												borderRadius: "15px",
											}}
											onClick={handleCloseProfilePhotoModal}>
											Cancel
										</Button>
									</div>
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#3CCE8F",
												border: "none",
												height: "35px",
												//   width:"40px",
												borderRadius: "15px",
												//   display:"flex",
												//   alignItems:"center",
												//   justifyContent:"end"
											}}>
											Upload
										</Button>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						show={showJoinModal}
						onHide={handleCloseJoinModal}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11" style={{ paddingLeft: "0px" }}>
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "10px",
											marginBottom: "0px",
											display: "flex",
										}}>
										Join - Nuclear Physics &nbsp;
										<span
											className="public-text"
											// onClick="communityChangeNew(5421)"
										>
											<span className="uc-star-count">
												<a
													href="javascript:void(0)"
													className="cmn-btn uc-normal-btn">
													<img src="./images/Public_icon.svg" /> Public
												</a>
											</span>
										</span>{" "}
									</h2>
								</div>
								<div
									className="col-1"
									style={{ padding: "10px", cursor: "pointer" }}
									onClick={handleCloseJoinModal}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
							<div
								className="uc-pro-left-img"
								style={{ padding: "20px 40px", textAlign: "center" }}>
								{croppedImageProfilePhoto === undefined ? (
									<img
										src="./images/krishigaplogo.png"
										id="createComprofileimg"
										alt=""
										className="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											border: "1px solid #ddd",
										}}
									/>
								) : (
									<img
										src={URL.createObjectURL(croppedImageProfilePhoto)}
										id="createComprofileimg"
										alt=""
										className="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											border: "1px solid #ddd",
										}}
									/>
								)}

								<label className="uc-cam-icon">
									<img
										id="cameraicon"
										src="./images/camera.svg"
										alt=""
										style={{
											backgroundColor: "#E4E3E0",
											marginLeft: "-30px",
											padding: "6px 6px",
											borderRadius: "40%",
											marginTop: "75px",
											cursor: "pointer",
										}}
										onClick={handleOpenprofilePhotoModal}
									/>
								</label>
							</div>
							<div style={{ padding: "15px 45px 10px" }}>
								<label for="name" style={{ paddingBottom: "5px" }}>
									Name*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Name"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Profile Headline*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Profile Headline"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Location*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Location"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Email*
								</label>
								<input
									type="email"
									className="form-control"
									placeholder="Enter Email"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Mobile Number*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Mobile Number"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Select Role*
								</label>
								<select
									className="form-control select_role select-hidden"
									id="select_role"
									name="formFieldName[select_role]"
									required=""
									profession-selected="0">
									<option value="">Select</option>
									<option value="4">Member</option>
									<option value="17">Alumni/Guest</option>
								</select>
							</div>
							<div
								className="row"
								style={{
									padding: "15px 15px 15px 15px",
									display: "flex",
									alignItems: "center",
									background: "#F1F1F1",
									//   marginLeft: "0px",
									//   marginRight:"0px"
								}}>
								<div className="col-9"></div>
								<div className="col-3">
									<span
										className="col-1"
										style={{
											//   border: "1px solid #ddd",
											borderRadius: "5px",
											padding: "4px 15px 6px 20px",
											//   width: "auto",
											paddingLeft: "20px",
											color: "#fff",
											background: "#E23F1C",
										}}>
										+ Join
									</span>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<div style={{ padding: "20px 60px 20px 120px" }}>
						<div
							className=""
							style={{
								backgroundImage: "url(./images/360_banner_img.png)",
								padding: "40px",
							}}>
							<div className="row">
								<div className="col-4">
									<div className="container">
										<img
											src="./images/ai_cir.png"
											alt="Your Image"
											className="rotate rotate-clockwise"
										/>
									</div>
									{/* <img
                src="./images/ai_cir.png"
                className="vew3"
                style={{ width: "25vw" }}
              ></img> */}
								</div>
								<div
									className="col-8"
									style={{
										display: "flex",
										alignContent: "center",
										justifyContent: "center",
										flexDirection: "column",
										alignItems: "flex-start",
										// marginLeft:"10px"
									}}>
									<div style={{ fontSize: "30px" }}>
										Get 360° AI-based Personalized Guidance
									</div>
									<br />
									<div>
										<Button style={{ background: "#F96342", border: "none" }}>
											Update Profile
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>People you may like to connect</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div
											style={{ padding: "10px 10px 10px 10px", width: "15vw" }}>
											<div className="uc-community-li-main">
												{" "}
												<div className="uc-comm-like-imgs">
													<img
														// onError="communityBannerImgError(this);"
														src="./images/common-background.png"
														alt="img"
													/>
												</div>
												<div className="uc-comm-userimg">
													{" "}
													<img
														src="https://lh3.googleusercontent.com/a/ACg8ocI5543DTkdKPEsRuFOHPGyz2l6l8toFAzbyzmwaChhe5A4=s96-c"
														alt=""
														// onError="profileImgError(this);"
														className="uc-comm-user-img"
														// onClick="communityChangeNew(5421)"
													/>{" "}
												</div>
												<div className="uc-right-top-wrap">
													<a href="javascript:void(0)" className="uc-rb-btn">
														<span
															className="uc-rc-pop-span"
															style={{ position: "absolute" }}></span>
													</a>{" "}
												</div>{" "}
												<div className="uc-community-li-con">
													{" "}
													<div>
														<h4>JAKKULA SURESH</h4>{" "}
														<div className="uc-pro-location">
															{" "}
															<p> Student</p>{" "}
															<div className="uc-pv-wrap-comb-mem">
																{" "}
																<div className="uc-pv-wrap"></div>{" "}
																<p style={{ marginBottom: "0rem" }}>
																	<span className="">Hyderabad</span>
																</p>{" "}
															</div>{" "}
														</div>
													</div>{" "}
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															marginTop: "30px",
														}}>
														<Button
															style={{
																background: "#20ce8f",
																borderRadius: "5px",
																width: "fit-content",
																width: "100px",
																height: "30px",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																border: "none",
															}}>
															Connect
														</Button>{" "}
													</div>
												</div>{" "}
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>Communities you may like to join</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div
											// className="col-3"
											style={{ padding: "10px 10px 10px 10px" }}>
											<div className="uc-community-li-main">
												{" "}
												<div className="uc-comm-like-imgs">
													<img
														// onError="communityBannerImgError(this);"
														src="./images/community-common-background.png"
														alt="img"
													/>
												</div>
												<div className="uc-comm-userimg">
													{" "}
													<img
														src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/28215421.png"
														alt=""
														// onError="profileImgError(this);"
														className="uc-comm-user-img"
														// onClick="communityChangeNew(5421)"
													/>{" "}
												</div>
												<div className="uc-right-top-wrap">
													<a
														href="javascript:void(0)"
														className="uc-option-btn">
														{" "}
														<img src="./images/options.svg" alt="icon" />{" "}
													</a>{" "}
													<a
														href="javascript:void(0)"
														className="uc-rb-btn"
														//   onClick="openReportCommunityPopup(5421,this)"
													>
														<span
															className="uc-rc-pop-span"
															style={{ position: "absolute" }}>
															{/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community */}
														</span>
													</a>{" "}
												</div>{" "}
												<div className="uc-community-li-con">
													{" "}
													<div>
														<h4>Nuclear Physics</h4>{" "}
														<div className="uc-pro-location">
															{" "}
															<p>
																{" "}
																No. 100, Lake View Estate, Kundrathur Main
																Road,Porur, Chennai - 600 116{" "}
															</p>{" "}
															<div
																className="public-text"
																// onClick="communityChangeNew(5421)"
															>
																<span className="uc-star-count">
																	<a
																		href="javascript:void(0)"
																		className="cmn-btn uc-normal-btn">
																		<img src="./images/Public_icon.svg" />{" "}
																		Public
																	</a>
																	<a
																		href="javascript:void(0)"
																		className="cmn-btn uc-blue-btn type">
																		<span className="">Skill</span>
																	</a>
																</span>
															</div>{" "}
															<div className="uc-pv-wrap-comb-mem">
																{" "}
																<div className="uc-pv-wrap">
																	{/* <a
                                          href="https://www.ulektz.com/in/53010781"
                                          style="background-color: #00B3F6;border-radius: 25px;text-align: center;"
                                        >
                                          <span style="text-transform: capitalize;font-weight: bold;">
                                            u
                                          </span>
                                        </a>
                                        <a href="https://www.ulektz.com/in/53010757">
                                          <img
                                            src="https://lh3.googleusercontent.com/a/ACg8ocJr3hF6LUSQCADedncmz7-BZdNA2_ob5_2ojLRphrW7=s96-c"
                                            alt="Abhinav shukla"
                                            style="border-radius: 25px;"
                                            // onError="this.onerror=null;this.src=https://www.ulektz.com/assets/img/header/common-user.svg"
                                          />
                                        </a>
                                        <a href="https://www.ulektz.com/in/53010572">
                                          <img
                                            src="https://lh3.googleusercontent.com/a/ACg8ocJOdghAab-mQGftAo5k83Ah4EtsjNuAUd7swuAI8ermOZ4=s96-c"
                                            alt="Sahana M"
                                            style="border-radius: 25px;"
                                            // onError="this.onerror=null;this.src=https://www.ulektz.com/assets/img/header/common-user.svg"
                                            className=""
                                          />
                                        </a> */}
																	{/* <a
                                          href="https://www.ulektz.com/in/53009052"
                                          style="background-color: #6a828e;border-radius: 25px;text-align: center;"
                                        >
                                          <span style="text-transform: capitalize;font-weight: bold;">
                                            M
                                          </span>
                                        </a> */}
																</div>{" "}
																<p
																	// onClick="communityChangeNew(5421)"
																	// className="pb-2"
																	style={{ marginBottom: "0rem" }}>
																	<span className="">23</span>&nbsp;Members
																</p>{" "}
															</div>{" "}
														</div>
													</div>{" "}
													<div
														onClick={handleOpenjoinModal}
														// href="javascript:void(0)"
														className="cmn-btn uc-join-btn uc-com-join-toggle">
														+Join
													</div>{" "}
												</div>{" "}
											</div>
											{/* <div className="card">
                              <img
                                src="./images/profile.png"
                                className="uc-comm-user-img "
                              ></img>
                              <div>Nuclear Physics</div>
                              <div
                                style={{
                                  backgroundColor: "#E23F1C",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                +Join
                              </div>
                            </div> */}
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>eBooks you may like to study</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div
												className="card"
												style={{ padding: "10px 10px 10px 10px" }}>
												<div>
													<img
														src="https://s3.ap-south-1.amazonaws.com/ulektzmumbai/Content/Publisher/64899/training_material/10400/8sjMHF.jpg"
														style={{ width: "100%", height: "100%" }}></img>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													A Textbook of Pharmacology-II (PCI)
												</div>
												<div
													style={{
														fontSize: "14px",
														fontWeight: "400",
														color: "#646363",
													}}>
													SIA Publishers & Distributors Pvt. Ltd
												</div>
												<div>
													<Rating
														emptySymbol="fa fa-star-o fa-1x"
														fullSymbol="fa fa-star fa-1x"
													/>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													<span> ₹ 87</span>&nbsp;
													<span
														style={{
															textDecoration: "line-through",
															color: "#646363",
															fontWeight: "400",
														}}>
														₹ 119
													</span>
													&nbsp;
													<span
														style={{
															background: "#DB2828",
															color: "#fff",
															fontSize: "13px",
															padding: "3px",
															borderRadius: "4px",
														}}>
														30% OFF
													</span>
												</div>
												<div style={{ marginTop: "10px" }}>
													<Button
														style={{
															background: "#f96342",
															width: "100%",
															borderRadius: "4px",
															border: "none",
														}}>
														{" "}
														+Add to cart
													</Button>
												</div>{" "}
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>

						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>Courses you may like to enroll</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div
												className="card"
												style={{ padding: "10px 10px 10px 10px" }}>
												<div>
													<img
														src="https://s3.ap-south-1.amazonaws.com/ulektzmumbai/skillCourse/1844577778.jpg"
														style={{ width: "100%", height: "100%" }}></img>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													Course in Communication Skills as per AICTE Model
													Curriculum
												</div>
												<div
													style={{
														fontSize: "14px",
														fontWeight: "400",
														color: "#646363",
													}}>
													NITTTR Chandigarh
												</div>
												<div>
													<Rating
														emptySymbol="fa fa-star-o fa-1x"
														fullSymbol="fa fa-star fa-1x"
													/>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													<span> ₹ 9999</span>&nbsp;
													<span
														style={{
															textDecoration: "line-through",
															color: "#646363",
															fontWeight: "400",
														}}>
														₹ 8473
													</span>
													{/* &nbsp;
                      <span
                        style={{
                          background: "#DB2828",
                          color: "#fff",
                          fontSize: "13px",
                          padding: "3px",
                          borderRadius: "4px",
                        }}
                      >
                        30% OFF
                      </span> */}
												</div>
												<div style={{ marginTop: "10px" }}>
													<Button
														style={{
															background: "#f96342",
															width: "100%",
															borderRadius: "4px",
															border: "none",
														}}>
														{" "}
														+Add to cart
													</Button>
												</div>{" "}
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>Jobs you may like to apply</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div
												className="card"
												style={{ padding: "10px 10px 10px 10px" }}>
												<div>
													<img
														src="./images/experience_default_image.svg"
														style={{ width: "58px", height: "58px" }}></img>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													Insurance Sales Executive (1-2 exp)
												</div>
												<div
													style={{
														fontSize: "14px",
														fontWeight: "400",
													}}
													className="lightgreen">
													MoralMag
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													<span>
														<img src="./images/location_on.svg"></img>
													</span>
													&nbsp;
													<span
														style={{
															color: "#646363",
															fontWeight: "400",
														}}>
														KA
													</span>
												</div>
												<div style={{ marginTop: "10px" }}>
													<Button
														style={{
															background: "#3CCE8F",
															width: "100%",
															borderRadius: "4px",
															border: "none",
														}}>
														{" "}
														Apply Job
													</Button>
												</div>{" "}
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														marginTop: "10px",
													}}>
													<div style={{ display: "contents" }}>
														<span>
															<img
																src="./images/Hot.svg"
																style={{
																	padding: "3px",
																	background: "#F4F7F9",
																}}></img>
														</span>
														<span
															style={{
																padding: "3px",
																background: "#F4F7F9",
																fontSize: "13px",
															}}>
															<img src="./images/Time.svg"></img>&nbsp;8 days
															ago
														</span>
														<span>
															<img
																src="./images/Star.svg"
																style={{
																	padding: "3px",
																	background: "#F4F7F9",
																}}></img>
														</span>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>Internships you may like to apply</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div
												className="card"
												style={{ padding: "10px 10px 10px 10px" }}>
												<div>
													<img
														src="./images/experience_default_image.svg"
														style={{ width: "58px", height: "58px" }}></img>
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													International Sales Executive
												</div>
												<div
													style={{
														fontSize: "14px",
														fontWeight: "400",
													}}
													className="lightgreen">
													Deloitte
												</div>
												<div style={{ fontSize: "16px", fontWeight: "500" }}>
													<span>
														<img src="./images/location_on.svg"></img>
													</span>
													&nbsp;
													<span
														style={{
															color: "#646363",
															fontWeight: "400",
														}}>
														KA
													</span>
												</div>
												<div
													style={{
														fontSize: "14px",
														fontWeight: "400",
													}}
													// className="lightgreen"
												>
													Posted: 15 days ago
												</div>
												<div style={{ marginTop: "10px" }}>
													<Button
														style={{
															background: "#3CCE8F",
															width: "100%",
															borderRadius: "4px",
															border: "none",
														}}>
														{" "}
														Apply Internship
													</Button>
												</div>{" "}
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>News you may like to read</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive3} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div
												className="card"
												style={{
													padding: "10px 10px 10px 10px",
													backgroundImage:
														"url(https://www.ulektznews.com/wp-content/uploads/2023/02/XLRI-secures-100-placement-in-one-day-highest-offer-at-Rs-1.1-crore.png)",
													width: "20vw",
													height: "243px",
													backgroundSize: "cover",
													backgroundPosition: "bottom",
													// background: "rgba(0, 0, 0,0.3)",
												}}>
												<br />
												<br />
												<br />
												<br />
												<br />
												<div
													style={{
														background: "#0F68B3",
														width: "fit-content",
														color: "#fff",
														// marginTop:"100px",
														fontSize: "12px",
													}}>
													Education
												</div>
												<div style={{ color: "#fff", fontWeight: "500" }}>
													XLRI Delhi Introduces PG in Automobile
												</div>
												<div
													style={{
														color: "#fff",
														fontSize: "13px",
														fontWeight: "500",
													}}>
													Armin Vans - 03/05/2024
												</div>
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
						<div
							className="row card"
							style={{
								marginTop: "25px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
									marginLeft: "0px",
								}}>
								<div className="col-11">
									<div>Scholarships you may like to apply</div>
								</div>
								<div className="col-1" style={{ textDecoration: "underline" }}>
									View All
								</div>
							</div>

							<Carousel responsive={responsive2} arrows={true}>
								{communitiesArray.map((e) => {
									return (
										<div style={{ padding: "10px" }}>
											<div className="card">
												<div style={{ background: "#F4F7F9" }}>
													<img
														src="https://d2w7l1p59qkl0r.cloudfront.net/static/scholarship_logo/logo_23883_1691649050.png"
														width="100%"></img>
												</div>

												<div style={{ fontWeight: "500", padding: "20px" }}>
													Buddy4Study-PNB International Education Loan Programme
												</div>
												<div
													style={{
														fontWeight: "400",
														fontSize: "14px",
														color: "646363",
														paddingLeft: "20px",
													}}>
													<img src="./images/graduate_icon.svg"></img>Students
													pursuing higher education abroad
												</div>
												<div
													style={{
														fontWeight: "400",
														fontSize: "14px",
														color: "646363",
														paddingLeft: "20px",
													}}>
													<img src="./images/Award.svg"></img>Students Rate of
													interest starting from 9.25%
												</div>
												<div
													style={{
														fontWeight: "400",
														fontSize: "14px",
														color: "646363",
														paddingLeft: "20px",
													}}>
													Last date: 2025-03-21
												</div>
												<div style={{ padding: "20px" }}>
													<Button
														style={{
															background: "#20ce8f",
															borderRadius: "3px",
															border: "none",
														}}>
														Apply Scholorship
													</Button>
												</div>
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Resources;
