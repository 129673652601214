import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDataAPI } from "../utils/fetchData";
const Payment = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const handlePayment = async () => {
		// const orderUrl = "http://localhost:3001/create-order";
		// const verifyUrl = "http://localhost:3001/verify-payment";

		// const orderResponse = await axios.post(orderUrl, {
		// 	amount: 500,
		// 	currency: "INR",
		// 	receipt: "receipt#1",
		// 	notes: { key1: "value1", key2: "value2" },
		// });
		const orderResponse = await postDataAPI(
			"createpayment",
			{
				amount: 500,
				currency: "INR",
				receipt: "receipt#1",
				notes: { key1: "value1", key2: "value2" },
			},
			auth.token
		);

		const { id: order_id, amount, currency } = orderResponse.data;
		console.log("orderResponse.data", orderResponse.data);

		const options = {
			key: "rzp_test_ZrlDFJqqD5ZYwg",
			amount,
			currency,
			name: "Foodsafety Academy",
			description: "Test Transaction",
			order_id,
			handler: async function (response) {
				const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
					response;

				// const verificationResponse = await axios.post(verifyUrl, {
				// 	order_id: razorpay_order_id,
				// 	payment_id: razorpay_payment_id,
				// 	signature: razorpay_signature,
				// });
				const verificationResponse = await postDataAPI(
					"verifypayment",
					{
						order_id: razorpay_order_id,
						payment_id: razorpay_payment_id,
						signature: razorpay_signature,
					},
					auth.token
				);

				if (verificationResponse.data.status === "success") {
					alert("Payment successful!");
				} else {
					alert("Payment verification failed");
				}
			},
			prefill: {
				name: auth.user.name,
				email: auth.user.email,
				contact: "9999999999",
			},
			notes: {
				address: "Some Address",
			},
			theme: {
				color: "#0db154",
			},
		};

		const rzp = new window.Razorpay(options);
		rzp.open();
	};

	return (
		<div style={{ margin: "20px 100px" }}>
			<button onClick={handlePayment}>Pay Now</button>
		</div>
	);
};

export default Payment;
