import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Platformfeatures = () => {
  return (
		<>
			<Navbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<h1 className="">Standards Coverage </h1>

				<div className="row">
					<div class="col" style={{}}>
						<ul className="mx-5" style={{ paddingLeft: "50px" }}>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								On Farm Production.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Post-Harvest.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								Sustainability.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								USDA and USFDA across food chain .
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Platformfeatures;
