import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Approach = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <h1 className="" style={{ textAlign: "center" }}>
          Our Approach to Address the Problem
        </h1>
        <h4 className="">Digital Learning Platform</h4>
        <div className="row">
          <div class="col" style={{}}>
            <p class="mb-4">
              We offer a comprehensive EdTech platform that serves as a hub for
              online education. Through virtual and instructor-led courses, we
              impart essential knowledge about global food safety and
              sustainability standards, spanning the entire food supply chain.
            </p>
          </div>
        </div>

        <h4 className="">Model Farm Initiative</h4>
        <div className="row">
          <div class="col" style={{}}>
            <p class="mb-4">
              Our Model Farms in key crop production areas demonstrate the
              practical application of global standards. Aligned with ISO 17065
              and Smart Farming Initiatives, these farms serve as benchmarks and
              training centers for farmers, Farmer Producer Organizations
              (FPOs), agricultural extension officers, researchers, students,
              and food processors.
            </p>
          </div>
        </div>

        <h4 className="">Onboarding Universities and Colleges</h4>
        <div className="row">
          <div class="col" style={{}}>
            <p class="mb-4">
              We partner with universities and colleges across the food supply
              chain, providing them with branded AI-powered mobile apps. These
              apps support students and professionals through:
            </p>
            <ul style={{ paddingLeft: "50px" }}>
              <h5>Institution Dashboard:</h5>
              <li className="mx-5 " style={{ listStyle: "disc" }}>
                Upload institution-specific courses
              </li>
              <li className="mx-5 " style={{ listStyle: "disc" }}>
                Engage through discussion boards
              </li>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                Access a digital library of learning resources
              </li>
              <h5>KrishiGAP Dashboard:</h5>
              <li className="mx-5 " style={{ listStyle: "disc" }}>
                Access to online courses
              </li>
              <li className="mx-5 "  style={{ listStyle: "disc" }}>
                Job placement support
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div class="col" style={{}}>
            <p class="mb-4">
              Students and faculty can log in via their institution’s app or
              cloud platform to access both the institution's and KrishiGAP’s
              resources.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Approach;
