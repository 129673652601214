import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useState } from "react";
import "../Menu.css";
const Footer = () => {
	const [visitCount, setvisitCount] = useState("000000");
	useEffect(() => {
		const currentCount = localStorage.getItem("visitCount");
		if (currentCount) {
			const newCount = parseInt(currentCount) + 1;
			setvisitCount(newCount);
			localStorage.setItem("visitCount", newCount);
		} else {
			setvisitCount(1);
			localStorage.setItem("visitCount", 1);
		}
	}, []);
	return (
		<div
			className="row"
			style={{
				background: "#013c64",
				paddingBottom: "100px",
				paddingTop: "50px",
				marginBottom: "-28px",
			}}>
			<div
				className="col-2 "
				style={{
					display: "flex",
					justifyContent: "center",
					// fontFamily: "Manrope",
					fontSize: "24px",
					fontWeight: "700",
					lineHeight: "32.78px",
					// textAlign: "left",
					color: "#ffffff",
				}}>
				Quick Link
			</div>
			<div
				className="col-2"
				style={{ display: "flex", flexDirection: "column" }}>
				{/* <a
					class=""
					href="/about"
					style={{
						fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					About Us
				</a> */}
				<a
					class=""
					href="/contactus"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Contact Us
				</a>
				<a
					class=""
					href="/privacypolicy"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Privacy Policy
				</a>
				<a
					class=""
					href="/termsandconditions"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Terms &amp; Conditions
				</a>
			</div>
			<div
				className="col-3"
				style={{ display: "flex", flexDirection: "column" }}>
				<a
					class=""
					href="/faq"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Feedback
				</a>
				<a
					class=""
					href="/cancellationandrefundpolicy"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Cancellation &amp; Refund Policy
				</a>
				{/* <a
					class=""
					href="/shippingdelivarypolicy"
					style={{
						fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Shipping &amp; Delivery Policy
				</a> */}
				<a
					class=""
					href="/disclaimer"
					style={{
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					Disclaimer
				</a>
			</div>
			{/* <div className="col-1"></div> */}
			<div className="col-5">
				<div
					class=""
					style={{
						display: "flex",
						justifyContent: "flex-end",
						// fontFamily: "Manrope",
						fontSize: "16px",
						fontWeight: "500",
						lineHeight: "21.86px",
						letterSpacing: "-0.02em",
						color: "#ffffff",
						// textAlign: left;
					}}>
					© Global Academy On Food Safety And Sustainability Standards. <br />
					All Rights Reserved.
				</div>
				<div className="row">
					<div
						class=""
						style={{
							marginTop: "30px",
							display: "flex",
							justifyContent: "flex-end",
						}}>
						<a class="" href="">
							<i
								class="fa fa-twitter "
								style={{ padding: "10px", color: "#ffffff" }}></i>
						</a>

						<a class="" href="">
							<i
								class="fa fa-facebook "
								style={{ padding: "10px", color: "#ffffff" }}></i>
						</a>
						<a class="" href="">
							<i
								class="fa fa-youtube "
								style={{ padding: "10px", color: "#ffffff" }}></i>
						</a>
						<a class="" href="">
							<i
								class="fa fa-linkedin "
								style={{ padding: "10px", color: "#ffffff" }}></i>
						</a>
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className="col"
					style={{ display: "flex", justifyContent: "center" }}>
					<div
						className=""
						style={{
							fontSize: "25px",
							background: "#00798d",
							color: "white",
							fontWeight: "bold",
							textAlign: "center",
							borderRadius: "5px",
							letterSpacing: "4px",
							paddingLeft: "8px",
							paddingRight: "5px",
							textShadow: "2px 2px #9d9193",
						}}
						// style={{
						//   fontWeight: "900",
						//   fontSize: "40px",
						//   display: "flex",
						//   color: "rgb(255, 255, 255)",
						//   justifyContent: "center",
						// }}
					>
						{visitCount}
					</div>
				</div>
			</div>
		</div>

		// <div
		//   class="container-fluid bg-dark1 text-light footer pt-5 mt-5 wow fadeIn"
		//   data-wow-delay="0.1s"
		//   style={{
		//     marginTop: "1rem",
		//     visibility: "visible",
		//     animationDelay: "0.1s;",
		//     animationName: "fadeIn",
		//     backgroundColor: "none",
		//   }}
		// >
		//   <div class=" " style={{ padding: "50px 0px 50px 100px" }}>
		//     <div class="row ">
		//       <div class="col-lg-4 col-md-6 hoverone">
		//         <h4 class="text-white mb-3">Quick Link</h4>
		//         <a class="btn btn-link font" href="/about">
		//           About Us
		//         </a>
		//         <a class="btn btn-link font" href="/contactus">
		//           Contact Us
		//         </a>
		//         <a class="btn btn-link font" href="/privacypolicy">
		//           Privacy Policy
		//         </a>
		//         <a class="btn btn-link font" href="/termsconditions">
		//           Terms &amp; Conditions
		//         </a>
		//         <a class="btn btn-link font" href="/faq">
		//           FAQs &amp; Help
		//         </a>
		//         <a class="btn btn-link font" href="/cancellationrefundpolicy">
		//           Cancellation &amp; Refund Policy
		//         </a>
		//         <a class="btn btn-link font" href="/shippingdelivarypolicy">
		//           Shipping &amp; Delivery Policy
		//         </a>
		//         <a class="btn btn-link font" href="/disclaimer">
		//           Disclaimer
		//         </a>
		//       </div>
		//       <div class="col-lg-12 text-center col-md-6">
		//         <div class="d-flex pt-2" style={{ marginTop: "30px" }}>
		//           <a class="btn btn-outline-light btn-social iconc" href="">
		//             <i class="fa fa-twitter icons"></i>
		//           </a>
		//           <a class="btn btn-outline-light btn-social iconc" href="">
		//             <i class="fa fa-facebook icons"></i>
		//           </a>
		//           <a class="btn btn-outline-light btn-social iconc" href="">
		//             <i class="fa fa-youtube icons"></i>
		//           </a>
		//           <a class="btn btn-outline-light btn-social iconc" href="">
		//             <i class="fa fa-linkedin icons"></i>
		//           </a>
		//         </div>
		//       </div>
		//     </div>
		//   </div>
		//   <div class="copyright">
		//     <div class="container">
		//       <div class="row">
		//         <div class="col-md-4"></div>

		//         <div class="col-md-4"></div>
		//         <div class="col-md-12 text-center mb-3 mb-md-0">
		//           © Good Agricultural Practices All Right Reserved.
		//           <br />
		//           <br />
		//         </div>
		//       </div>
		//     </div>
		//   </div>
		// </div>
	);
};

export default Footer;
