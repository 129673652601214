import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Aboutus = () => {
	return (
		<>
			{/* <Header /> */}
			{/* <div
				style={{
					padding: "20px 60px 20px 120px",
				}}>
				<div
					className="row card"
					style={{
						marginRight: "75px",
						border: "none",
						marginTop: "28px",
						borderRadiusTop: "15px",
						marginLeft: "10px",
						borderRadius: "15px",
						paddingBottom: "80px",
					}}>
					<div className="col " style={{ padding: "20px 60px 20px 60px" }}>
						<div
							style={{
								fontWeight: "500",
								fontSize: "30px",
								textAlign: "center",
								paddingBottom: "30px",
								color: "lightblue",
							}}>
							ABOUT US
						</div>

						<h1 class="mb-4 ">MISSION</h1>
						<div className="row">
							<div
								class="col wow fadeInUp"
								data-wow-delay="0.3s"
								style={{
									visibility: "visible",
									animationDelay: "0.3s",
									animationName: "fadeInUp",
								}}>
								<p class="mb-4">
									Our Mission is to promote food safety and sustainable
									standards by enhancing the availability of skilled manpower
									and inculcating a culture of food safety in the entire agri
									value chain and to create enhanced income to the farming
									community and safe food to the consumers
								</p>

								<p class="mb-4">
									This will be achieved through Digital Platform as a one stop
									solution by networking among food safety
									institutions/stakeholders in agri echo system.
								</p>
							</div>
						</div>
						<div className="row">
							<div
								class="col-lg-12 wow fadeInUp"
								data-wow-delay="0.3s"
								style={{
									visibility: "visible",
									animationDelay: "0.3s",
									animationName: "fadeInUp",
								}}>
								<h1 class=" pt-4">OUR APPROACH</h1>
								<p class="mb-4 text-center">
									Taking digital learning to crop production/food processing
									centers to empower farmer's organizations and food processors
									with ease and scale of implementation.
								</p>
							</div>
						</div>
						<div className="row">
							<div
								class="col-lg-6 mt-0 wow fadeInUp"
								data-wow-delay="0.3s"
								style={{
									visibility: "visible",
									animationDelay: "0.3s",
									animationName: "fadeInUp",
								}}>
								<div class="phase-box">
									<div class="phase-click">
										<a href="">Phase-1 : India</a>
									</div>
									<div class="img">
										<img
											src="../images/phase1.jpg"
											style={{ borderRadius: "10px" }}
										/>
									</div>
								</div>
							</div>

							<div
								class="col-lg-6 mt-0 wow fadeInUp"
								data-wow-delay="0.3s"
								style={{
									visibility: "visible",
									animationDelay: "0.3s",
									animationName: "fadeInUp",
								}}>
								<div class="phase-box">
									<div class="phase-click">
										<a href="">Phase-2 : Global</a>
									</div>
									<div class="img">
										<img
											src="../images/phase2.jpg"
											style={{ borderRadius: "10px" }}
										/>
									</div>
								</div>
							</div>
							<p class="mb-4 text-center pt-5">
								Maintaining the integrity and independence of its activities,
								Krishi GAP will not be involved in Certification Activities,
								which are done by Accredited Certification Bodies.
							</p>
						</div>
					</div>

					<div className="row" style={{ padding: "20px 60px 20px 60px" }}>
						<h1 class=" pt-4" style={{ paddingBottom: "30px" }}>
							MY ANALYTICS
						</h1>

						<div
							className="col-4 d-flex flex-column align-items-center"
							style={{
								borderRadius: "20px",
								width: "200px",
							}}>
							<div>
								<img src="../images/Courses.svg" alt=""></img>
							</div>
							<div style={{ fontSize: "50px", color: "green" }}>16</div>
							<div>Current Courses</div>

						</div>
						<div
							className="col-4 d-flex flex-column align-items-center"
							style={{
								borderRadius: "20px",
								width: "200px",
							}}>
							<div>
								<img src="../images/Members.svg" alt=""></img>
							</div>
							<div style={{ fontSize: "50px", color: "green" }}>121</div>
							<div> Active Members</div>
						</div>
						<div
							className="col-4 d-flex flex-column align-items-center"
							style={{
								borderRadius: "20px",
								width: "200px",
							}}>
							<div>
								<img src="../images/Events.svg" alt=""></img>
							</div>
							<div style={{ fontSize: "50px", color: "green" }}>5</div>
							<div>Events</div>
						</div>
					</div>
				</div>
			</div> */}
			<Navbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<h1 className="">About Us</h1>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							We are committed to digitally empowering stakeholders across the
							food supply chain by promoting compliance with global Food Safety
							and Sustainability Standards. Through our innovative approach, we
							bridge the gap between knowledge and practice, supporting farmers,
							processors, and other food supply chain actors in meeting
							international standards.
						</p>
					</div>
				</div>

				<h1 className="">Key Objectives</h1>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							Our objective is to tackle the critical challenges faced by
							farmers' organizations and food processors in adopting and scaling
							global standards. Our key objectives are to:
						</p>
						<ul style={{ paddingLeft: "50px" }} className="mx-5">
							<li style={{ listStyle: "disc" }}>
								Expand the availability of skilled professionals across the food
								supply chain.
							</li>
							<li style={{ listStyle: "disc" }}>
								Enhance the financial sustainability of farmers and processors.
							</li>
							<li style={{ listStyle: "disc" }}>
								Ensure the production of safe, high-quality food for consumers
								worldwide.
							</li>
							<li style={{ listStyle: "disc" }}>
								Support the achievement of the United Nations Sustainable
								Development Goals (SDGs).
							</li>
						</ul>
					</div>
				</div>

				<h1 className="">Vision</h1>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							To build a holistic, integrated solution that empowers all
							stakeholders in the food supply chain, driving global compliance
							with food safety and sustainability standards.
						</p>
					</div>
				</div>

				<h1 className="">Mission</h1>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							To develop an innovative digital platform that delivers both
							virtual and instructor-led skill development programs, while
							establishing Model Farms that demonstrate best practices and
							adherence to international food safety and sustainability
							standards.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Aboutus;
