import React from "react";
import { useSelector } from "react-redux";
import FollowBtn from "../FollowBtn";
import UserCard from "../UserCard";

const Following = ({ users, setShowFollowing }) => {
	const { auth } = useSelector((state) => state);
	return (
		<div className="follow">
			<div className="follow_box">
				<h5 className="text-center follow_box-heading">Following</h5>
				<hr />
				{users.map((user) => (
					<UserCard
						key={user.id}
						setShowFollowing={setShowFollowing}
						user={user}>
						{auth.user.id !== user.id && <FollowBtn user={user} />}
					</UserCard>
				))}

				<div className="close" onClick={() => setShowFollowing(false)}>
					&times;
				</div>
			</div>
		</div>
	);
};

export default Following;
