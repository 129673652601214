import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Targetaudience = () => {
	return (
		<>
			<Navbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<h1 className="">Impact Creation</h1>

				<div className="row">
					<div class="col" style={{}}>
						<ul className="mx-5" style={{ paddingLeft: "50px" }}>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								Certification of produce under recognized standards enhances
								income for farmers and processors.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Access to educational resources and Model Farms will create a
								pool of digitally empowered entrepreneurs.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								Food exporters/importers.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Adoption of standards ensures safe food for both the processing
								industry and consumers, while boosting exports.
							</li>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Widespread implementation of standards benefits farmers,
								processors, and consumers, elevating India’s position in the
								global market.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Targetaudience;
