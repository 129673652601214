import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoginRequest from "./loginRequest";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";

const Notifications = () => {
	const { theme, auth } = useSelector((state) => state);
	const [notificationsArray, setNotificationsArray] = useState([1, 2, 4]);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<div className="row" style={{ marginTop: "20px" }}>
						<div className="col-3"></div>
						<div className="col-6">
							{" "}
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-11"
									style={{ display: "flex", alignItems: "center" }}>
									Notifications
								</div>
								<div className="col-1">
									<img src="./images/options.svg"></img>
								</div>
							</div>
							{notificationsArray.map(() => {
								return (
									<>
										<div
											className="card row radius"
											style={{ borderRadius: "none", background: "#C1F2E2" }}>
											{" "}
											<div className="row" style={{ padding: "15px" }}>
												<div className="col-2" style={{ width: "auto" }}>
													<img
														src="./images/krishigaplogo.png"
														style={{
															borderRadius: "50%",
															width: "50px",
															border: "1px solid #ddd",
														}}></img>
												</div>
												<div
													className="col-9"
													style={{ display: "flex", alignItems: "center" }}>
													Learn how to earn more points on uLektz
												</div>
												<div
													className="col-1"
													style={{ display: "flex", alignItems: "center" }}>
													4h
												</div>
											</div>
										</div>
									</>
								);
							})}
						</div>
						<div className="col-4"></div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Notifications;
