import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import "../Menu.css";
import { logout } from "../redux/actions/authAction";
import Popupnav from "./popupnav";

function Navbar() {
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	return (
		<>
			<nav
				className="container-fluid"
				style={{
					background: "#fff",
					height: "85px",
					display: "flex",
					alignItems: "center",
					padding: "0 115px",
					zIndex: "12",
					justifyContent: "space-between",
				}}>
				<div
					className="d-flex align-items-center justify-content-between"
					style={{ width: "350px" }}>
					<Popupnav />
					<a
						class="navbar-brand"
						href="/"
						style={{
							color: "green",
							fontSize: "26px",
							fontWeight: "700",
							display: "flex",
							flexDirection: "column",
						}}>
						GLOBAL ACADEMY
						<span
							style={{ color: "#000000", fontSize: "14px", fontWeight: "700" }}>
							On Food Safety And Sustainability Standards
						</span>
					</a>
				</div>
				{/* <Bars className="bars1" /> */}
				<div style={{ display: "flex" }}>
					<div className="navmenu1">
						{/* <div className="burger">
            <i class="fa-solid fa-bars"></i>
          </div> */}
						{/* <NavLink to="/home" className="navlink1">
            HOME
          </NavLink> */}
						<NavLink
							id="color"
							to="/"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							HOME
						</NavLink>
						{/* <NavLink
							to="/about"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							ABOUT US
						</NavLink> */}
						<div className="navlink1" activeStyle style={{ padding: "0" }}>
							<div class="dropdown">
								<button class="dropbtn" style={{ fontWeight: "700" }}>
									ABOUT US
									<i
										style={{ paddingLeft: "5px" }}
										class="fa fa-caret-down"></i>
								</button>
								<div class="dropdown-content">
									<a href="/aboutus" style={{ fontWeight: "500" }}>
										About&nbsp;Us
									</a>
									<a href="/companytraction" style={{ fontWeight: "500" }}>
										Company&nbsp;Traction
									</a>
									<a href="/ourteam" style={{ fontWeight: "500" }}>
										Our&nbsp;Team
									</a>
									<a href="/experts" style={{ fontWeight: "500" }}>
										Experts
									</a>
								</div>
							</div>
						</div>
						{/* <NavLink
							to="/whatwedo"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							WHAT WE DO
						</NavLink> */}
						<div className="navlink1" activeStyle style={{ padding: "0" }}>
							<div class="dropdown">
								<button class="dropbtn" style={{ fontWeight: "700" }}>
									WHAT WE DO
									<i
										style={{ paddingLeft: "5px" }}
										class="fa fa-caret-down"></i>
								</button>
								<div class="dropdown-content">
									<a href="/problemstatement" style={{ fontWeight: "500" }}>
										Problem&nbsp;Statement
									</a>
									<a href="/approach" style={{ fontWeight: "500" }}>
										Our&nbsp;Approach
									</a>
									<a href="/platformfeatures" style={{ fontWeight: "500" }}>
										Platform &nbsp;Features
									</a>
									<a href="/standardscoverage" style={{ fontWeight: "500" }}>
										Standards&nbsp;Coverage{" "}
									</a>
									<a href="/targetaudience" style={{ fontWeight: "500" }}>
										Target&nbsp;Audience
									</a>
									<a href="/impactcreation" style={{ fontWeight: "500" }}>
										Impact&nbsp;Creation
									</a>
								</div>
							</div>
						</div>
						<NavLink
							to="/faqs"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							FAQS
						</NavLink>

						<div class="dropdown ">
							<button class="dropbtn">
								<i style={{ paddingLeft: "" }} class="fa fa-user"></i>
								<i style={{ paddingLeft: "10px" }} class="fa fa-caret-down"></i>
							</button>
							<div class="dropdown-content">
								{auth.user === undefined ? (
									<a href="/login">Login</a>
								) : (
									<>
										{/* <a href="./myprofile">My Profile</a>
										<a href="./mybooks">My Books</a>
										<a href="./myvideos">My Videos</a>
										<a href="./mycources">My Courses</a>
										<a href="./myevents">My Events</a>
										<a href="./myjobs">My Jobs</a>
										<a href="./myorders">My Orders</a>
										<hr className="dropdown-divider" /> */}
										<Link
											// style={{ color: "white" }}
											className="dropdown-item"
											to="/login"
											onClick={() => dispatch(logout())}>
											Logout
										</Link>
									</>
								)}
							</div>
						</div>

						{/* <NavLink to="" className="navlink1" activeStyle> */}
						{/* <div className="navlink1">
            <div class="dropdown ">
              <button class="dropbtn">
                WHAT WE DO
                <i style={{ paddingLeft: "5px" }} class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a href="./digitalenablement">Digital&nbsp;Enablement</a>
                <a href="./impactcreation">Impact&nbsp;Creation</a>
                <a href="./backgroundinitiation">
                  Background&nbsp;behind&nbsp;the&nbsp;Initiative
                </a>
              </div>
            </div>
          </div> */}

						{/* <NavLink to="/events" className="navlink1" activeStyle>
            EVENTS
          </NavLink> */}
						{/* <div className="navlink1" activeStyle>
            <div class="dropdown">
              <button class="dropbtn">
                STANDARDS
                <i style={{ paddingLeft: "5px" }} class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content">
                <a href="./onfarmproduction">On&nbsp;Farm&nbsp;Production</a>
                <a href="./postprocessing">Post&nbsp;Harvest&nbsp;Processing</a>
                <a href="./sustainableiniatives">Sustainable&nbsp;Initiative</a>
                <a href="./login">Others</a>
                <a href="./login">
                  Steps&nbsp;In&nbsp;Obtaining&nbsp;Certificate
                </a>
                <a href="./login">Accredited&nbsp;Certification&nbsp;Bodies</a>
              </div>
            </div>
          </div> */}
						{/* <NavLink to="/contactus" className="navlink1" activeStyle>
            CONTACT
          </NavLink>
          <NavLink to="/news" className="navlink1" activeStyle>
            NEWS
          </NavLink> */}
					</div>

					{/* <div style={{ paddingLeft: "100px" }}>
          <img
            style={{ width: "20px", height: "20px" }}
            src="../../images/search.png"
            alt=""
          />
        </div> */}
					{/* <div style={{ paddingLeft: "50px" }}>
            <i class="fa fa-user " style={{ width: "", height: "" }}></i>
          </div> */}
				</div>

				{/* <Signin />
        <button
          className="navbtnlink1"
          variant="primary"
          onClick={() => (window.location.href = "/login")}
        >
          Log in
        </button>

        <button className="navbtnlink1" to="/signup">
          DOWNLOAD THE APP
        </button> */}
			</nav>
			<div className="row">
				<div className="col"></div>
			</div>
			<div className="row" style={{ backgroundColor: "green", marginTop: "" }}>
				<marquee
					style={{ fontWeight: "700", fontSize: "20px", color: "#ffffff" }}>
					{" "}
					*GLOBAL ACADEMY ON FOOD SAFETY AND SUSTAINABILITY STANDARDS*
				</marquee>
			</div>
		</>
	);
}

export default Navbar;
