import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/esm/Button";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import "./pdfreaderstyles.css";
import PdfViewer from "./pdfviewer";
import Test from "./test";

const Pdfreader = () => {
	const { profile, auth } = useSelector((state) => state);
	console.log("auth", auth);
	const dispatch = useDispatch();
	const [pdfFile, setPdfFile] = useState("");
	const [data, setData] = useState(null);
	const [viewChange, setViewChange] = useState("");
	const [startTest, setStartTest] = useState("");
	const [sections, setSections] = useState([]);

	useEffect(() => {
		if (auth.token) {
			getDataAPI(
				`getCourseById/${window.location.pathname.split("/")[2]}/${
					auth.user.id
				}`,
				auth.token
			).then((res) => {
				console.log("res my communities", res.data.course);
				if (res.data.courses !== null) {
					setData(res.data.course);
				}
			});
			// console.log("data", data);
		}
	}, [auth]);

	useEffect(() => {
		let tempSections = [];
		if (data !== null) {
			data.textlist.forEach((value) => {
				if (!tempSections.includes(value.section)) {
					tempSections.push(value.section);
				}
			});
		}
		console.log("tempSections", tempSections);
		setSections(tempSections);
	}, [data]);

	const [selectedOptions, setSelectedOptions] = useState({});

	const handleOptionChange = (questionIndex, selectedOption) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			[questionIndex]: selectedOption,
		}));
	};

	const [started, setStarted] = useState(false);
	const [results, setResults] = useState(null);
	const [score, setScore] = useState(0);

	const handleStart = async () => {
		setStarted(true);
		// postDataAPI(
		// 	`generatecertificate`,
		// 	{
		// 		fullname: auth.user.fullname,
		// 		email: auth.user.email,
		// 		coursetitle: data.coursetitle + " : " + data.subcategory.name,
		// 		courseduration: data.duration,
		// 	},
		// 	auth.token
		// ).then((res) => {
		// 	console.log("generate certificate ", res.data);
		// });
		try {
			const res = await postDataAPI(
				"examstarted",
				{
					userid: auth.user.id,
					courseid: data.id,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// window.location.reload();
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handleSubmit = (answers) => {
		setResults(answers);
		setStarted(false);
	};

	const handleScore = async (score) => {
		// alert(score)
		setScore(score);
		// console.log("score", score);
		var percentage = (score / data.questionslist.length) * 100;
		// console.log("percentage", percentage);
		try {
			const res = await postDataAPI(
				"examended",
				{
					userid: auth.user.id,
					courseid: data.id,
					percentage: percentage,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// window.location.reload();
				if (Number(percentage) > 60) {
					postDataAPI(
						`generatecertificate`,
						{
							fullname: auth.user.fullname,
							email: auth.user.email,
							coursetitle: data.coursetitle + " : " + data.subcategory.name,
							courseduration: data.duration,
						},
						auth.token
					).then((res) => {
						console.log("generate certificate ", res.data);
					});
				}
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			setPdfFile(fileURL);
			// window.open(fileURL);
			setViewChange(false);
			setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};
	return (
		<div className="App pdf-container" style={{ marginTop: "-28px" }}>
			<div
				style={{
					color: "#fff",
					background: "#0C67DA",
					textAlign: "center",
					padding: "10px",
					fontSize: "23px",
				}}>
				{data && data.coursetitle}
			</div>
			<div className="row">
				{/* <div className="col-1"></div> */}
				<div className="col-3" style={{ background: "#000" }}>
					<div style={{ padding: "25px" }}>
						<div className="pt-4">
							<img
								src={data && data.courseimage}
								style={{ width: "100%" }}></img>
						</div>
						<div
							style={{
								fontSize: "23px",
								fontWeight: "500",
								minHeight: "36px",
								color: "#fff",
								paddingTop: "10px",
							}}>
							{data && data.coursetitle}
						</div>
					</div>
					<div
						style={{
							color: "#fff",
							background: "#0C67DA",
							textAlign: "center",
							padding: "10px",
							marginRight: "-12px",
						}}>
						Course Study Materials
					</div>
					<div>
						{/* <Accordion style={{ marginRight: "-12px" }}>
							{sections.map((c) => {
								return (
									data &&
									data.files.map((e, index) => {
										if (e. === c) {
											return (
												<Fragment key={index}>
													<Accordion.Item eventKey={index}>
														<Accordion.Header
															style={
																{
																	// marginBottom: "10px",
																	// borderBottom: "1px solid #ddd",
																}
															}>
															{e.name}
														</Accordion.Header>
														<Accordion.Body
															style={{
																// padding: "5px",
																// borderTop: "1px solid #ddd",
																background: "#fff",
															}}>
															<div
																onClick={() => {
																	fileConversion(e.file);
																}}
																style={{ cursor: "pointer" }}>
																<img
																	alt="pdf"
																	src="../images/PDF.svg"
																	style={{ height: "20px" }}></img>
																<span
																	style={{ color: "green", paddingLeft: "10px" }}>
																	{e.name}
																</span>
															</div>
														</Accordion.Body>
													</Accordion.Item>
												</Fragment>
											);
										}
									})
								);
							})}
							<Accordion.Item eventKey={data && data.files.length}>
								<Accordion.Header
									style={
										{
											// marginBottom: "10px",
											// borderBottom: "1px solid #ddd",
										}
									}>
									<p style={{ fontWeight: 900, color: "#36BB7D" }}>
										Final Assessment
									</p>
								</Accordion.Header>
								<Accordion.Body
									style={{
										// padding: "5px",
										// borderTop: "1px solid #ddd",
										background: "#fff",
									}}>
									<div
										onClick={() => setViewChange(true)}
										style={{ cursor: "pointer" }}>
										{/* <img
                      src="./images/PDF.svg"
                      style={{ height: "20px" }}
                    ></img>
										<span style={{ color: "green", paddingLeft: "10px" }}>
											Final Assessment
										</span>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion> */}

						{sections.map((c) => {
							if (c !== "") {
								return (
									<>
										<Accordion
											style={{
												margin: "10px 0",
											}}>
											<Accordion.Item eventKey={c}>
												<Accordion.Header
													style={{
														borderBottom: "1px solid #ddd",
													}}>
													<p
														style={{
															fontWeight: 900,
															color: "#36BB7D",
														}}>
														{c}
													</p>
												</Accordion.Header>

												<Accordion.Body
													style={{
														padding: "15px",
														borderTop: "1px solid #ddd",
														// display: "flex",
													}}>
													{data.textlist.map((e, index) => {
														if (e.section === c) {
															return (
																<Accordion
																	key={index}
																	style={{ marginBottom: "5px" }}>
																	<Accordion.Item eventKey={index}>
																		<Accordion.Header
																			style={
																				{
																					// marginBottom: "10px",
																					// borderBottom: "1px solid #ddd",
																				}
																			}>
																			{e.name}
																		</Accordion.Header>
																		<Accordion.Body
																			style={{
																				// padding: "5px",
																				// borderTop: "1px solid #ddd",
																				background: "#fff",
																			}}>
																			<div
																				onClick={() => {
																					fileConversion(e.file);
																				}}
																				style={{ cursor: "pointer" }}>
																				<img
																					alt="pdf"
																					src="../images/PDF.svg"
																					style={{ height: "20px" }}></img>
																				<span
																					style={{
																						color: "green",
																						paddingLeft: "10px",
																					}}>
																					{e.name}
																				</span>
																			</div>
																		</Accordion.Body>
																	</Accordion.Item>
																</Accordion>
															);
														}
													})}
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</>
								);
							} else {
								return data.textlist.map((e, index) => {
									if (e.section === c) {
										return (
											<>
												<Accordion key={index} style={{ margin: "10px 0" }}>
													<Accordion.Item eventKey={index}>
														<Accordion.Header
															style={
																{
																	// marginBottom: "10px",
																	// borderBottom: "1px solid #ddd",
																}
															}>
															{e.name}
														</Accordion.Header>
														<Accordion.Body
															style={{
																// padding: "5px",
																// borderTop: "1px solid #ddd",
																background: "#fff",
															}}>
															<div
																onClick={() => {
																	fileConversion(e.file);
																}}
																style={{ cursor: "pointer" }}>
																<img
																	alt="pdf"
																	src="../images/PDF.svg"
																	style={{ height: "20px" }}></img>
																<span
																	style={{
																		color: "green",
																		paddingLeft: "10px",
																	}}>
																	{e.name}
																</span>
															</div>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</>
										);
									}
								});
							}
						})}
						<Accordion style={{ margin: "10px 0" }}>
							<Accordion.Item eventKey={data && data.files.length}>
								<Accordion.Header
									style={
										{
											// marginBottom: "10px",
											// borderBottom: "1px solid #ddd",
										}
									}>
									<p style={{ fontWeight: 900, color: "#36BB7D" }}>
										Final Assessment
									</p>
								</Accordion.Header>
								<Accordion.Body
									style={{
										// padding: "5px",
										// borderTop: "1px solid #ddd",
										background: "#fff",
									}}>
									<div
										onClick={() => setViewChange(true)}
										style={{ cursor: "pointer" }}>
										<span style={{ color: "green", paddingLeft: "10px" }}>
											Final Assessment
										</span>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
				<div className="col-9">
					{/* <h1>PDF Viewer</h1> */}
					{viewChange === false && <PdfViewer pdfFile={pdfFile} />}
					{/* {viewChange === false && <PdfViewerWithSearch pdfFile={pdfFile} />} */}
					{viewChange === true && (
						<div
							style={{
								background: "#fff",
								height: "100vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<div
								className="card"
								style={{
									width: "45vw",
									// height: "40vh",
									paddingBottom: "15px",
								}}>
								<div
									style={{
										background: "#203F54",
										color: "#fff",
										padding: "10px",
										fontSize: "20px",
									}}>
									{data.coursetitle}
									<br />
									{/* <span style={{ fontSize: "13px" }}>Published by:mahesh</span> */}
								</div>
								{data.questionslist.length > 0 ? (
									<>
										<div
											style={{
												textAlign: "center",
												fontSize: "23px",
												padding: "10px",
											}}>
											Final Assesment
										</div>
										<div
											className="row"
											style={{ paddingTop: "10px", paddingBottom: "10px" }}>
											<div
												className="col-4"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												{/* <div style={{ color: "red", marginLeft: "-5px" }}>8</div> */}
												{data.questionslist.length}
												<br />
												Questions
											</div>
											<div
												className="col-4"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<i className="fa fa-sharp fa-solid fa-arrow-trend-up"></i>
												{data.questionslist.length *
													parseInt(data.questionsduration)}
												<br />
												Minutes
											</div>
											<div
												className="col-4"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<i
													className="fa fa-regular fa-clock fa-3x"
													style={{ color: "#ebb813", paddingRight: "4px" }}></i>
												{data.questionslist.length}
												<br />
												Marks
											</div>
										</div>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<Button
												style={{
													background: "#20ce8f",
													border: "none",
													borderRadius: "4px",
												}}
												onClick={() => {
													setStartTest(true);
													setViewChange("");
													handleStart();
												}}>
												Start test
											</Button>
										</div>
									</>
								) : (
									<>
										<div
											style={{
												textAlign: "center",
												fontSize: "23px",
												padding: "10px",
											}}>
											No Assessment for this course
										</div>
									</>
								)}
							</div>
						</div>
					)}
					{startTest === true && (
						<>
							<div
								style={{
									background: "#fff",
									height: "100vh",
									padding: "10px",
								}}>
								<div
									style={{
										background: "#203F54",
										color: "#fff",
										padding: "10px",
										fontSize: "20px",
										borderRadius: "5px",
										marginBottom: "10px",
									}}>
									{data.coursetitle}
									<br />
									{/* <span style={{ fontSize: "13px" }}>Published by:mahesh</span> */}
								</div>
								<div
									style={{
										background: "lightgrey",
										color: "#fff",
										padding: "20px",
										fontSize: "20px",
										borderRadius: "5px",
									}}>
									Final Assesment
								</div>
								{/* <div>
                  {data &&
                    data.questionslist.map((questionData, index) => (
                      <Question
                        key={index}
                        question={questionData.question}
                        options={questionData.options}
                        selectedOption={selectedOptions[index]}
                        handleOptionChange={(selectedOption) =>
                          handleOptionChange(index, selectedOption)
                        }
                      />
                    ))}
                </div> */}
								<div>
									{results ? (
										<div style={{ textAlign: "center", marginTop: "50px" }}>
											<h2>Test Results</h2>
											{/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
											{/* <div>{results}</div> */}
											<p>
												Your score is {score} out of {data.questionslist.length}
											</p>
										</div>
									) : started ? (
										<Test
											onSubmit={handleSubmit}
											questions={data.questionslist}
											questionsduration={data.questionsduration}
											scoretemp={handleScore}
										/>
									) : (
										// <StartTest onStart={handleStart} />
										<></>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Pdfreader;
