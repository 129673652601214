import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF, postDataAPI } from "../utils/fetchData";
import AddExistingFiles from "./addExistingFiles";
import Addfreecourse from "./addfreecourse";
import "./communitystyles.css";
import FreeCoursesAction from "./freeCoursesAction";
import LoginRequest from "./loginRequest";

const Courses = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	// console.log("profile", profile, auth);

	const [communitiesArray, setCommunitiesArray] = useState([]);
	const [communities, setCommunities] = useState([]);
	const [categoriesArray, setCategoriesArray] = useState([]);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showCategorieModal, setShowCategorieModal] = useState(false);
	const [showSubCategorieModal, setShowSubCategorieModal] = useState(false);
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [showReferenceModal, setShowReferenceModal] = useState(false);
	const [showCourseTab, setShowCourseTab] = useState(true);
	const [retake, setRetake] = useState(false);
	const [displayContent, setDisplayContent] = useState("");
	const [courseCategorieArray, setCourseCategorieArray] = useState([]);
	const [courseSubCategorieArray, setCourseSubCategorieArray] = useState([]);
	const [courseImage, setCourseImage] = useState("");
	const [courseTitle, setCourseTitle] = useState("");
	const [aboutCourse, setAboutCourse] = useState("");
	const [topics, setTopics] = useState("");
	const [courseDuration, setCourseDuration] = useState("");
	const [courseValidity, setCourseValidity] = useState("");
	const [questionsDuration, setQuestionsDuration] = useState("");
	const [targetAudience, setTargetAudience] = useState("");
	const [cost, setCost] = useState("");
	const [stepsInvolved, setStepsInvolved] = useState("");
	const [modules, setModules] = useState("");
	const [learningOutcomes, setLearningOutcomes] = useState("");
	const [obtaining, setObtaining] = useState("");
	const [eligibility, setEligibility] = useState("");
	const [proceed, setProceed] = useState("");
	const [courseOverviewFile, setCourseOverviewFile] = useState("");
	const [topicsFile, setTopicsFile] = useState("");
	const [outcomesFile, setOutcomesFile] = useState("");
	const [obtainingFile, setObtainingFile] = useState("");
	const [eligibilityFile, setEligibilityFile] = useState("");
	const [proceedFile, setProceedFile] = useState("");
	const [courseOverviewActualFile, setCourseOverviewActualFile] = useState("");
	const [topicsActualFile, setTopicsActualFile] = useState("");
	const [outcomesActualFile, setOutcomesActualFile] = useState("");
	const [obtainingActualFile, setObtainingActualFile] = useState("");
	const [eligibilityActualFile, setEligibilityActualFile] = useState("");
	const [proceedActualFile, setProceedActualFile] = useState("");
	const [stepsInvolvedFile, setStepsInvolvedFile] = useState("");
	const [stepsInvolvedActualFile, setStepsInvolvedActualFile] = useState("");
	const [courseName, setCourseName] = useState("");
	const [subCourseName, setSubCourseName] = useState("");
	const [language, setLanguage] = useState("");
	const [reference, setReference] = useState("");
	const [courseDescription, setCourseDescription] = useState("");
	const [subCourseDescription, setSubCourseDescription] = useState("");
	const [showCard, setShowCard] = useState(false);
	const [activeTab, setActiveTab] = useState("home");
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [textlist, setTextlist] = useState([
		{ name: "", file: "", section: "" },
	]);
	const [referencelist, setReferencelist] = useState([
		{ title: "", name: "", file: "", link: "", source: "" },
	]);
	const [question, setQuestion] = useState("");
	const [answers, setAnswers] = useState("");
	const [questionAnswer, setQuestionAnswer] = useState("");
	const [questionsList, setQuestionsList] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [references, setReferences] = useState([]);
	const [filesList, setfilesList] = useState([]);
	const [referencefilesList, setReferencefilesList] = useState([]);
	const [userCourses, setUserCourses] = useState([]);
	const [addbutton, setAddbutton] = useState(false);
	const [userRole, setUserRole] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [selectedCourse, setSelectedCourse] = useState({});
	const [freeCourses, setFreeCourses] = useState([]);
	const [organisationArray, setOrganisationArray] = useState([]);
	const [courseRegisterModel, setCourseRegisterModel] = useState(false);
	const [loginModel, setLoginModel] = useState(false);
	const initialState = {
		username: "",
		headline: "",
		email: "",
		location: "",
		mobile: "",
		role: "",
	};
	const [userData, setUserData] = useState(initialState);

	const data = new FormData();

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (auth.token) {
			getDataAPI(
				`getcommunityuser/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);

				getDataAPI(
					`getAllCourses/${auth.user.id}/${
						window.location.pathname.split("/")[2]
					}`,
					auth.token
				).then((res) => {
					console.log("res my courses", res.data);

					setCommunitiesArray(res.data.courses);
					setCommunities(res.data.courses);
					setCategoriesArray(res.data.categories);
				});
			});

			getDataAPI(`getusersavedbyid/${auth.user.id}`, auth.token).then((res) => {
				console.log("res my communities", res.data);
				if (res.data.usersaved !== null && res.data.usersaved.courses !== "") {
					setUserCourses(JSON.parse(res.data.usersaved.courses));
				}
				// setMycommunitiesArray(res.data.community);
			});

			getDataAPI(`getfreecourses`).then((res) => {
				console.log("res getcourseorganisations", res.data);
				// setCommunitiesArray(res.data.community);
				setFreeCourses(res.data.freecourses);
				setOrganisationArray(res.data.organisationNames);
				setTimeout(() => {
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				}, 1000);
			});
			// getDataAPI(`getcourseorganisation`, auth.token).then((res) => {
			// 	console.log("res getcourseorganisations", res.data);
			// 	// setCommunitiesArray(res.data.community);
			// 	setOrganisationArray(res.data.organisations);setCommunitiesArray
			// });
		} else {
			setUserRole("user");

			getDataAPI(
				`getAllCoursesWithoutLogin/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`
			).then((res) => {
				console.log("res my courses", res.data);

				setCommunitiesArray(res.data.courses);
				setCommunities(res.data.courses);
				setCategoriesArray(res.data.categories);
			});
			getDataAPI(`getfreecourses`).then((res) => {
				console.log("res getcourseorganisations", res.data);
				// setCommunitiesArray(res.data.community);
				setFreeCourses(res.data.freecourses);
				setOrganisationArray(res.data.organisationNames);
				setTimeout(() => {
					dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				}, 1000);
			});
			// getDataAPI(`getcourseorganisation`, auth.token).then((res) => {
			// 	console.log("res getcourseorganisations", res.data);
			// 	// setCommunitiesArray(res.data.community);
			// 	setOrganisationArray(res.data.organisations);
			// });
		}
	}, [auth]);
	// dispatch({
	// 	type: GLOBALTYPES.ALERT,
	// 	payload: { loading: true },
	// });
	useEffect(() => {
		if (questionAnswer === "" || question === "" || answers === "") {
			setAddbutton(true);
		} else {
			setAddbutton(false);
		}
	}, [questionAnswer, question, answers]);

	// useEffect(() => {

	// }, [communitiesArray, categoriesArray]);

	useEffect(() => {
		if (searchValue === "") {
			setCommunitiesArray(communities);
			return;
		}
		const filterBySearch = communities.filter((item) => {
			if (
				JSON.stringify(item.coursetitle)
					.toLowerCase()
					.includes(searchValue.toLowerCase()) ||
				JSON.stringify(item.cost)
					.toLowerCase()
					.includes(searchValue.toLowerCase()) ||
				JSON.stringify(item.targetaudience)
					.toLowerCase()
					.includes(searchValue.toLowerCase()) ||
				JSON.stringify(item.category)
					.toLowerCase()
					.includes(searchValue.toLowerCase())
			) {
				return item;
			}
		});

		setCommunitiesArray(filterBySearch);
	}, [searchValue]);

	useEffect(() => {
		getDataAPI(`getcoursecategory`, auth.token).then((res) => {
			console.log("res getcoursecategory", res.data);
			// setCommunitiesArray(res.data.community);
			setCourseCategorieArray(res.data.categories);
		});
		getDataAPI(`getlanguages`, auth.token).then((res) => {
			console.log("res getlanguages", res.data);
			// setCommunitiesArray(res.data.community);
			setLanguages(res.data.languages);
		});
		getDataAPI(`getreferences`, auth.token).then((res) => {
			console.log("res getreferences", res.data);
			// setCommunitiesArray(res.data.community);
			setReferences(res.data.references);
		});
	}, []);

	useEffect(() => {
		if (selectedCategory !== "") {
			getDataAPI(`getcoursesubcategory/${selectedCategory}`, auth.token).then(
				(res) => {
					console.log("res getcourse subcategory", res.data);
					// setCommunitiesArray(res.data.community);
					setCourseSubCategorieArray(res.data.subcategories);
				}
			);
		}
	}, [selectedCategory]);

	// console.log(retake);

	const handleShowQuestion = () => {
		// let test1 = answers.split(",");
		// console.log(test1);
		let test = answers
			.split(",")
			.map((part) => part.trim())
			.join(",")
			.split(",");
		// console.log(test.split(","));
		if (test.includes(questionAnswer)) {
			let item = {
				question: question,
				options: test,
				answer: questionAnswer,
			};
			console.log(item);
			setQuestionsList((prevState) => [...prevState, item]);
			setQuestion("");
			setAnswers("");
			setQuestionAnswer("");
		} else {
			alert("Please Give correct option");
		}
	};

	const [selectedOptions, setSelectedOptions] = useState({});

	const handleOptionChange = (questionIndex, selectedOption) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			[questionIndex]: selectedOption,
		}));
	};

	const handleEditCourse = (course) => {
		console.log("course", course);
		setSelectedCourse(course);
		setShowEditModal(true);
		setSelectedCategory(course.category.id);
		setSelectedSubCategory(course.subcategory.id);
		setSelectedLanguage(course.language.id);
		setCourseImage(course.courseimage);
		setCourseTitle(course.coursetitle);
		setAboutCourse(course.aboutcourse);
		setCourseOverviewFile(course.overviewfile);
		setTopics(course.topics);
		setTopicsFile(course.topicsfile);
		setTargetAudience(course.targetaudience);
		setModules(course.modules);
		setCourseDuration(course.duration);
		setCourseValidity(course.validity);
		setQuestionsDuration(course.questionsduration);
		setLearningOutcomes(course.learningoutcome);
		setOutcomesFile(course.outcomesfile);
		setObtaining(course.obtaining);
		setObtainingFile(course.obtainingfile);
		setEligibility(course.eligibility);
		setEligibilityFile(course.eligibilityfile);
		setProceed(course.howtoproceed);
		setProceedFile(course.howtoproceedfile);
		// setInstructorInformation(course.instructorinformation);
		course.textlist.length > 0
			? setTextlist(course.textlist)
			: setTextlist([{ name: "", file: "", section: "" }]);
		course.referencelist.length > 0
			? setReferencelist(course.referencelist)
			: setReferencelist([
					{ title: "", name: "", file: "", link: "", source: "" },
			  ]);
		setRetake(course.retake);
		setCost(course.cost);
		setQuestionsList(course.questionlist);
	};

	const handleSetCourseInactive = async (course) => {
		getDataAPI(
			`inactivecourse/${course.id}/${window.location.pathname.split("/")[2]}`,
			auth.token
		).then((res, err) => {
			console.log("res my communities", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};

	const addtomycartclick = async (courseid) => {
		// console.log("courseid", courseid);
		try {
			const res = await postDataAPI(
				"createcart",
				{
					userid: auth.user.id,
					itemid: courseid,
					communityid: window.location.pathname.split("/")[2],
					itemtype: "course",
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				window.location.reload();
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handlertextrchange = (e, index) => {
		const { name, value } = e.target;
		const list = [...textlist];
		list[index][name] = value;
		setTextlist(list);
	};

	const handlereferencechange = (e, index) => {
		const { name, value } = e.target;
		const list = [...referencelist];
		list[index][name] = value;
		setReferencelist(list);
	};

	const handleFile = (e, index) => {
		const file = e.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			console.log(url);
			const { name, value } = e.target;
			const list = [...textlist];
			list[index][name] = url;
			setTextlist(list);
			console.log("list", list);

			//  setCourseOverviewFile(url);
			const list1 = [...filesList];
			list1[index] = file;
			setfilesList(list1);
			console.log("list1", list1);
		}

		// setfilesList([...filesList, (filesList[index] = file)]);
		// data.append(file.name, file);
	};

	const handleReferenceFile = (e, index) => {
		const file = e.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			console.log(url);
			const { name, value } = e.target;
			const list = [...referencelist];
			list[index][name] = url;
			setReferencelist(list);
			console.log("list", list);

			//  setCourseOverviewFile(url);
			const list1 = [...referencefilesList];
			list1[index] = file;
			setReferencefilesList(list1);
			console.log("list1", list1);
		}

		// setfilesList([...filesList, (filesList[index] = file)]);
		// data.append(file.name, file);
	};

	const handlertextremove = (index) => {
		const list = [...textlist];
		list.splice(index, 1);
		setTextlist(list);
	};

	const handlerquestionremove = (index) => {
		const list = [...questionsList];
		list.splice(index, 1);
		setQuestionsList(list);
	};

	const handlereferenceremove = (index) => {
		const list = [...referencelist];
		list.splice(index, 1);
		setReferencelist(list);
	};

	useEffect(() => {
		console.log("reference list", referencelist);
		console.log("reference files list", referencefilesList);
	}, [referencefilesList, referencelist]);

	const handleTextadd = () => {
		setTextlist([...textlist, { name: "", file: "", section: "" }]);
	};

	const handleReferenceadd = () => {
		setReferencelist([...referencelist, { name: "", file: "", section: "" }]);
	};

	const validEmailExp = new RegExp(
		"^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
	);

	const getButtonClass = () => {
		let str = [];
		let rvalue = "aebuttongrey";
		textlist.forEach((list) => {
			if (
				list.email !== "" &&
				list.name !== "" &&
				validEmailExp.test(list.email)
			) {
				str.push("full");
			} else {
				str.push("notfull");
			}
		});

		let mstr = "," + str.join() + ",";
		if (mstr.indexOf(",notfull,") >= 0) {
			rvalue = "aebuttongrey";
		} else {
			rvalue = "aebuttonblack";
		}
		return rvalue;
	};

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#fff",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
						color: "#000",
					}}>
					<img src="../images/Upload.svg"></img> Upload
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		setShowUploadModal(true);
	};
	const handleCloseUploadModal = () => {
		setShowUploadModal(false);
	};

	const handleCloseEditModal = () => {
		setShowEditModal(false);
	};

	const handleCloseCategorieModal = () => {
		setShowCategorieModal(false);
		// setShowUploadModal(true);
	};

	const handleCloseSubCategorieModal = () => {
		setShowSubCategorieModal(false);
		// setShowUploadModal(true);
	};

	const handleCloseLanguageModal = () => {
		setShowLanguageModal(false);
		// setShowUploadModal(true);
	};

	const handleCloseReferenceModal = () => {
		setShowReferenceModal(false);
		// setShowUploadModal(true);
	};

	const handleUploadlanguage = async () => {
		console.log(language);

		try {
			const res = await postDataAPI(
				"createlanguage",
				{
					name: language,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setLanguages((prev) => [...prev, res.data.language]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		// courseCategorieArray.push(item);
		setShowLanguageModal(false);
		// setShowUploadModal(true);
	};

	const handleUploadReference = async () => {
		console.log(reference);

		try {
			const res = await postDataAPI(
				"createreference",
				{
					name: reference,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setReferences((prev) => [...prev, res.data.reference]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		// courseCategorieArray.push(item);
		setShowReferenceModal(false);
		setShowUploadModal(true);
	};

	const handlePushItemIntoCourseCategorieArray = async () => {
		console.log(courseName);

		try {
			const res = await postDataAPI(
				"createcoursecategory",
				{
					name: courseName,
					description: courseDescription,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// setTimeout(() => {
				// 	// window.location.reload();
				// }, 1000);
				setCourseCategorieArray((prev) => [...prev, res.data.category]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		// courseCategorieArray.push(item);
		setShowCategorieModal(false);
		// setShowUploadModal(true);
	};

	const handlePushItemIntoCourseSubCategorieArray = async () => {
		console.log(subCourseName);

		try {
			const res = await postDataAPI(
				"createcoursesubcategory",
				{
					name: subCourseName,
					description: subCourseDescription,
					categoryid: selectedCategory,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// setTimeout(() => {
				// 	// window.location.reload();
				// }, 1000);
				setCourseSubCategorieArray((prev) => [...prev, res.data.subcategory]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		// courseCategorieArray.push(item);
		setShowSubCategorieModal(false);
		// setShowUploadModal(true);
	};
	// console.log(courseCategorieArray);

	// const handleViewChange = (e) => {
	// 	console.log("e", e);
	// 	setDisplayContent(e);
	// 	setShowCourseTab(false);
	// 	setShowCourseOverview(true);
	// };

	// const handleBackToView = () => {
	// 	setShowCourseTab(true);
	// 	setShowCourseOverview(false);
	// 	setDisplayContent("");
	// };

	const handleCourseImage = (event) => {
		// const file = event.target.files[0];
		// console.log(file);
		// if (file) {
		//   const url = URL.createObjectURL(file);
		//   // console.log(url);
		//   setCourseImage(url);
		// }

		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setCourseImage(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handleCourseOverviewFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setCourseOverviewFile(url);
			setCourseOverviewActualFile(file);
		}
	};

	const handleTopicsFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setTopicsFile(url);
			setTopicsActualFile(file);
		}
	};

	const handleOutcomesFile = (event) => {
		const file = event.target.files[0];
		// console.log(file, typeof(file));
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setOutcomesFile(url);
			setOutcomesActualFile(file);
		}
	};

	const handleObtainingFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setObtainingFile(url);
			setObtainingActualFile(file);
		}
	};

	const handleEligibilityFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setEligibilityFile(url);
			setEligibilityActualFile(file);
		}
	};

	const handleProceedFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setProceedFile(url);
			setProceedActualFile(file);
		}
	};

	const handleStepsInvolvedFile = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			const url = URL.createObjectURL(file);
			// console.log(url);

			setStepsInvolvedFile(url);
			// file.name = "stepsinvolvedfile.pdf";

			// var fil = new File([file], "stepsinvolvedfile.pdf");
			setStepsInvolvedActualFile(file);
		}
	};

	// const handleOpenOrganicFile = (file) => {
	// 	const pdfWindow = window.open();
	// 	pdfWindow.location.href = file;
	// };

	const handleOpenCourseStepsInvolvedFile = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("file", file);
		var newfile = {
			file: file,
		};
		// pdfWindow.location.href = file;
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			const pdfWindow = window.open();
			console.log("res my courses 11 ", res.data);

			const newfile1 = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(newfile1);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			pdfWindow.location.href = fileURL;
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			// window.open(fileURL);
		});
	};

	// useEffect(() => {
	// 	postDataAPI(`generatecertificate`, { name: "oakeh" }, auth.token).then(
	// 		(res) => {
	// 			console.log("generate certificate ", res.data);
	// 		}
	// 	);
	// }, []);

	// const handleOpenFinalAsessmentFile = (file) => {
	// 	const pdfWindow = window.open();
	// 	pdfWindow.location.href = file;
	// };

	// const handleOrganicFile = (event) => {
	// 	const file = event.target.files[0];
	// 	console.log(file);
	// 	if (file) {
	// 		const url = URL.createObjectURL(file);
	// 		// console.log(url);
	// 		setOrganicFile(url);
	// 	}
	// };

	// const handleFinalAsessmentFile = (event) => {
	// 	const file = event.target.files[0];
	// 	console.log(file);
	// 	if (file) {
	// 		const url = URL.createObjectURL(file);
	// 		// console.log(url);
	// 		setFinalAsessmentFile(url);
	// 	}
	// };

	useEffect(() => {
		// Cleanup the URL object when the component unmounts or a new file is selected
		return () => {
			if (courseImage) {
				URL.revokeObjectURL(courseImage);
			}
		};
	}, [courseImage]);

	const handlePushItemsIntoArray = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowUploadModal(false);
		console.log("data", data, selectedCategory);
		const formData = new FormData();
		for (let i = 0; i < filesList.length; i++) {
			formData.append("files", filesList[i]);
		}
		for (let i = 0; i < referencefilesList.length; i++) {
			formData.append("files", referencefilesList[i]);
		}
		console.log("stepsInvolvedActualFile", stepsInvolvedActualFile);
		if (stepsInvolvedActualFile !== "") {
			formData.append(
				"files",
				stepsInvolvedActualFile,
				"stepsinvolvedfile.pdf"
			);
		}
		if (courseOverviewActualFile !== "") {
			formData.append("files", courseOverviewActualFile, "courseoverview.pdf");
		}
		if (topicsActualFile !== "") {
			formData.append("files", topicsActualFile, "coursetopics.pdf");
		}
		if (outcomesActualFile !== "") {
			formData.append("files", outcomesActualFile, "courseoutcomes.pdf");
		}
		if (obtainingActualFile !== "") {
			formData.append("files", obtainingActualFile, "courseobtaining.pdf");
		}
		if (eligibilityActualFile !== "") {
			formData.append("files", eligibilityActualFile, "courseeligibility.pdf");
		}
		if (proceedActualFile !== "") {
			formData.append("files", proceedActualFile, "coursehowtoproceed.pdf");
		}
		let item = {
			admin: auth.user.id,
			courseimage: courseImage,
			coursetitle: courseTitle,
			aboutcourse: aboutCourse,
			// instructorinformation: instructorInformation,
			duration: courseDuration,
			validity: courseValidity,
			questionsduration: questionsDuration,
			targetaudience: targetAudience,
			module: modules,
			learningoutcome: learningOutcomes,
			stepsinvolved: stepsInvolved,
			stepsinvolvedfile: stepsInvolvedFile,
			files: textlist,
			fileslist: filesList,
			textlist: textlist,
			referencelist: referencelist,
			questionlist: questionsList,
			retake: retake,
			cost: cost,
			category: selectedCategory,
			subcategory: selectedSubCategory,
			language: selectedLanguage,
			topics,
			obtaining,
			eligibility,
			proceed,
		};
		let item1 = {
			admin: auth.user.id,
			courseimage: courseImage,
			coursetitle: courseTitle,
			aboutcourse: aboutCourse,
			// instructorinformation: instructorInformation,
			duration: courseDuration,
			validity: courseValidity,
			questionsduration: questionsDuration,
			targetaudience: targetAudience,
			modules: modules,
			learningoutcome: learningOutcomes,
			stepsinvolved: stepsInvolved,
			stepsinvolvedfile: stepsInvolvedActualFile,
			files: formData,
			fileslist: filesList,
			textlist: textlist,
			referencelist: referencelist,
			questionlist: questionsList,
			retake: retake,
			cost: cost,
			category: selectedCategory,
			subcategory: selectedSubCategory,
			language: selectedLanguage,
			communityid: window.location.pathname.split("/")[2],
			topics,
			obtaining,
			eligibility,
			proceed,
		};

		for (var key in item1) {
			formData.append(
				key,
				typeof item1[key] === "object" ? JSON.stringify(item1[key]) : item1[key]
			);
		}
		console.log("typeof item1[key]", typeof item1["stepsinvolvedfile"]);
		console.log("item", item1);

		try {
			const res = await postDataAPI("createcourse", formData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		communitiesArray.push(item);
		setQuestionsList([]);
		setTextlist([{ name: "", file: "", section: "" }]);
		setReferencelist([{ title: "", name: "", file: "", link: "", source: "" }]);
		setShowUploadModal(false);
	};
	// console.log(textlist);

	const handleEditSubmit = async () => {
		let newreferencelist = [];
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowEditModal(false);
		console.log("data", data, selectedCategory);
		const formData = new FormData();
		referencelist.forEach((e) => {
			newreferencelist.push({ ...e, title: e.title.id });
		});
		for (let i = 0; i < filesList.length; i++) {
			console.log("filesList", filesList[i]);
			formData.append("files", filesList[i]);
		}
		for (let i = 0; i < referencefilesList.length; i++) {
			formData.append("files", referencefilesList[i]);
		}
		console.log("stepsInvolvedActualFile", stepsInvolvedActualFile);
		if (
			stepsInvolvedActualFile !== "" &&
			typeof stepsInvolvedActualFile === "object"
		) {
			formData.append(
				"files",
				stepsInvolvedActualFile,
				"stepsinvolvedfile.pdf"
			);
		}
		if (
			courseOverviewActualFile !== "" &&
			typeof courseOverviewActualFile === "object"
		) {
			formData.append("files", courseOverviewActualFile, "courseoverview.pdf");
		}
		if (topicsActualFile !== "" && typeof topicsActualFile === "object") {
			formData.append("files", topicsActualFile, "coursetopics.pdf");
		}

		if (outcomesActualFile !== "" && typeof outcomesActualFile === "object") {
			formData.append("files", outcomesActualFile, "courseoutcomes.pdf");
		}
		if (obtainingActualFile !== "" && typeof obtainingActualFile === "object") {
			formData.append("files", obtainingActualFile, "courseobtaining.pdf");
		}
		if (
			eligibilityActualFile !== "" &&
			typeof eligibilityActualFile === "object"
		) {
			formData.append("files", eligibilityActualFile, "courseeligibility.pdf");
		}
		if (proceedActualFile !== "" && typeof proceedActualFile === "object") {
			formData.append("files", proceedActualFile, "coursehowtoproceed.pdf");
		}
		console.log("newreferencelist", newreferencelist);

		let item = {
			courseid: selectedCourse.id,
			admin: auth.user.id,
			courseimage: courseImage,
			coursetitle: courseTitle,
			aboutcourse: aboutCourse,
			// instructorinformation: instructorInformation,
			duration: courseDuration,
			validity: courseValidity,
			questionsduration: questionsDuration,
			targetaudience: targetAudience,
			module: modules,
			learningoutcome: learningOutcomes,
			stepsinvolved: stepsInvolved,
			stepsinvolvedfile: stepsInvolvedFile,
			files: textlist,
			fileslist: filesList,
			textlist: textlist,
			referencelist: newreferencelist,
			questionlist: questionsList,
			retake: retake,
			cost: cost,
			category: selectedCategory,
			subcategory: selectedSubCategory,
			language: selectedLanguage,
			topics,
			obtaining,
			eligibility,
			proceed,
		};
		let item1 = {
			courseid: selectedCourse.id,
			admin: auth.user.id,
			courseimage: courseImage,
			coursetitle: courseTitle,
			aboutcourse: aboutCourse,
			// instructorinformation: instructorInformation,
			duration: courseDuration,
			validity: courseValidity,
			questionsduration: questionsDuration,
			targetaudience: targetAudience,
			modules: modules,
			learningoutcome: learningOutcomes,
			stepsinvolved: stepsInvolved,
			stepsinvolvedfile: stepsInvolvedActualFile,
			files: formData,
			fileslist: filesList,
			textlist: textlist,
			referencelist: referencelist,
			questionlist: questionsList,
			retake: retake,
			cost: cost,
			category: selectedCategory,
			subcategory: selectedSubCategory,
			language: selectedLanguage,
			communityid: window.location.pathname.split("/")[2],
			topics,
			obtaining,
			eligibility,
			proceed,
			reffilecount: referencefilesList.length,
			textfilecount: filesList.length,
		};

		for (var key in item1) {
			formData.append(
				key,
				typeof item1[key] === "object" ? JSON.stringify(item1[key]) : item1[key]
			);
		}
		console.log("typeof item1[key]", typeof item1["stepsinvolvedfile"]);
		console.log("item", item1);

		try {
			const res = await postDataAPI("updatecourse", formData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
		communitiesArray.push(item);
		setQuestionsList([]);
		setTextlist([{ name: "", file: "", section: "" }]);
		setReferencelist([{ title: "", name: "", file: "", link: "", source: "" }]);
		setShowUploadModal(false);
	};

	const handleClick = async (
		file,
		image,
		title,
		question,
		id,
		admin,
		details
	) => {
		// console.log(file, image, title);
		// localStorage.setItem("data", "");
		// console.log(localStorage.getItem("data"));
		if (details === 0) {
			try {
				const res = await postDataAPI(
					"createCourseDetails",
					{
						userid: auth.user.id,
						courseid: id,
						adminid: admin,
					},
					auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					// window.location.reload();
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
		}

		// if (localStorage.getItem("data") === "") {
		// 	let dataToSend = {
		// 		id: id,
		// 		files: file,
		// 		courseimage: image,
		// 		coursetitle: title,
		// 		questionslist: question,
		// 	};
		// 	let temp = { state: dataToSend };
		// 	console.log(dataToSend);
		// 	localStorage.setItem("data", JSON.stringify(dataToSend));
		window.open("/pdfreader/" + id, "_blank");
		// }
	};

	const handleRegisterCourse = async () => {
		setCourseRegisterModel(false);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("join clicked", userData);
		try {
			// const res = await postDataAPI(
			// 	"createCourseRegisters",
			// 	{
			// 		...userData,
			// 		courseid: displayContent.id,
			// 		userid: auth.user.id,
			// 	},
			// 	auth.token
			// );
			// console.log("res", res);
			// if (res.status === 200) {
			if (displayContent.cost === "") {
				try {
					const res = await postDataAPI(
						"createCourseRegisters",
						{
							...userData,
							courseid: displayContent.id,
							userid: auth.user.id,
						},
						auth.token
					);
					console.log("res", res);
					if (res.status === 200) {
						const res1 = await postDataAPI(
							"createusersaved",
							{
								userid: auth.user.id,
								courseid: displayContent.id,
							},
							auth.token
						);
						console.log("res", res);
						if (res1.status === 200) {
							// window.location.reload();
							window.location.href = "../mycources";
						}
					}
				} catch (err) {
					dispatch({
						type: GLOBALTYPES.ALERT,
						payload: { error: err.response.data.msg },
					});
				}
			} else {
				const orderResponse = await postDataAPI(
					"createrazorpaypayment",
					{
						amount: Number(displayContent.cost),
						currency: "INR",
						receipt: "receipt#1",
						// notes: { key1: "value1", key2: "value2" },
					},
					auth.token
				);

				const { id: order_id, amount, currency } = orderResponse.data;
				console.log("orderResponse.data", orderResponse.data);

				const options = {
					key: "rzp_test_ZrlDFJqqD5ZYwg",
					amount,
					currency,
					name: "Foodsafety Academy",
					description: "Test Transaction",
					order_id,
					handler: async function (response) {
						const {
							razorpay_payment_id,
							razorpay_order_id,
							razorpay_signature,
						} = response;

						// const verificationResponse = await axios.post(verifyUrl, {
						// 	order_id: razorpay_order_id,
						// 	payment_id: razorpay_payment_id,
						// 	signature: razorpay_signature,
						// });
						const verificationResponse = await postDataAPI(
							"verifypayment",
							{
								order_id: razorpay_order_id,
								payment_id: razorpay_payment_id,
								signature: razorpay_signature,
							},
							auth.token
						);

						if (verificationResponse.data.status === "success") {
							console.log("Payment successful!!!");

							try {
								const res = await postDataAPI(
									"createpayment",
									{
										userid: auth.user.id,
										orderid: razorpay_order_id,
										paymentid: razorpay_payment_id,
										items: displayContent.id,
										amount: Number(displayContent.cost),
										items_community: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
									},
									auth.token
								);
								console.log("res", res);
								if (res.status === 200) {
									// window.location.reload();
									try {
										const res = await postDataAPI(
											"createCourseRegisters",
											{
												...userData,
												courseid: displayContent.id,
												userid: auth.user.id,
											},
											auth.token
										);
										console.log("res", res);
										if (res.status === 200) {
											const res1 = await postDataAPI(
												"createusersaved",
												{
													userid: auth.user.id,
													courseid: displayContent.id,
												},
												auth.token
											);
											console.log("res", res);
											if (res1.status === 200) {
												// window.location.reload();
												window.location.href = "../mycources";
											}
										}
									} catch (err) {
										dispatch({
											type: GLOBALTYPES.ALERT,
											payload: { error: err.response.data.msg },
										});
									}
								}
							} catch (err) {
								dispatch({
									type: GLOBALTYPES.ALERT,
									payload: { error: err.response.data.msg },
								});
							}
						} else {
							alert("Payment verification failed");
						}
					},
					prefill: {
						name: auth.user.name,
						email: auth.user.email,
						contact: "9999999999",
					},
					notes: {
						address: "Some Address",
					},
					theme: {
						color: "#0db154",
					},
				};
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
				const rzp = new window.Razorpay(options);
				rzp.open();
			}
			// }
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	return (
		<>
			{/* <Header /> */}
			<Navbar />

			<Modal
				show={courseRegisterModel}
				onHide={() => setCourseRegisterModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
									marginBottom: "0px",
									display: "flex",
								}}>
								Register for this Course
								{/* {selectedCommunity.name} &nbsp; */}
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={() => setCourseRegisterModel(false)}>
							<img src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />

					<div style={{ padding: "15px 45px 10px" }}>
						<label for="name" style={{ paddingBottom: "5px" }}>
							Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="username"
							value={userData.username}
							className="form-control"
							placeholder="Enter Name"></input>
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Profile Headline*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="headline"
							value={userData.headline}
							className="form-control"
							placeholder="Enter Profile Headline"></input> */}
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Location*
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="location"
							value={userData.location}
							placeholder="Enter Location"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Email*
						</label>
						<input
							type="email"
							onChange={handleChangeInput}
							name="email"
							value={userData.email}
							className="form-control"
							placeholder="Enter Email"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Mobile Number*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="mobile"
							// style={{ paddingTop: "10px", paddingBottom: "5px" }}
							value={userData.mobile}
							className="form-control"
							placeholder="Enter Mobile Number"></input>
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Role*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.role}
							name="role"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="Member">Member</option>
							<option value="Guest">Alumni/Guest</option>
						</select> */}
					</div>
					<div
						className="row"
						style={{
							padding: "15px 15px 15px 15px",
							display: "flex",
							alignItems: "center",
							background: "#F1F1F1",
							//   marginLeft: "0px",
							//   marginRight:"0px"
						}}>
						<div className="col-7"></div>
						<div className="col-5">
							<span
								className="col-12"
								onClick={handleRegisterCourse}
								style={{
									//   border: "1px solid #ddd",
									borderRadius: "5px",
									padding: "4px 15px 6px 20px",
									//   width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									background: "#E23F1C",
									cursor: "pointer",
								}}>
								Register and Pay
							</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={loginModel}
				onHide={() => setLoginModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						{/* <div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Organisation
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setLoginModel(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ padding: "15px 0", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Please Login to Register for this Course!!!</div>

					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={organisationName === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoFreeOrganisationArray();
							}}>
							Save
						</Button>*/}
						<LoginRequest />
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={showCategorieModal}
				onHide={handleCloseCategorieModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Course Categorie
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseCategorieModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Course Categorie Name:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Course Categorie name"
								onChange={(e) => setCourseName(e.target.value)}></input>
						</div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Course Categorie Description:
							</div>
							<textarea
								type="text"
								className="form-control"
								placeholder="Enter Course Categorie Description"
								onChange={(e) =>
									setCourseDescription(e.target.value)
								}></textarea>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={courseName === "" || courseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoCourseCategorieArray();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showSubCategorieModal}
				onHide={handleCloseSubCategorieModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Course Sub Categorie
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseSubCategorieModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Course Categorie:</div>
							<select
								name="category"
								defaultValue={selectedCategory}
								onChange={(e) => setSelectedCategory(e.target.value)}>
								<option value="">Select</option>
								{courseCategorieArray.map((e) => {
									return (
										<option value={e.id} key={e.id}>
											{e.name}
										</option>
									);
								})}
							</select>
							{/* <span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span> */}
						</div>
						<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Course Sub Categorie Name:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Course Sub Categorie name"
								onChange={(e) => setSubCourseName(e.target.value)}></input>
						</div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Course Sub Categorie Description:
							</div>
							<textarea
								type="text"
								className="form-control"
								placeholder="Enter Course Sub Categorie Description"
								onChange={(e) =>
									setSubCourseDescription(e.target.value)
								}></textarea>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={subCourseName === "" || subCourseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoCourseSubCategorieArray();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showLanguageModal}
				onHide={handleCloseLanguageModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Language
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseLanguageModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Language:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Language"
								onChange={(e) => setLanguage(e.target.value)}></input>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={language === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleUploadlanguage();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showReferenceModal}
				onHide={handleCloseReferenceModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Reference Title
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseReferenceModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>Reference Title:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Reference Title"
								onChange={(e) => setReference(e.target.value)}></input>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={reference === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleUploadReference();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showUploadModal}
				onHide={handleCloseUploadModal}
				dialogClassName="modal-sm-30px"
				size="xl"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Upload Course
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer", textAlign: "right" }}
							onClick={handleCloseUploadModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div className="row">
						<div className="col-7">
							<div>
								<div style={{ paddingTop: "10px", fontSize: "13px" }}>
									Welcome and Introduction
								</div>
								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Categorie:</div>
									<select
										name="category"
										onChange={(e) => setSelectedCategory(e.target.value)}>
										<option value="">Select</option>
										{courseCategorieArray.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>

								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Sub Categorie:</div>
									<select
										name="subcategory"
										onChange={(e) => setSelectedSubCategory(e.target.value)}>
										<option value="">Select</option>
										{courseSubCategorieArray.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowSubCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>

								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Language:</div>
									<select
										name="language"
										onChange={(e) => setSelectedLanguage(e.target.value)}>
										<option value="">Select</option>
										{languages.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowLanguageModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Image:</div>
									<input
										type="file"
										className="form-control"
										accept="image/*"
										onChange={(e) => handleCourseImage(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course title:</div>
									<input
										type="text"
										className="form-control"
										placeholder="Enter Course Title name"
										onChange={(e) => setCourseTitle(e.target.value)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Overview:</div>
									<textarea
										className="form-control"
										placeholder="Enter Course Overview"
										rows="3"
										onChange={(e) => setAboutCourse(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleCourseOverviewFile(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Core Topics:</div>
									<textarea
										className="form-control"
										placeholder="Enter Core Topics"
										rows="3"
										onChange={(e) => setTopics(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleTopicsFile(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Target Audience:</div>
									<input
										type="text"
										className="form-control"
										placeholder="Enter Target Audience"
										onChange={(e) => setTargetAudience(e.target.value)}></input>
								</div>
							</div>

							<div>
								<div style={{ paddingTop: "20px", fontSize: "13px" }}>
									Curriculum Outline
								</div>

								<div style={{ paddingTop: "0px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Modules:</div>
									<textarea
										className="form-control"
										placeholder="Enter Modules"
										onChange={(e) => setModules(e.target.value)}></textarea>
								</div>
								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Course Duration in Hours:
									</div>
									<input
										className="form-control"
										placeholder="Enter Course Duration"
										type="text"
										onChange={(e) => setCourseDuration(e.target.value)}></input>
								</div>

								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Course validity in Days:
									</div>
									<input
										className="form-control"
										placeholder="Enter Course validity"
										type="text"
										onChange={(e) => setCourseValidity(e.target.value)}></input>
								</div>

								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Each Question duration in Mins:
									</div>
									<input
										className="form-control"
										placeholder="Enter each question duration"
										type="text"
										onChange={(e) =>
											setQuestionsDuration(e.target.value)
										}></input>
								</div>

								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Learning Outcomes:</div>
									<textarea
										className="form-control"
										placeholder="Enter Learning Outcomes"
										onChange={(e) =>
											setLearningOutcomes(e.target.value)
										}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleOutcomesFile(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										Steps in obtaining the certification:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter Steps in obtaining the certification"
										onChange={(e) => setObtaining(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleObtainingFile(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										Eligibility to take the course:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter Eligibility to take the course"
										onChange={(e) => setEligibility(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleEligibilityFile(e)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										How to proceed with this course:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter How to proceed with this course"
										onChange={(e) => setProceed(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleProceedFile(e)}></input>
								</div>
							</div>

							{/* <div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Instructor Information:</div>
								<input
									className="form-control"
									placeholder="Enter About Instructor"
									type="text"
									onChange={(e) =>
										setInstructorInformation(e.target.value)
									}></input>
							</div> */}
							{/* <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
            <div style={{ fontSize: "13px" }}>Syllabus:</div>
            <div>
              <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                Course Material:
              </div>
              <input
                type="file"
                className="form-control"
                onChange={(e) => handleCourseOverviewFile(e)}
              ></input>
            </div>
          </div> */}
							{/* <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                <div style={{ fontSize: "13px" }}>Course Material:</div>
                <div style={{ paddingLeft: "10px" }}>
                  <div style={{ fontSize: "13px", paddingTop: "0px" }}>
                    Course Overview:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleCourseOverviewFile(e)}
                  ></input>
                  <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                    Course Material:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleOrganicFile(e)}
                  ></input>
                  <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                    Final Assessment:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFinalAsessmentFile(e)}
                  ></input>
                </div>
              </div> */}

							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Course Modules:</div>
							</div>

							<div className="row">
								<div className="col-4" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Course Header</label>
								</div>
								<div className="col-4" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Course File</label>
								</div>
								<div className="col-3" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Course Section</label>
								</div>
							</div>
							<div
								style={{
									// height: "200px",
									overflowY: "auto",
									overflowX: "hidden",
								}}>
								{textlist.map((singletext, index) => (
									<div key={index}>
										<div className="row">
											<div className="col-4" style={{ paddingBottom: "10px" }}>
												<input
													type="text"
													name="name"
													className="form-control"
													placeholder="Enter Course Header here"
													value={singletext.name}
													// style={{
													//   border: "1px solid #ACACAC",
													//   borderRadius: "10px",
													// }}

													onChange={(e) =>
														handlertextrchange(e, index)
													}></input>
											</div>
											<div className="col-4" style={{ paddingBottom: "10px" }}>
												<input
													name="file"
													type="file"
													accept="application/pdf"
													className="form-control"
													onChange={(e) => handleFile(e, index)}></input>
												{singletext.file !== undefined &&
													singletext.file !== "" &&
													!singletext.file.includes("blob") &&
													singletext.file.split("/")[2]}
											</div>
											<div className="col-3" style={{ paddingBottom: "10px" }}>
												<input
													type="text"
													name="section"
													className="form-control"
													placeholder="Enter Section"
													// style={{
													//   border: "1px solid #ACACAC",
													//   borderRadius: "10px",
													// }}
													value={singletext.section}
													onChange={(e) =>
														handlertextrchange(e, index)
													}></input>
											</div>
											<div className="col-1">
												{textlist.length > 1 && (
													<img
														alt="icon"
														style={{ cursor: "pointer" }}
														onClick={() => handlertextremove(index)}
														src="../images/closeOne.png"></img>
												)}
											</div>
										</div>
									</div>
								))}
							</div>
							<div
								className="row"
								style={{ paddingTop: "20px", paddingBottom: "30px" }}>
								<div className="col">
									<div>
										<div>
											{textlist.length >= 1 && (
												<>
													<span
														onClick={handleTextadd}
														style={{ cursor: "pointer", marginRight: "20px" }}>
														<img
															alt="icon"
															style={{
																width: "18px",
																height: "18px",
																background: "#20ce8f",
																borderRadius: "20px",
															}}
															src="../images/Icon.png"></img>
														&nbsp;
														<span
															className="f-fm fm-w6-s16"
															style={{
																color: "#20ce8f",
																verticalAlign: "middle",
															}}>
															Add New File
														</span>
													</span>
													<AddExistingFiles
														courses={communities}
														showPdf={(file) =>
															handleOpenCourseStepsInvolvedFile(file)
														}
														addFiles={(item) =>
															setTextlist([...textlist, ...item])
														}
													/>
												</>
											)}
											<br />
											<span
												// onClick={handleTextadd}
												style={{
													display:
														getButtonClass() === "aebuttonblack"
															? "none"
															: "block",
												}}
												// disabledClass={getButtonClass()}
											></span>
										</div>
									</div>
								</div>
							</div>
							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Course References:</div>
							</div>

							<div className="row">
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Title</label>
								</div>
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Name</label>
								</div>
							</div>
							<div
								style={{
									// height: "200px",
									overflowY: "auto",
									overflowX: "hidden",
								}}>
								{referencelist.map((singletext, index) => (
									<div
										key={index}
										className="row"
										style={{ marginTop: "20px" }}>
										<div className="col-5" style={{ paddingLeft: "15px" }}>
											{/* <div style={{ fontSize: "13px" }}>Language:</div> */}
											<select
												name="title"
												onChange={(e) => handlereferencechange(e, index)}>
												<option value="">Select</option>
												{references.map((e) => {
													return (
														<option value={e.id} key={e.id}>
															{e.name}
														</option>
													);
												})}
											</select>
											<span style={{ marginLeft: "30px" }}>
												<Button
													onClick={() => {
														setShowReferenceModal(true);
														// setShowUploadModal(false);
													}}
													style={{ background: "#20ce8f", border: "none" }}>
													Add
												</Button>
											</span>
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="name"
												type="text"
												className="form-control"
												placeholder="Enter reference name"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}

												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="row">
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>File</label>
											</div>
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Link</label>
											</div>
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="file"
												type="file"
												className="form-control"
												accept="application/pdf"
												onChange={(e) => handleReferenceFile(e, index)}></input>
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="link"
												type="text"
												className="form-control"
												placeholder="Enter Links"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}

												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="row">
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Source</label>
											</div>
											{/* <div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Link</label>
											</div> */}
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="source"
												type="text"
												className="form-control"
												placeholder="Enter Source"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}

												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="col-2">
											{referencelist.length > 1 && (
												<img
													alt="icon"
													style={{ cursor: "pointer" }}
													onClick={() => handlereferenceremove(index)}
													src="../images/closeOne.png"></img>
											)}
										</div>
									</div>
								))}
							</div>
							<div
								className="row"
								style={{ paddingTop: "20px", paddingBottom: "30px" }}>
								<div className="col">
									<div>
										<div>
											{referencelist.length >= 1 && (
												<span
													onClick={handleReferenceadd}
													style={{ cursor: "pointer" }}>
													<img
														alt="icon"
														style={{
															width: "18px",
															height: "18px",
															background: "#20ce8f",
															borderRadius: "20px",
														}}
														src="../images/Icon.png"></img>
													&nbsp;
													<span
														className="f-fm fm-w6-s16"
														style={{
															color: "#20ce8f",
															verticalAlign: "middle",
														}}>
														Add New Reference
													</span>
												</span>
											)}
											<br />
											<span
												// onClick={handleTextadd}
												style={{
													display:
														getButtonClass() === "aebuttonblack"
															? "none"
															: "block",
												}}
												// disabledClass={getButtonClass()}
											></span>
										</div>
									</div>
								</div>
							</div>

							{/* <div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Steps Involved:</div>
								<textarea
									className="form-control"
									placeholder="Enter Steps Involved"
									onChange={(e) => setStepsInvolved(e.target.value)}></textarea>
								<div style={{ textAlign: "center" }}>OR</div>
								<input
									type="file"
									accept="application/pdf"
									className="form-control"
									onChange={(e) => handleStepsInvolvedFile(e)}></input>
							</div> */}
							<div style={{ paddingTop: "15px" }}>
								<input
									type="checkbox"
									checked={retake}
									style={{ cursor: "pointer" }}
									onChange={(e) =>
										setRetake((retake) => setRetake(!retake))
									}></input>
								&nbsp;Retake Exam
							</div>
							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Cost of Course:</div>

								<input
									type="text"
									className="form-control"
									value={cost}
									placeholder="Enter course cost"
									onChange={(e) => setCost(e.target.value)}></input>
							</div>
							<div style={{ fontSize: "13px", fontWeight: 500 }}>
								(Note: In case of free course, leave the cost field empty!!!)
							</div>
						</div>

						<div className="col-5">
							<div style={{ paddingTop: "10px", fontSize: "13px" }}>
								Dynamic Questions and Answers:
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Question"
									value={question}
									onChange={(e) => setQuestion(e.target.value)}></input>
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<textarea
									className="form-control"
									placeholder="Enter options seperated by comma"
									value={answers}
									onChange={(e) => setAnswers(e.target.value)}></textarea>
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Answer"
									value={questionAnswer}
									onChange={(e) => setQuestionAnswer(e.target.value)}></input>
							</div>

							<div style={{ textAlign: "center" }}>
								<Button
									onClick={() => handleShowQuestion()}
									disabled={addbutton}
									style={{
										background: "#20ce8f",
										border: "none",
										width: "60px",
										borderRadius: "5px",
									}}>
									Add
								</Button>
							</div>

							{questionsList.map((questionData, index) => (
								// <ModalQuestion
								// 	key={index}
								// 	question={questionData.question}
								// 	options={questionData.options}
								// 	selectedOption={selectedOptions[index]}
								// 	handleOptionChange={(selectedOption) =>
								// 		handleOptionChange(index, selectedOption)
								// 	}

								// />
								<div
									style={{
										border: "1px solid #000",
										backgroundColor: "lightgray",
										borderRadius: "10px",
										margin: "10px",
										padding: "10px",
									}}>
									<div className="d-flex justify-content-between">
										<h3>{questionData.question}</h3>
										{/* {questionsList.length > 1 && ( */}
										<img
											alt="icon"
											style={{ cursor: "pointer", objectFit: "contain" }}
											onClick={() => handlerquestionremove(index)}
											src="../images/closeOne.png"></img>
										{/* )} */}
									</div>
									{questionData.options.map((option, index) => (
										<>
											<label key={index}>
												&nbsp;
												<input
													type="radio"
													value={option}
													checked={selectedOptions[index] === option}
													onChange={() => handleOptionChange(option)}
												/>
												&nbsp; {option}
											</label>
										</>
									))}
									{/* <div> */}

									{/* </div> */}
								</div>
							))}
						</div>
					</div>

					<div
						style={{
							textAlign: "center",
							paddingBottom: "10px",
							paddingTop: "20px",
						}}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemsIntoArray();
							}}>
							Upload
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showEditModal}
				onHide={handleCloseEditModal}
				dialogClassName="modal-sm-30px"
				size="xl"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Edit Course
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer", textAlign: "right" }}
							onClick={handleCloseEditModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div className="row">
						<div className="col-7">
							<div>
								<div style={{ paddingTop: "10px", fontSize: "13px" }}>
									Welcome and Introduction
								</div>
								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Categorie:</div>
									<select
										value={selectedCategory}
										name="category"
										onChange={(e) => setSelectedCategory(e.target.value)}>
										<option value="">Select</option>
										{courseCategorieArray.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Sub Categorie:</div>
									<select
										name="subcategory"
										value={selectedSubCategory}
										onChange={(e) => setSelectedSubCategory(e.target.value)}>
										<option value="">Select</option>
										{courseSubCategorieArray.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowSubCategorieModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div style={{ paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Language:</div>
									<select
										name="language"
										value={selectedLanguage}
										onChange={(e) => setSelectedLanguage(e.target.value)}>
										<option value="">Select</option>
										{languages.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												setShowLanguageModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Image:</div>
									<input
										type="file"
										className="form-control"
										accept="image/*"
										// value={courseImage}
										onChange={(e) => handleCourseImage(e)}></input>
									{courseImage !== "" ? (
										<img
											alt="course"
											style={{ marginTop: "5px" }}
											onError={(e) => {
												e.target.src = "../images/common-couse-image.png";
											}}
											width="150px"
											src={courseImage}
										/>
									) : (
										<img
											alt="course"
											style={{ marginTop: "5px" }}
											onError={(e) => {
												e.target.src = "../images/common-couse-image.png";
											}}
											width="150px"
											src="../images/common-couse-image.png"
										/>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course title:</div>
									<input
										type="text"
										className="form-control"
										value={courseTitle}
										placeholder="Enter Course Title name"
										onChange={(e) => setCourseTitle(e.target.value)}></input>
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Course Overview:</div>
									<textarea
										className="form-control"
										placeholder="Enter Course Overview"
										rows="3"
										value={aboutCourse}
										onChange={(e) => setAboutCourse(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										onChange={(e) => handleCourseOverviewFile(e)}></input>
									{courseOverviewFile === selectedCourse.overviewfile && (
										<>{courseOverviewFile.split("/")[2]}</>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Core Topics:</div>
									<textarea
										className="form-control"
										placeholder="Enter Core Topics"
										rows="3"
										value={topics}
										onChange={(e) => setTopics(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										// value={topicsActualFile}
										onChange={(e) => handleTopicsFile(e)}></input>

									{topicsFile === selectedCourse.topicsfile && (
										<>{topicsFile.split("/")[2]}</>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Target Audience:</div>
									<input
										type="text"
										className="form-control"
										placeholder="Enter Target Audience"
										value={targetAudience}
										onChange={(e) => setTargetAudience(e.target.value)}></input>
								</div>
							</div>

							<div>
								<div style={{ paddingTop: "20px", fontSize: "13px" }}>
									Curriculum Outline
								</div>

								<div style={{ paddingTop: "0px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Modules:</div>
									<textarea
										className="form-control"
										placeholder="Enter Modules"
										value={modules}
										onChange={(e) => setModules(e.target.value)}></textarea>
								</div>
								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Course Duration in Hours:
									</div>
									<input
										className="form-control"
										placeholder="Enter Course Duration"
										type="text"
										value={courseDuration}
										onChange={(e) => setCourseDuration(e.target.value)}></input>
								</div>

								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Course validity in Days:
									</div>
									<input
										className="form-control"
										placeholder="Enter Course validity"
										value={courseValidity}
										type="text"
										onChange={(e) => setCourseValidity(e.target.value)}></input>
								</div>

								<div
									style={{
										paddingTop: "15px",
										paddingBottom: "10px",
										paddingLeft: "10px",
									}}>
									<div style={{ fontSize: "13px" }}>
										Each Question duration in Mins:
									</div>
									<input
										className="form-control"
										value={questionsDuration}
										placeholder="Enter each question duration"
										type="text"
										onChange={(e) =>
											setQuestionsDuration(e.target.value)
										}></input>
								</div>

								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>Learning Outcomes:</div>
									<textarea
										className="form-control"
										placeholder="Enter Learning Outcomes"
										value={learningOutcomes}
										onChange={(e) =>
											setLearningOutcomes(e.target.value)
										}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										// value={outcomesActualFile}
										onChange={(e) => handleOutcomesFile(e)}></input>
									{outcomesFile === selectedCourse.outcomesfile && (
										<>{outcomesFile.split("/")[2]}</>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										Steps in obtaining the certification:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter Steps in obtaining the certification"
										value={obtaining}
										onChange={(e) => setObtaining(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										// value={obtainingActualFile}
										onChange={(e) => handleObtainingFile(e)}></input>
									{obtainingFile === selectedCourse.obtainingfile && (
										<>{obtainingFile.split("/")[2]}</>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										Eligibility to take the course:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter Eligibility to take the course"
										value={eligibility}
										onChange={(e) => setEligibility(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										// value={eligibilityActualFile}
										onChange={(e) => handleEligibilityFile(e)}></input>
									{eligibilityFile === selectedCourse.eligibilityfile && (
										<>{eligibilityFile.split("/")[2]}</>
									)}
								</div>
								<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
									<div style={{ fontSize: "13px" }}>
										How to proceed with this course:
									</div>
									<textarea
										className="form-control"
										placeholder="Enter How to proceed with this course"
										value={proceed}
										onChange={(e) => setProceed(e.target.value)}></textarea>
									<div style={{ textAlign: "center" }}>OR</div>
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										// value={proceedActualFile}
										onChange={(e) => handleProceedFile(e)}></input>
									{proceedFile === selectedCourse.howtoproceedfile && (
										<>{proceedFile.split("/")[2]}</>
									)}
								</div>
							</div>

							{/* <div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Instructor Information:</div>
								<input
									className="form-control"
									placeholder="Enter About Instructor"
									type="text"
									value={instructorInformation}
									onChange={(e) =>
										setInstructorInformation(e.target.value)
									}></input>
							</div> */}
							{/* <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
            <div style={{ fontSize: "13px" }}>Syllabus:</div>
            <div>
              <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                Course Material:
              </div>
              <input
                type="file"
                className="form-control"
                onChange={(e) => handleCourseOverviewFile(e)}
              ></input>
            </div>
          </div> */}
							{/* <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                <div style={{ fontSize: "13px" }}>Course Material:</div>
                <div style={{ paddingLeft: "10px" }}>
                  <div style={{ fontSize: "13px", paddingTop: "0px" }}>
                    Course Overview:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleCourseOverviewFile(e)}
                  ></input>
                  <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                    Course Material:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleOrganicFile(e)}
                  ></input>
                  <div style={{ fontSize: "13px", paddingTop: "10px" }}>
                    Final Assessment:
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFinalAsessmentFile(e)}
                  ></input>
                </div>
              </div> */}

							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Course Modules:</div>
							</div>

							<div className="row">
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Course Header</label>
								</div>
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Course File</label>
								</div>
							</div>
							<div
								style={{
									// height: "200px",
									overflowY: "auto",
									overflowX: "hidden",
								}}>
								{textlist.map((singletext, index) => (
									<div key={index}>
										<div className="row">
											<div className="col-4" style={{ paddingBottom: "10px" }}>
												<input
													type="text"
													name="name"
													className="form-control"
													placeholder="Enter Course Header here"
													value={singletext.name}
													// style={{
													//   border: "1px solid #ACACAC",
													//   borderRadius: "10px",
													// }}
													onChange={(e) =>
														handlertextrchange(e, index)
													}></input>
											</div>
											<div className="col-4" style={{ paddingBottom: "10px" }}>
												<input
													name="file"
													type="file"
													// value={singletext.file}
													accept="application/pdf"
													className="form-control"
													onChange={(e) => handleFile(e, index)}></input>
												{singletext.file !== undefined &&
													singletext.file !== "" &&
													!singletext.file.includes("blob") &&
													singletext.file.split("/")[2]}
											</div>
											<div className="col-3" style={{ paddingBottom: "10px" }}>
												<input
													type="text"
													name="section"
													className="form-control"
													placeholder="Enter Section"
													// style={{
													//   border: "1px solid #ACACAC",
													//   borderRadius: "10px",
													// }}
													value={singletext.section}
													onChange={(e) =>
														handlertextrchange(e, index)
													}></input>
											</div>
											<div className="col-1">
												{textlist.length > 1 && (
													<img
														alt="icon"
														style={{ cursor: "pointer" }}
														onClick={() => handlertextremove(index)}
														src="../images/closeOne.png"></img>
												)}
											</div>
										</div>
									</div>
								))}
							</div>
							<div
								className="row"
								style={{ paddingTop: "20px", paddingBottom: "30px" }}>
								<div className="col">
									<div>
										<div>
											{textlist.length >= 1 && (
												<>
													<span
														onClick={handleTextadd}
														style={{ cursor: "pointer", marginRight: "20px" }}>
														<img
															alt="icon"
															style={{
																width: "18px",
																height: "18px",
																background: "#20ce8f",
																borderRadius: "20px",
															}}
															src="../images/Icon.png"></img>
														&nbsp;
														<span
															className="f-fm fm-w6-s16"
															style={{
																color: "#20ce8f",
																verticalAlign: "middle",
															}}>
															Add New File
														</span>
													</span>
													<AddExistingFiles
														courses={communities}
														showPdf={(file) =>
															handleOpenCourseStepsInvolvedFile(file)
														}
														addFiles={(item) =>
															setTextlist([...textlist, ...item])
														}
													/>
												</>
											)}
											<br />
											<span
												// onClick={handleTextadd}
												style={{
													display:
														getButtonClass() === "aebuttonblack"
															? "none"
															: "block",
												}}
												// disabledClass={getButtonClass()}
											></span>
										</div>
									</div>
								</div>
							</div>
							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Course References:</div>
							</div>

							<div className="row">
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Title</label>
								</div>
								<div className="col-5" style={{ paddingBottom: "10px" }}>
									<label style={{ fontSize: "13px" }}>Name</label>
								</div>
							</div>
							<div
								style={{
									// height: "200px",
									overflowY: "auto",
									overflowX: "hidden",
								}}>
								{referencelist.map((singletext, index) => (
									<div
										key={index}
										className="row"
										style={{ marginTop: "20px" }}>
										<div className="col-5" style={{ paddingLeft: "15px" }}>
											{/* <div style={{ fontSize: "13px" }}>Language:</div> */}
											<select
												name="title"
												defaultValue={singletext.title?.id}
												// value={singletext.title}
												onChange={(e) => handlereferencechange(e, index)}>
												<option value="">Select</option>
												{references.map((e) => {
													return (
														<option value={e.id} key={e.id}>
															{e.name}
														</option>
													);
												})}
											</select>
											<span style={{ marginLeft: "30px" }}>
												<Button
													onClick={() => {
														setShowReferenceModal(true);
														// setShowUploadModal(false);
													}}
													style={{ background: "#20ce8f", border: "none" }}>
													Add
												</Button>
											</span>
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="name"
												type="text"
												className="form-control"
												placeholder="Enter reference name"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}
												value={singletext.name}
												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="row">
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>File</label>
											</div>
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Link</label>
											</div>
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="file"
												type="file"
												className="form-control"
												accept="application/pdf"
												// value={singletext.file}
												onChange={(e) => handleReferenceFile(e, index)}></input>
											{singletext.file !== "" &&
												singletext.file !== undefined &&
												!singletext.file.includes("blob") &&
												singletext.file.split("/")[2]}
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="link"
												type="text"
												className="form-control"
												placeholder="Enter Links"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}
												value={singletext.link}
												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="row">
											<div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Source</label>
											</div>
											{/* <div className="col-5" style={{ paddingBottom: "10px" }}>
												<label style={{ fontSize: "13px" }}>Link</label>
											</div> */}
										</div>
										<div className="col-5" style={{ paddingBottom: "10px" }}>
											<input
												name="source"
												type="text"
												className="form-control"
												placeholder="Enter Source"
												// style={{
												//   border: "1px solid #ACACAC",
												//   borderRadius: "10px",
												// }}
												value={singletext.source}
												onChange={(e) =>
													handlereferencechange(e, index)
												}></input>
										</div>
										<div className="col-2">
											{referencelist.length > 1 && (
												<img
													alt="icon"
													style={{ cursor: "pointer" }}
													onClick={() => handlereferenceremove(index)}
													src="../images/closeOne.png"></img>
											)}
										</div>
									</div>
								))}
							</div>
							<div
								className="row"
								style={{ paddingTop: "20px", paddingBottom: "30px" }}>
								<div className="col">
									<div>
										<div>
											{referencelist.length >= 1 && (
												<span
													onClick={handleReferenceadd}
													style={{ cursor: "pointer" }}>
													<img
														alt="icon"
														style={{
															width: "18px",
															height: "18px",
															background: "#20ce8f",
															borderRadius: "20px",
														}}
														src="../images/Icon.png"></img>
													&nbsp;
													<span
														className="f-fm fm-w6-s16"
														style={{
															color: "#20ce8f",
															verticalAlign: "middle",
														}}>
														Add New Reference
													</span>
												</span>
											)}
											<br />
											<span
												// onClick={handleTextadd}
												style={{
													display:
														getButtonClass() === "aebuttonblack"
															? "none"
															: "block",
												}}
												// disabledClass={getButtonClass()}
											></span>
										</div>
									</div>
								</div>
							</div>

							{/* <div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Steps Involved:</div>
								<textarea
									className="form-control"
									placeholder="Enter Steps Involved"
									onChange={(e) => setStepsInvolved(e.target.value)}></textarea>
								<div style={{ textAlign: "center" }}>OR</div>
								<input
									type="file"
									accept="application/pdf"
									className="form-control"
									onChange={(e) => handleStepsInvolvedFile(e)}></input>
							</div> */}
							<div style={{ paddingTop: "15px" }}>
								<input
									type="checkbox"
									style={{ cursor: "pointer" }}
									checked={retake}
									onChange={(e) => setRetake(e.target.checked)}></input>
								&nbsp;Retake Exam
							</div>
							<div style={{ paddingTop: "15px" }}>
								<div style={{ fontSize: "13px" }}>Cost of Course:</div>

								<input
									type="text"
									className="form-control"
									value={cost}
									placeholder="Enter course cost"
									onChange={(e) => setCost(e.target.value)}></input>
							</div>
							<div style={{ fontSize: "13px", fontWeight: 500 }}>
								(Note: In case of free course, leave the cost field empty!!!)
							</div>
						</div>
						<div className="col-5">
							<div style={{ paddingTop: "10px", fontSize: "13px" }}>
								Dynamic Questions and Answers:
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Question"
									value={question}
									onChange={(e) => setQuestion(e.target.value)}></input>
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<textarea
									className="form-control"
									placeholder="Enter options seperated by comma"
									value={answers}
									onChange={(e) => setAnswers(e.target.value)}></textarea>
							</div>
							<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Answer"
									value={questionAnswer}
									onChange={(e) => setQuestionAnswer(e.target.value)}></input>
							</div>

							<div style={{ textAlign: "center" }}>
								<Button
									onClick={() => handleShowQuestion()}
									disabled={addbutton}
									style={{
										background: "#20ce8f",
										border: "none",
										width: "60px",
										borderRadius: "5px",
									}}>
									Add
								</Button>
							</div>

							{/* {questionsList.map((questionData, index) => (
								<ModalQuestion
									key={index}
									question={questionData.question}
									options={questionData.options}
									selectedOption={selectedOptions[index]}
									handleOptionChange={(selectedOption) =>
										handleOptionChange(index, selectedOption)
									}
								/>
							))} */}
							{questionsList.map((questionData, index) => (
								// <ModalQuestion
								// 	key={index}
								// 	question={questionData.question}
								// 	options={questionData.options}
								// 	selectedOption={selectedOptions[index]}
								// 	handleOptionChange={(selectedOption) =>
								// 		handleOptionChange(index, selectedOption)
								// 	}

								// />
								<div
									key={questionData.question}
									style={{
										border: "1px solid #000",
										backgroundColor: "lightgray",
										borderRadius: "10px",
										margin: "10px",
										padding: "10px",
									}}>
									<div className="d-flex justify-content-between">
										<h3>{questionData.question}</h3>
										{/* {questionsList.length > 1 && ( */}
										<img
											alt="icon"
											style={{ cursor: "pointer", objectFit: "contain" }}
											onClick={() => handlerquestionremove(index)}
											src="../images/closeOne.png"></img>
										{/* )} */}
									</div>
									{questionData.options.map((option, index) => (
										<>
											<label key={index}>
												&nbsp;
												<input
													type="radio"
													value={option}
													checked={selectedOptions[index] === option}
													onChange={() => handleOptionChange(option)}
												/>
												&nbsp; {option}
											</label>
										</>
									))}
									{/* <div> */}

									{/* </div> */}
								</div>
							))}
						</div>
					</div>

					<div
						style={{
							textAlign: "center",
							paddingBottom: "10px",
							paddingTop: "20px",
						}}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								// handlePushItemsIntoArray();
								handleEditSubmit();
							}}>
							Edit course
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<div style={{ padding: "20px 60px 20px 120px", marginTop: "28px" }}>
				<div style={{ display: showCourseTab ? "block" : "none" }}>
					<div
						className="row"
						style={{ marginLeft: "5px", display: "contents" }}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => (window.location.href = "/foodsafetyacademy")}>
							Dashboard
						</span>{" "}
						&nbsp;&nbsp;&nbsp;/&nbsp;
						<span style={{ fontWeight: "500" }}>Courses</span>
					</div>
					<Tabs
						defaultActiveKey="home"
						activeKey={activeTab}
						onSelect={(k) => setActiveTab(k)}
						id="uncontrolled-tab-example"
						// className="mb-3"
						style={{
							backgroundColor: "#fff",
							borderRadius: "15px",
							height: "55px",
							marginTop: "20px",
						}}>
						<Tab eventKey="home" title="Courses">
							<div className="">
								<div className="row pt-3 justify-content-center">
									<div
										className="col-8 card"
										style={{
											marginRight: "75px",
											border: "none",
											borderRadiusTop: "15px",
											marginLeft: "10px",
											borderRadius: "15px",
										}}>
										<div
											className="row"
											style={{
												backgroundColor: "#F4F7F9",
												padding: "15px 15px",
												marginLeft: "-12px",
												marginRight: "-12px",
												fontWeight: "600",
												fontSize: "16px",
												borderTopLeftRadius: "15px",
												borderTopRightRadius: "15px",
											}}>
											<div
												className="col-3"
												style={{ display: "flex", alignItems: "center" }}>
												{/* Courses */}
											</div>
											<div className="col-5">
												{userRole === "Admin" && (
													<>
														<Addfreecourse
															languages={languages}
															showLanguageModel={() =>
																setShowLanguageModal(true)
															}
														/>

														<Button
															style={{
																background: "#36BB7D",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															onClick={() => setShowUploadModal(true)}>
															+ Add New Course
														</Button>
													</>
												)}
											</div>
											<div
												className="col-1"
												style={{
													width: "auto",
													paddingRight: "0px",
													paddingLeft: "0px",
												}}>
												{/* <button
													className="ripple-button uc-extra-options-button"
													id="uc-add-clsJRS"
													style={{
														background: "#fff",
														width: "35px",
														height: "35px",
														borderRadius: "4px",
														border: "1px solid #ddd",
													}}>
													<img
														className="uc-extra-options-img"
														src="../images/sort_icon.svg"
														alt=""
													/>
												</button> */}
											</div>
											<div className="col-3 uc-searchBar">
												<input
													type="text"
													placeholder="Search"
													onChange={(e) => setSearchValue(e.target.value)}
													className="uc-formControl1"></input>
											</div>
										</div>
										<div>
											<div className="row card-container">
												<>
													{organisationArray.length > 0 && (
														<Accordion
															style={{
																marginTop: "10px",
																marginBottom: "10px",
															}}>
															<Accordion.Item eventKey={"free"}>
																<Accordion.Header
																	style={{
																		borderBottom: "1px solid #ddd",
																	}}>
																	<p
																		style={{
																			fontWeight: 900,
																			color: "#36BB7D",
																		}}>
																		Free Courses
																	</p>
																</Accordion.Header>

																<Accordion.Body
																	style={{
																		padding: "15px",
																		// borderTop: "1px solid #ddd",
																		// display: "flex",
																	}}>
																	{organisationArray.map((c) => {
																		return (
																			<>
																				<Accordion
																					style={{
																						marginTop: "10px",
																						marginBottom: "10px",
																					}}>
																					<Accordion.Item eventKey={c}>
																						<Accordion.Header
																							style={{
																								borderBottom: "1px solid #ddd",
																							}}>
																							<p
																								style={{
																									fontWeight: 900,
																									color: "#36BB7D",
																								}}>
																								{c}
																							</p>
																						</Accordion.Header>

																						<Accordion.Body
																							className="row"
																							style={{
																								padding: "15px",
																								// borderTop: "1px solid #ddd",
																								// display: "flex",
																							}}>
																							{freeCourses.map((e) => {
																								if (e.organisation.name === c) {
																									return (
																										<div
																											className="col-4 card card1"
																											style={{
																												padding: "5px",
																												margin: "5px",
																												// cursor: "pointer",
																												// visibility: showCard ? "visible" : "hidden",
																											}}>
																											<div
																												// className="card"
																												// onClick={(item) =>
																												// 	handleViewChange(e)
																												// }
																												style={{
																													// cursor: "pointer",
																													height:
																														"-webkit-fill-available",
																												}}>
																												<div className="uc-comm-like-imgs">
																													<img
																														// onerror="communityBannerImgError(this);"
																														src="../images/community-common-background.png"
																														alt="img"
																													/>
																												</div>
																												<div className="uc-comm-userimg">
																													{" "}
																													<img
																														src="../images/common-couse-image.png"
																														alt=""
																														onError={(e) => {
																															e.target.src =
																																"../images/common-couse-image.png";
																														}}
																														className="uc-comm-user-img"
																														// onClick="communityChangeNew(5421)"
																													/>{" "}
																												</div>
																												<div className="uc-right-top-wrap">
																													<a
																														href="javascript:void(0)"
																														className="uc-rb-btn"
																														//   onClick="openReportCommunityPopup(5421,this)"
																													>
																														<span
																															className="uc-rc-pop-span"
																															style={{
																																position:
																																	"absolute",
																															}}>
																															{/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community */}
																														</span>
																													</a>
																												</div>
																												<div
																													style={{
																														fontSize: "15px",
																														fontWeight: "500",
																														minHeight: "36px",
																														paddingTop: "40px",
																													}}>
																													{e.name}
																												</div>
																												<div
																													style={{
																														fontSize: "12px",
																													}}>
																													Language &nbsp;&nbsp;
																													<span>
																														{e.language.name}
																													</span>
																												</div>
																												<div
																													style={{
																														fontSize: "12px",
																													}}>
																													Subject &nbsp;&nbsp;
																													<span>
																														{e.subject}
																													</span>
																												</div>
																												<div
																													style={{
																														fontSize: "12px",
																													}}>
																													Source Link
																													&nbsp;&nbsp;
																													<span>
																														<a
																															href={
																																(e.sourcelink?.includes(
																																	"https://"
																																)
																																	? ""
																																	: "http://") +
																																e.sourcelink
																															}
																															rel="no-referer noreferrer"
																															target="_blank">
																															{e.sourcelink}
																														</a>
																													</span>{" "}
																												</div>
																												{userRole ===
																													"Admin" && (
																													<FreeCoursesAction
																														freecourse={e}
																														languages={
																															languages
																														}
																														showLanguageModel={() =>
																															setShowLanguageModal(
																																true
																															)
																														}
																													/>
																												)}
																											</div>
																										</div>
																									);
																								}
																							})}
																						</Accordion.Body>
																					</Accordion.Item>
																				</Accordion>
																			</>
																		);
																	})}
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													)}
												</>
												{categoriesArray.map((c) => {
													return (
														<>
															{courseCategorieArray.map((cc) => {
																if (c === cc.name) {
																	return (
																		<>
																			<Accordion
																				style={{
																					marginTop: "10px",
																					marginBottom: "10px",
																				}}>
																				<Accordion.Item eventKey={c}>
																					<Accordion.Header
																						style={{
																							borderBottom: "1px solid #ddd",
																						}}>
																						<p
																							style={{
																								fontWeight: 900,
																								color: "#36BB7D",
																							}}>
																							{c}
																						</p>
																					</Accordion.Header>

																					<Accordion.Body
																						style={{
																							padding: "15px",
																							// borderTop: "1px solid #ddd",
																							// display: "flex",
																						}}>
																						{cc.subcategories.map((s) => {
																							if (
																								JSON.stringify(
																									communitiesArray
																								).includes(s.id)
																							) {
																								return (
																									<Accordion
																										style={{
																											marginTop: "10px",
																											marginBottom: "10px",
																										}}>
																										<Accordion.Item
																											eventKey={s.name}>
																											<Accordion.Header
																												style={{
																													borderBottom:
																														"1px solid #ddd",
																												}}>
																												<p
																													style={
																														{
																															// fontWeight: 900,
																															// color: "#36BB7D",
																														}
																													}>
																													{s.name}
																												</p>
																											</Accordion.Header>

																											<Accordion.Body
																												className="row col-12"
																												style={{
																													padding: "0",
																													margin:
																														"5px 10px 0 0",
																													// borderTop:
																													// 	"1px solid #ddd",
																													display: "flex",
																												}}>
																												{communitiesArray.map(
																													(e, index) => {
																														if (
																															e.subcategory
																																.id === s.id
																														) {
																															return (
																																<div
																																	class="col-4 mb-2 d-flex"
																																	style={{
																																		padding:
																																			"0 5px",
																																	}}>
																																	<div
																																		className="card1 card"
																																		style={{
																																			padding:
																																				"5px",
																																			// margin: "5px",
																																			// cursor: "pointer",
																																			// visibility: showCard ? "visible" : "hidden",
																																		}}>
																																		<div
																																			// className="card "
																																			onClick={() =>
																																				// handleViewChange(
																																				// 	e
																																				// )
																																				(window.location.href =
																																					"../coursedetails/" +
																																					e.id)
																																			}
																																			style={{
																																				cursor:
																																					"pointer",
																																				height:
																																					"-webkit-fill-available",
																																			}}>
																																			<div className="uc-comm-like-imgs">
																																				<img
																																					// onerror="communityBannerImgError(this);"
																																					src="../images/community-common-background.png"
																																					alt="img"
																																				/>
																																			</div>
																																			<div className="uc-comm-userimg">
																																				{" "}
																																				<img
																																					src={
																																						e.courseimage
																																					}
																																					alt=""
																																					onError={(
																																						e
																																					) => {
																																						e.target.src =
																																							"../images/common-couse-image.png";
																																					}}
																																					className="uc-comm-user-img"
																																					// onClick="communityChangeNew(5421)"
																																				/>{" "}
																																			</div>
																																			<div className="uc-right-top-wrap">
																																				{/* <a
                                        href="javascript:void(0)"
                                        className="uc-option-btn"
                                      >
                                        {" "}
                                        <img
                                          src="https://www.ulektz.com/assets/img/home/options.svg"
                                          alt="icon"
                                        />{" "}
                                      </a>{" "} */}
																																				<a
																																					href="javascript:void(0)"
																																					className="uc-rb-btn"
																																					//   onClick="openReportCommunityPopup(5421,this)"
																																				>
																																					<span
																																						className="uc-rc-pop-span"
																																						style={{
																																							position:
																																								"absolute",
																																						}}>
																																						{/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community */}
																																					</span>
																																				</a>{" "}
																																			</div>{" "}
																																			{/* <div
                                    //   className="uc-comm-like-imgs"
                                      style={{
                                        width: "100%",
                                        height: "60px",
                                        objectFit: "cover",
                                        backgroundImage:
                                          "url(./images/community-common-background.png)",
                                      }}
                                    >
                                      <img
                                        // onerror="communityBannerImgError(this);"
                                        src={e.courseimage}
                                        alt="img"
                                        className="uc-comm-user-img"
                                      />
                                    </div> */}
																																			{/* <img
                                      src={e.courseimage}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "cover",
                                        backgroundImage:
                                          "url(./images/community-common-background.png)",
                                      }}
                                    ></img> */}
																																			<div
																																				style={{
																																					fontSize:
																																						"15px",
																																					fontWeight:
																																						"500",
																																					minHeight:
																																						"36px",
																																					paddingTop:
																																						"40px",
																																				}}>
																																				{
																																					e.coursetitle
																																				}
																																			</div>
																																			{/* <div
																																			style={{
																																				fontSize:
																																					"12px",
																																			}}>
																																			Instructor&nbsp;&nbsp;
																																			<span
																																				style={{
																																					textDecoration:
																																						"underline",
																																				}}>
																																				{
																																					e.instructorinformation
																																				}
																																			</span>{" "}
																																		</div> */}
																																			<div
																																				style={{
																																					fontSize:
																																						"12px",
																																				}}>
																																				Duration&nbsp;&nbsp;
																																				<span
																																					style={{
																																						textDecoration:
																																							"underline",
																																					}}>
																																					{
																																						e.duration
																																					}{" "}
																																					Hrs
																																				</span>{" "}
																																			</div>
																																			{e
																																				.language
																																				.name !==
																																				"English" && (
																																				<div
																																					style={{
																																						fontSize:
																																							"12px",
																																					}}>
																																					Language&nbsp;&nbsp;
																																					<span
																																						style={{
																																							textDecoration:
																																								"underline",
																																						}}>
																																						{
																																							e
																																								.language
																																								.name
																																						}
																																					</span>{" "}
																																				</div>
																																			)}
																																			{/* <div style={{ fontSize: "12px" }}>
																			Certification By
																		</div>
																		<div
																			style={{
																				fontSize: "12px",
																				textDecoration: "underline",
																			}}>
																			Krishigap
																		</div> */}
																																			<div
																																				style={{
																																					fontSize:
																																						"15px",
																																					fontWeight:
																																						"500",
																																					paddingTop:
																																						"13px",
																																					paddingBottom:
																																						"13px",
																																				}}>
																																				{e.cost ===
																																				""
																																					? "Free"
																																					: "₹" +
																																					  e.cost}
																																			</div>
																																		</div>

																																		{/* {index === 0 && userRole === "Admin" && (
																		<Button
																			style={{
																				background: "#36BB7D",
																				fontSize: "13px",
																				borderRadius: "4px",
																				border: "none",
																			}}
																			onClick={() => setShowUploadModal(true)}>
																			+ Add New Course
																		</Button>
																	)} */}
																																		{userRole ===
																																			"Admin" && (
																																			<>
																																				<Button
																																					style={{
																																						background:
																																							"#36BB7D",
																																						fontSize:
																																							"13px",
																																						borderRadius:
																																							"4px",
																																						border:
																																							"none",
																																						marginBottom:
																																							"5px",
																																					}}
																																					onClick={(
																																						item
																																					) =>
																																						setActiveTab(
																																							"List"
																																						)
																																					}>
																																					<img
																																						alt="my courses"
																																						src="../images/Go-to-My-Courses-icon.svg"></img>{" "}
																																					Go to
																																					My
																																					Courses
																																				</Button>
																																				<div className="col-12 d-flex justify-content-around">
																																					<Button
																																						style={{
																																							// background:
																																							// 	"#E23F1C",
																																							fontSize:
																																								"13px",
																																							borderRadius:
																																								"4px",
																																							border:
																																								"none",
																																						}}
																																						className="btn btn-warning"
																																						onClick={() =>
																																							handleEditCourse(
																																								e
																																							)
																																						}>
																																						<i class="fas fa-edit"></i>
																																						Edit
																																						course
																																					</Button>
																																					<Button
																																						style={{
																																							background:
																																								"#E23F1C",
																																							fontSize:
																																								"13px",
																																							borderRadius:
																																								"4px",
																																							border:
																																								"none",
																																						}}
																																						onClick={() =>
																																							handleSetCourseInactive(
																																								e
																																							)
																																						}>
																																						<i
																																							class="fa fa-trash"
																																							aria-hidden="true"></i>{" "}
																																						Delete
																																						course
																																					</Button>
																																				</div>
																																			</>
																																		)}
																																	</div>
																																</div>
																															);
																														}
																													}
																												)}
																											</Accordion.Body>
																										</Accordion.Item>
																									</Accordion>
																								);
																							}
																						})}
																					</Accordion.Body>
																				</Accordion.Item>
																			</Accordion>
																		</>
																	);
																}
															})}
														</>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="List" title="My Courses">
							<div className="">
								<div className="row pt-3  justify-content-center">
									<div
										className="col-8 card"
										style={{
											marginRight: "75px",
											border: "none",
											borderRadiusTop: "15px",
											marginLeft: "10px",
											borderRadius: "15px",
										}}>
										<div
											className="row"
											style={{
												backgroundColor: "#F4F7F9",
												padding: "15px 15px",
												marginLeft: "-12px",
												marginRight: "-12px",
												fontWeight: "600",
												fontSize: "16px",
												borderTopLeftRadius: "15px",
												borderTopRightRadius: "15px",
											}}>
											<div
												className="col-8"
												style={{ display: "flex", alignItems: "center" }}>
												{/* My Courses */}
											</div>
											<div
												className="col-1"
												style={{
													width: "auto",
													paddingRight: "0px",
													paddingLeft: "0px",
												}}></div>
											<div className="col-3 uc-searchBar">
												<input
													type="text"
													placeholder="Search"
													onChange={(e) => setSearchValue(e.target.value)}
													className="uc-formControl1"></input>
											</div>
										</div>
										<div>
											<div className="row">
												{communitiesArray.map((e, index) => {
													return (
														<>
															{(userRole === "Admin" ||
																(userRole !== "Admin" &&
																	e.isCourseSaved === true)) && (
																<div
																	className="col-12"
																	style={{
																		padding: "10px 10px 10px 10px",
																	}}>
																	<div
																		className="card"
																		style={{
																			padding: "5px 5px 5px 5px",
																			// visibility: showCard ? "visible" : "hidden",
																		}}>
																		<div className="row">
																			<div
																				className="col-3"
																				style={{ cursor: "pointer" }}
																				onClick={() =>
																					(window.location.href =
																						"../coursedetails/" + e.id)
																				}>
																				<img
																					alt=""
																					onError={(e) => {
																						e.target.src =
																							"../images/common-couse-image.png";
																					}}
																					src={e.courseimage}
																					style={{ width: "100%" }}></img>
																			</div>
																			<div className="col-6">
																				{" "}
																				<div
																					style={{
																						fontSize: "15px",
																						fontWeight: "500",
																						minHeight: "36px",
																					}}>
																					{e.coursetitle}
																				</div>
																				{e.language.name !== "English" && (
																					<div style={{ fontSize: "12px" }}>
																						Language&nbsp;&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																							}}>
																							{e.language.name}
																						</span>{" "}
																					</div>
																				)}
																				<div style={{ fontSize: "12px" }}>
																					Duration&nbsp;&nbsp;
																					<span>{e.duration} Hrs</span>
																				</div>
																				<div
																					style={{
																						fontSize: "15px",
																						fontWeight: "500",
																						paddingTop: "13px",
																						paddingBottom: "13px",
																					}}>
																					{e.cost === ""
																						? "Free"
																						: "₹" + e.cost}
																				</div>
																			</div>
																			<div className="col-3">
																				<br />
																				<br />
																				<br />
																				{e.courses_details > 0 && (
																					<div
																						className="lightgreen"
																						style={{
																							textAlign: "center",
																						}}>
																						In Progress
																					</div>
																				)}

																				<div
																					style={{
																						paddingTop: "10px",
																						textAlign: "center",
																					}}>
																					<Button
																						style={{
																							background: "#F96342",
																							border: "none",
																							width: "150px",
																						}}
																						onClick={() => {
																							handleClick(
																								e.files,
																								e.courseimage,
																								e.coursetitle,
																								e.questionlist,
																								e.id,
																								e.admin,
																								e.courses_details
																							);
																						}}>
																						{e.courses_details > 0
																							? "Resume"
																							: "Start"}
																					</Button>
																					{/* </Link> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															)}
														</>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export default Courses;
