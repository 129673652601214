import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import LoginRequest from "./loginRequest";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";

const Myorders = () => {
	const [itemsArray, setItemsArray] = useState([1, 2, 3, 4, 5]);
	const { theme, auth } = useSelector((state) => state);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<div style={{ padding: "20px 60px 20px 120px" }}>
						<div
							className="row"
							style={{
								backgroundColor: "#F4F7F9",
								padding: "15px 15px",
								marginLeft: "-12px",
								marginRight: "-12px",
								fontWeight: "600",
								fontSize: "16px",
								borderTopLeftRadius: "15px",
								borderTopRightRadius: "15px",
							}}>
							<div
								className="col-9"
								style={{ display: "flex", alignItems: "center" }}>
								My Orders 4
							</div>

							<div className="col-3">
								<input
									type="text"
									placeholder="Search"
									style={{
										padding: "4px 4px 4px 20px",
										border: "1px solid #ddd",
										borderRadius: "4px",
										backgroundImage: "url(./images/search.svg)",
										backgroundRepeat: "no-repeat",
										backgroundSize: "18px",
										backgroundPosition: "left",
										width: "-webkit-fill-available",
									}}></input>
							</div>
						</div>

						<div className="card row">
							<div style={{ paddingTop: "10px" }}>
								<span style={{ fontSize: "12px" }}> 3 Orders Placed in</span>
								<span style={{ paddingLeft: "5px" }}>
									<select>
										<option>All time</option>
										<option>1 week</option>
										<option>1 month</option>
									</select>
								</span>
							</div>
							<div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
								<Accordion>
									<Accordion.Item eventKey="0">
										<Accordion.Header
											style={{
												marginBottom: "10px",
												borderBottom: "1px solid #ddd",
											}}>
											TXN_ORD_1714392547
											<span style={{ fontSize: "12px", textAlign: "end" }}>
												(Total : 18 items) Date:2024-09-29 17:45:07
											</span>
										</Accordion.Header>
										<Accordion.Body
											style={{ padding: "5px", borderTop: "1px solid #ddd" }}>
											{itemsArray.map((element, index) => {
												return (
													<>
														<div className="" style={{ marginTop: "10px" }}>
															<div className="row" style={{ padding: "10px" }}>
																{/* <div className="col-1" style={{width:"auto"}}>
                              <span>{index + 1}.</span>
                            </div> */}
																<div
																	className="col-3"
																	style={{ paddingRight: "0px" }}>
																	<img
																		src="./images/marketing.png"
																		style={{
																			height: "98px",
																			width: "100%",
																		}}></img>
																</div>
																<div className="col-7">
																	<div
																		style={{
																			fontSize: "16px",
																			fontWeight: "500",
																		}}>
																		Digital Marketing
																	</div>
																	<div style={{ fontSize: "13px" }}>
																		Publisher:uLektz Academy
																	</div>
																	<br />
																	<br />
																</div>
																<div className="col-2">
																	<div
																		style={{
																			textAlign: "end",
																		}}>
																		<span
																			style={{
																				fontSize: "16px",
																				color: "red",
																				fontSize: "19px",
																				fontWeight: "500",
																			}}>
																			₹1499
																		</span>
																		<span
																			style={{
																				fontSize: "11px",
																				textDecoration: "line-through",
																			}}>
																			₹1270
																		</span>
																	</div>
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "400",
																			textAlign: "end",
																		}}>
																		GST/Taxes ₹228.66
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header
											style={{
												marginBottom: "10px",
												borderBottom: "1px solid #ddd",
											}}>
											{" "}
											TXN_ORD_1714392547
											<span style={{ fontSize: "12px" }}>
												(Total : 18 items) Date:2024-09-29 17:45:07
											</span>
										</Accordion.Header>
										<Accordion.Body
											style={{ padding: "5px", borderTop: "1px solid #ddd" }}>
											{itemsArray.map(() => {
												return (
													<>
														<div className="" style={{ marginTop: "10px" }}>
															<div className="row" style={{ padding: "10px" }}>
																<div
																	className="col-3"
																	style={{ paddingRight: "0px" }}>
																	<img
																		src="./images/marketing.png"
																		style={{
																			height: "98px",
																			width: "100%",
																		}}></img>
																</div>
																<div className="col-7">
																	<div
																		style={{
																			fontSize: "16px",
																			fontWeight: "500",
																		}}>
																		Digital Marketing
																	</div>
																	<div style={{ fontSize: "13px" }}>
																		Publisher:uLektz Academy
																	</div>
																	<br />
																	<br />
																</div>
																<div
																	className="col-2"
																	style={{
																		textAlign: "end",
																	}}>
																	<div
																		style={{
																			textAlign: "end",
																		}}>
																		<span
																			style={{
																				fontSize: "16px",
																				color: "red",
																				fontSize: "19px",
																				fontWeight: "500",
																			}}>
																			₹1499
																		</span>
																		<span
																			style={{
																				fontSize: "11px",
																				textDecoration: "line-through",
																			}}>
																			₹1270
																		</span>
																	</div>
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "400",
																		}}>
																		GST/Taxes
																		<span style={{ fontSize: "12px" }}>
																			₹228.66
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header
										//   style={{
										//     marginBottom: "10px",
										//     borderBottom: "1px solid #ddd",
										//   }}
										>
											{" "}
											TXN_ORD_1714392547
											<span style={{ fontSize: "12px" }}>
												(Total : 18 items) Date:2024-09-29 17:45:07
											</span>
										</Accordion.Header>
										<Accordion.Body
											style={{ padding: "5px", borderTop: "1px solid #ddd" }}>
											{itemsArray.map(() => {
												return (
													<>
														<div className="" style={{ marginTop: "10px" }}>
															<div className="row" style={{ padding: "10px" }}>
																<div
																	className="col-3"
																	style={{ paddingRight: "0px" }}>
																	<img
																		src="./images/marketing.png"
																		style={{
																			height: "98px",
																			width: "100%",
																		}}></img>
																</div>
																<div className="col-7">
																	<div
																		style={{
																			fontSize: "16px",
																			fontWeight: "500",
																		}}>
																		Digital Marketing
																	</div>
																	<div style={{ fontSize: "13px" }}>
																		Publisher:uLektz Academy
																	</div>
																	<br />
																	<br />
																</div>
																<div
																	className="col-2"
																	style={{
																		textAlign: "end",
																	}}>
																	<div
																		style={{
																			textAlign: "end",
																		}}>
																		<span
																			style={{
																				fontSize: "16px",
																				color: "red",
																				fontSize: "19px",
																				fontWeight: "500",
																			}}>
																			₹1499
																		</span>
																		<span
																			style={{
																				fontSize: "11px",
																				textDecoration: "line-through",
																			}}>
																			₹1270
																		</span>
																	</div>
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "400",
																		}}>
																		GST/Taxes
																		<span style={{ fontSize: "12px" }}>
																			₹228.66
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Myorders;
