// src/Question.js
import React from "react";

const ModalQuestion = ({
	question,
	options,
	selectedOption,
	handleOptionChange,
}) => {
	return (
		<div>
			<h3>{question}</h3>
			{options.map((option, index) => (
				<>
					<label key={index}>
						&nbsp;
						<input
							type="radio"
							value={option}
							checked={selectedOption === option}
							onChange={() => handleOptionChange(option)}
						/>
						&nbsp; {option}
					</label>
					<div className="col-2">
						{options.length > 1 && (
							<img
								alt="icon"
								style={{ cursor: "pointer" }}
								// onClick={() => handlertextremove(index)}
								src="../images/closeOne.png"></img>
						)}
					</div>
				</>
			))}
		</div>
	);
};

export default ModalQuestion;
