import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const companytraction = () => {
	return (
		<>
			<Navbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<h1 className="" style={{ textAlign: "center" }}>
					Company Traction
				</h1>

				<h3 className="">Company formation</h3>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							Krishigap Digital Solutions Pvt Ltd , established in May 2023, to
							date, we have digitally empowered over 200 government agricultural
							extension officials and more than 50 women Agripreneurs. We have
							supported Farmers Producer Organizations in the successful
							implementation of India Good Agricultural Practices , launched by
							the Quality Council of India, aligned with the international ISO
							17065 standard.
						</p>
					</div>
				</div>

				<h3 className="">BITS Pilani and Microsoft Startups Founders Hub</h3>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							We are proud to announce that we have been selected as an
							evaluated startup under the prestigious{" "}
							<b>Conquest Initiative 2024,</b>a partnership between{" "}
							<b>BITS Pilani</b>
							and <b>Microsoft Startups Founders Hub.</b> This recognition opens
							up transformative opportunities for our platform, Global Academy
							on Food Safety and Sustainability Standards, by integrating
							cutting-edge AI solutions.
						</p>
						<p class="mb-4">
							The Microsoft for Startups Founders Hub accelerates innovation by
							providing selected startups with access to industry-leading AI
							services, expert guidance, and essential technology to build
							future-ready solutions. As part of this program, with no cost ,we
							gain critical resources to build and scale our platform in the
							cloud, helping us fast-track our journey toward product-market
							fit.
						</p>
						<h5>Program Benefits at No Cost:</h5>
						<ul style={{ paddingLeft: "50px" }} className="mx-5">
							<li style={{ listStyle: "disc" }}>
								Access up to US$150000 in Azure Credits
							</li>
							<li style={{ listStyle: "disc" }}>
								Drive limitless innovation with AI
							</li>
							<li style={{ listStyle: "disc" }}>
								Access to technical advisory and consulting
							</li>
							<li style={{ listStyle: "disc" }}>
								Free access to essential development tools
							</li>
							<li style={{ listStyle: "disc" }}>
								Leverage an all-in-one platform designed for SaaS startups
							</li>
							<li style={{ listStyle: "disc" }}>
								Boost customer acquisition through an exclusive engagement
								program
							</li>
							<li style={{ listStyle: "disc" }}>
								Go-to-market support with Microsoft
							</li>
							<li style={{ listStyle: "disc" }}>
								Strengthen and grow our tech team
							</li>
						</ul>
					</div>
				</div>

				<h3 className="">MOU with Montgomery County</h3>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							KrishiGAP has signed a Memorandum of Understanding (MOU) with
							Montgomery County, Maryland, USA, to leverage the Montgomery
							County International Soft Landing and Economic Development
							Program. This collaboration aims to facilitate KrishiGAP’ s
							expansion efforts in the United States, offering strategic support
							and resources for successful market entry and growth
						</p>
					</div>
				</div>

				<h3 className="">
					CABI{" "}
					<a
						href="https://www.cabi.org"
						target="blank"
						style={{ textDecoration: "none" }}>
						{" "}
						(www.cabi.org)
					</a>{" "}
					a not-for-profit international organization
				</h3>
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							We are excited to collaborate with <b>CABI</b> to offer{" "}
							<b>CABI's digital learning courses</b> free of charge through the{" "}
							<b>KrishiGAP</b> platform. These courses include{" "}
							<b>Crop Pest Diagnosis</b> and <b>Crop Pest Management Tools,</b>{" "}
							providing essential knowledge to enhance agricultural practices.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default companytraction;
