// StartTest.js
import React from "react";
import Button from "react-bootstrap/esm/Button";

const StartTest = ({ onStart }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Button onClick={onStart}>Start Test</Button>
    </div>
  );
};

export default StartTest;
