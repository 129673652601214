import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";
import "./communitystyles.css";

const Communityevents = () => {
	const dispatch = useDispatch();
	const { profile, auth } = useSelector((state) => state);
	const [userRole, setUserRole] = useState("");
	const [communityid, setCommunityid] = useState("");
	const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
	const [completedEventsArray, setCompletedEventsArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		setCommunityid(window.location.pathname.split("/")[2]);
		if (auth.user) {
			getDataAPI(
				`getAllEvents/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res get events", res.data);
				// setCommunitiesArray(res.data.community);
				setUpcomingEventsArray(res.data.upcomingevents);
				setCompletedEventsArray(res.data.completedevents);
				// setEbookCategoryArray(res.data.categories);
			});
			getDataAPI(
				`getcommunityuser/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);
			});
		} else {
			getDataAPI(
				`getAllEventsWithoutLogin/${window.location.pathname.split("/")[2]}`,
				auth.token
			).then((res) => {
				console.log("res get events", res.data);
				// setCommunitiesArray(res.data.community);
				setUpcomingEventsArray(res.data.upcomingevents);
				setCompletedEventsArray(res.data.completedevents);
				// setEbookCategoryArray(res.data.categories);
			});
			setUserRole("user");
		}
	}, [auth]);

	const addtomyeventsclick = async (eventid) => {
		if (auth.user === undefined) {
			alert("please login to add this event");
			return;
		}
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		console.log("bookid", eventid);
		try {
			const res = await postDataAPI(
				"createusersaved",
				{
					userid: auth.user.id,
					eventid: eventid,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				// window.location.reload();
				window.location.href = "../myevents";
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#3CCE8F",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
					}}>
					Manage Events
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		window.location.href = "../communityeventslist/" + communityid;
	};
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<div style={{ padding: "20px 60px 20px 120px" }}>
				<div className="row" style={{ marginLeft: "5px", display: "contents" }}>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => (window.location.href = "../foodsafetyacademy")}>
						Dashboard
					</span>{" "}
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span style={{ fontWeight: "500" }}>Events</span>
				</div>
				<div className="">
					<div className="row pt-3 justify-content-center">
						<div
							className="col-9 card"
							style={{
								marginRight: "75px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-7"
									style={{ display: "flex", alignItems: "center" }}>
									Events
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-3 uc-searchBar">
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							<div className="row " style={{ padding: "15px" }}>
								<Tabs
									defaultActiveKey="Invitations sent to Connections"
									id="uncontrolled-tab-example"
									style={{
										backgroundColor: "#F4F7F9",
										borderRadius: "5px",
										borderBottom: "none",
									}}
									className="eventtabs">
									<Tab
										eventKey="Invitations sent to Connections"
										title="Upcoming Events">
										{" "}
										<div className="pt-3">
											<div className="row" style={{}}>
												{upcomingEventsArray.length === 0 ? (
													<div
														className="col-12 card"
														style={{
															marginRight: "75px",
															border: "none",
															borderRadius: "15px",
														}}>
														<div>
															<div className="uc-discover-ele-div">
																<div
																	className=""
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	<img
																		alt="myevents"
																		src="../images/MyEvents-Empty.svg"
																		height="200px"
																	/>
																</div>
																<div className="uc-discover-ele-cnt-div">
																	<h3
																		style={{
																			fontSize: "28px",
																			paddingTop: "15px",
																			fontWeight: "300",
																		}}>
																		No Upcoming Event
																	</h3>
																</div>
																<div>
																	Try shortening or rephrasing your search
																</div>
															</div>
														</div>
													</div>
												) : (
													upcomingEventsArray.map((e) => {
														return (
															<div key={e.id} style={{ padding: "5px" }}>
																<div
																	className="card"
																	style={{ padding: "5px" }}>
																	<div className="row">
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			<img
																				alt="book"
																				src={e.banner}
																				onError={(e) => {
																					e.target.src =
																						"../images/common-background.png";
																				}}
																				style={{ width: "100%" }}></img>
																		</div>
																		<div className="col-6">
																			<div>
																				<span className="ebook">Title:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.title}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Organised by:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.organisedby}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Start time:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.startdate} {e.starttime}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">End time:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.enddate} {e.endtime}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Mode:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.mode}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Type:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.eventtype}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Description:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.description}
																				</span>
																			</div>
																		</div>
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			{e.isEventSaved === true && (
																				<Button
																					onClick={() => {
																						window.location.href =
																							"../myevents";
																					}}
																					style={{
																						backgroundColor: "#3CCE8F",
																						border: "none",
																						borderRadius: "4px",
																						marginTop: "-6px",
																						color: "#fff",
																					}}>
																					Go to my events
																				</Button>
																			)}
																			{e.isEventSaved === false && (
																				<div className="row">
																					{" "}
																					<Button
																						onClick={() => {
																							addtomyeventsclick(e.id);
																						}}
																						style={{
																							backgroundColor: "#E23F1C",
																							border: "none",
																							borderRadius: "4px",
																							marginTop: "-6px",
																							color: "#fff",
																						}}>
																						Add to my Events
																					</Button>
																				</div>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														);
													})
												)}
											</div>
										</div>
									</Tab>
									<Tab eventKey="Join Request Sent" title="Past Events">
										{" "}
										<div className="pt-3">
											<div className="row" style={{}}>
												{/*  */}
												{completedEventsArray.length === 0 ? (
													<div
														className="col-12 card"
														style={{
															marginRight: "75px",
															// marginLeft: "10px",
															border: "none",
															borderRadius: "15px",
														}}>
														<div>
															<div className="uc-discover-ele-div">
																<div
																	className=""
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	<img
																		alt="my events"
																		src="../images/MyEvents-Empty.svg"
																		height="200px"
																	/>
																</div>
																<div className="uc-discover-ele-cnt-div">
																	<h3
																		style={{
																			fontSize: "28px",
																			paddingTop: "15px",
																			fontWeight: "300",
																		}}>
																		No Past Event
																	</h3>
																</div>
																<div>
																	Try shortening or rephrasing your search
																</div>
															</div>
														</div>
													</div>
												) : (
													completedEventsArray.map((e) => {
														return (
															<div key={e.id} style={{ padding: "5px" }}>
																<div
																	className="card"
																	style={{ padding: "5px" }}>
																	<div className="row">
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			<img
																				alt="book"
																				src={e.banner}
																				onError={(e) => {
																					e.target.src =
																						"../images/common-background.png";
																				}}
																				style={{ width: "100%" }}></img>
																		</div>
																		<div className="col-6">
																			<div>
																				<span className="ebook">Title:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.title}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Organised by:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.organisedby}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Start time:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.startdate} {e.starttime}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">End time:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.enddate} {e.endtime}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Mode:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.mode}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">Type:</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.eventtype}
																				</span>
																			</div>
																			<div>
																				<span className="ebook">
																					Description:
																				</span>
																				&nbsp;
																				<span
																					style={{
																						textDecoration: "underline",
																						fontWeight: "500",
																					}}>
																					{e.description}
																				</span>
																			</div>
																		</div>
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			{/* {e.file !== "" && (
																				<Button
																					onClick={() => {
																						// fileConversion(e.file);
																					}}
																					style={{
																						backgroundColor: "#E23F1C",
																						border: "none",
																						borderRadius: "4px",
																						marginTop: "-6px",
																						color: "#fff",
																					}}>
																					Open
																				</Button>
																			)} */}
																			{(e.isBookSaved === true ||
																				userRole === "Admin") && (
																				<Button
																					onClick={() => {
																						window.location.href =
																							"../myevents";
																					}}
																					style={{
																						backgroundColor: "#E23F1C",
																						border: "none",
																						borderRadius: "4px",
																						marginTop: "-6px",
																						color: "#fff",
																					}}>
																					Go to my events
																				</Button>
																			)}
																			{e.isBookSaved === false &&
																				userRole !== "Admin" && (
																					<div className="row">
																						{" "}
																						<Button
																							onClick={() => {
																								addtomyeventsclick(e.id);
																							}}
																							style={{
																								backgroundColor: "#E23F1C",
																								border: "none",
																								borderRadius: "4px",
																								marginTop: "-6px",
																								color: "#fff",
																							}}>
																							Add to my Events
																						</Button>
																					</div>
																				)}
																		</div>
																	</div>
																</div>
															</div>
														);
													})
												)}
											</div>
										</div>
									</Tab>
									{userRole === "Admin" && <Tab title={<FirstTab />}></Tab>}
								</Tabs>
							</div>
						</div>
						{/* <div
							className="col-3"
							style={{
								// maxHeight: "calc(100vh - 235px)",
								border: "none",
								borderRadius: "15px",
							}}>
							<Calendar />
							<div
								className="card"
								style={{ padding: "10px", marginTop: "15px" }}>
								<img src="../images/MyEvents-Empty.svg"></img>
								<div
									className="uc-discover-ele-cnt-div"
									style={{
										fontSize: "18px",
										fontWeight: "300",
										color: "#3CCE8F",
										textAlign: "center",
									}}>
									No event in current date
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Communityevents;
