import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import InviteYourContacts from "./inviteyourcontacts";
import LoginRequest from "./loginRequest";
import Navbar from "../components/Navbar";
import ProfileBanner from "./profileBanner";

const Myvideos = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const communitiesArray = [1, 2];
	const [value, setValue] = useState("");
	const [videoArray, setVideoArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (auth.user) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { loading: true },
			});
			getDataAPI(`getSavedVideos/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get books", res.data);
				// setCommunitiesArray(res.data.community);
				setVideoArray(res.data.videos);
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { loading: false },
				});
			});
		}
	}, [auth]);

	useEffect(() => {
		console.log("videoArray", videoArray);
	}, [videoArray]);

	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<ProfileBanner />

					<div className="row pt-3 justify-content-around">
						{/* <div className="col-1"></div> */}
						<div
							className="col-8 card"
							style={{
								marginRight: "5px",
								border: "none",
								borderRadiusTop: "15px",
								//   marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-8"
									style={{ display: "flex", alignItems: "center" }}>
									My Videos
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-3 uc-searchBar">
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							<div>
								{/* <div className="row">
							{communitiesArray.map((e) => {
								return (
									<div
										className="col-4"
										style={{ padding: "10px 10px 10px 10px" }}>
										<img
											src="https://img.youtube.com/vi/2pfeCmqEaFo/hqdefault.jpg"
											style={{ width: "100%" }}></img>
										<div style={{ fontSize: "18px", fontWeight: "500" }}>
											Drip Maintenance
										</div>

										<div>
											<Rating
												emptySymbol="fa fa-star-o fa-1x"
												fullSymbol="fa fa-star fa-1x"
											/>
											(1,120)
										</div>
									</div>
								);
							})}
						</div> */}
								{videoArray.length === 0 ? (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF">
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
													}}>
													No Videos are added to my videos
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								) : (
									<div className="row">
										{videoArray.map((e) => {
											return (
												<div
													key={e.id}
													className="col-4"
													style={{ padding: "10px 10px 10px 10px" }}>
													<div
														className="card"
														style={{ padding: "5px 5px 5px 5px" }}>
														<ReactPlayer
															width="100%"
															height="160px"
															controls={true}
															url={e.link}
														/>
														<div>
															<span className="ebook">Name:</span>
															&nbsp;
															<span
																style={{
																	textDecoration: "underline",
																	fontWeight: "500",
																}}>
																{e?.name}
															</span>
														</div>
														{/* <div>
																<span className="ebook">Category:</span>
																&nbsp;
																<span
																	style={{
																		textDecoration: "underline",
																		fontWeight: "500",
																	}}>
																	{e?.category}
																</span>
															</div> */}

														<div>
															<span className="ebook">Subject:</span>
															&nbsp;
															<span
																style={{
																	textDecoration: "underline",
																	fontWeight: "500",
																}}>
																{e?.subject}
															</span>
														</div>
														<div>
															<span className="ebook">Duration:</span>
															&nbsp;
															<span
																style={{
																	textDecoration: "underline",
																	fontWeight: "500",
																}}>
																{e?.duration} Mins
															</span>
														</div>
														{/* <div>
																			<span className="ebook">cost:</span>
																			&nbsp;
																			<span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}>
																				{e.cost === "" ? "Free" : e.cost}
																			</span>
																		</div> */}
														<div>
															<span className="ebook">Link:</span>
															&nbsp;
															{/* <span
																				style={{
																					textDecoration: "underline",
																					fontWeight: "500",
																				}}
																			>
																				{e.link}
																			</span> */}
															<a
																href={
																	(e.link?.includes("https://")
																		? ""
																		: "https://") + e.link
																}
																rel="no-referer noreferrer"
																target="_blank">
																{e?.link}
															</a>
														</div>

														{/* <Button
															style={{
																background: "#36BB7D",
																fontSize: "13px",
																borderRadius: "4px",
																border: "none",
															}}
															// onClick={() => addtomyvideosclick(e.id)}
														>

															Play video
														</Button> */}
													</div>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>
						{/* <div className="col-3">
					<div
						style={{
							backgroundColor: "#F4F7F9",
							padding: "15px 15px",
							marginLeft: "-12px",
							marginRight: "-12px",
							fontWeight: "600",
							fontSize: "16px",
							borderTopLeftRadius: "15px",
							borderTopRightRadius: "15px",
						}}>
						Recently Added Items
					</div>
					<div className="card row" style={{ height: "40px" }}></div>
				</div> */}
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};
export default Myvideos;
