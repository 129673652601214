import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Sanjay_dave = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/MrSanjayDave.png"
            alt=""
          />

          <h4 className="">Mr. Sanjay Dave</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Senior International Expert to the Food and Agricultural
                Organization, World Health Organization, ITC, IAEA and Asian
                Development Bank on various Food Safety related capacity
                building programmes.
              </li>
            </ul>

            <br />
            <b>Education</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Global Chairperson of Codex Alimentarius Commission (a UN body
                jointly under Food and Agricultural Organization and World
                Health Organization) and Advisor to the Food Safety and
                Standards Authority of India.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Served as a Diplomat in the Mission of India to the European
                Union at Brussels.
              </li>{" "}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Sanjay_dave;
