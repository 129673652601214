import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Alert from "./components/alert/Alert";
import StatusModal from "./components/StatusModal";
import PageRender from "./customRouter/PageRender";
import PrivateRouter from "./customRouter/PrivateRouter";
import { getNotifies } from "./redux/actions/notifyAction";
import { getPosts } from "./redux/actions/postAction";
import { getSuggestions } from "./redux/actions/suggestionsAction";

// import Home from "./components/home";
import { io } from "socket.io-client";
import About from "./components/about";
import Backgroundinitiation from "./components/backgroundinitation";
import Contactus from "./components/contactus";
import Digitalenablement from "./components/digitalenablement";
import Events from "./components/events";
import Fpos from "./components/fpos";
import News from "./components/news";
import Newsread from "./components/newsread";
import Onfarmproduction from "./components/onfarmproduction";
import Postprocessing from "./components/postprocessing";
import Qci from "./components/qci";
import Shippingdelivarypolicy from "./components/shippingdelivarypolicy";
import Speaking from "./components/speaking";
import Sustainableiniatives from "./components/sustainableiniatives";
import Teams from "./components/teams";
import Homelanding from "./pages/homelanding";
import { refreshToken } from "./redux/actions/authAction";
import { GLOBALTYPES } from "./redux/actions/globalTypes";
function App() {
	const { auth, status, modal, userType } = useSelector((state) => state);
	const dispatch = useDispatch();
	// console.log("auth", auth, Object.keys(auth).length, userType);
	const firstLogin = localStorage.getItem("firstLogin");
	useLayoutEffect(() => {
		if (firstLogin) {
			dispatch(refreshToken());

			const socket = io();
			dispatch({ type: GLOBALTYPES.SOCKET, payload: socket });
			return () => socket.close();
		}
	}, [dispatch]);

	useEffect(() => {
		if (auth.token) {
			dispatch(getPosts(auth.token));
			dispatch(getSuggestions(auth.token));
			dispatch(getNotifies(auth.token));
		} else {
			dispatch(getPosts());
		}
	}, [dispatch, auth.token]);

	// useEffect(() => {
	// 	if (auth.user === undefined) {
	// 		localStorage.removeItem("firstLogin");
	// 		postDataAPI("logout");
	// 		return;
	// 	}
	// }, [auth.user]);

	useEffect(() => {
		if (!("Notification" in window)) {
			alert("This browser does not support desktop notification");
		} else if (Notification.permission === "granted") {
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission().then(function (permission) {
				if (permission === "granted") {
				}
			});
		}
	}, []);

	function changeTheme(h) {
		document.documentElement.setAttribute("color-scheme", h);
	}
	// function ScrollToTop() {
	// 	const { pathname } = useLocation();
	// 	useEffect(() => {
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: "smooth",
	// 		});
	// 	}, [pathname]);
	// 	return null;
	// }

	// window.onbeforeunload = function () {
	// 	window.scrollTo(0, 0);
	// };
	useEffect(() => {
		window.history.scrollRestoration = "manual";
	}, []);
	return (
		<Router>
			{/* <Navbar /> */}

			<Alert />
			{/* <ScrollToTop /> */}
			<input type="checkbox" id="theme" />
			<div className={`App ${(status || modal) && "mode"}`}>
				{window.location.href.indexOf("pdfreader") <= -1 && (
					<div className="main">
						{status && <StatusModal />}
						{/* {auth.token && <SocketClient />} */}
						{/* <Route path="/" component={Navbar}> */}

						{/* <Route exact path="/login" component={Login} /> */}
						{/* <Route exact path="/register" component={Register} /> */}
						<Route exact path="/about" component={About} />
						{/* <Route exact path="/aboutus" component={Communityabout} /> */}
						<Route exact path="/contactus" component={Contactus} />
						{/* <Route exact path="/privacypolicy" component={Privacypolicy} /> */}
						{/* <Route exact path="/termsconditions" component={Termsconditions} /> */}
						{/* <Route exact path="/faq" component={Faq} /> */}
						<Route exact path="/teams" component={Teams} />
						<Route exact path="/news" component={News} />
						<Route exact path="/events" component={Events} />
						{/* <Route exact path="/homelanding" component={Homelanding} /> */}
						<Route
							exact
							path="/shippingdelivarypolicy"
							component={Shippingdelivarypolicy}
						/>
						{/* <Route
							exact
							path="/cancellationrefundpolicy"
							component={Cancellationrefundpolicy}
						/> */}
						{/* <Route exact path="/Disclaimer" component={Disclaimer} /> */}
						<Route
							exact
							path="/digitalenablement"
							component={Digitalenablement}
						/>
						{/* <Route exact path="/impactcreation" component={Impactcreation} /> */}
						<Route
							exact
							path="/backgroundinitiation"
							component={Backgroundinitiation}
						/>
						<Route
							exact
							path="/onfarmproduction"
							component={Onfarmproduction}
						/>
						<Route exact path="/postprocessing" component={Postprocessing} />
						<Route
							exact
							path="/sustainableiniatives"
							component={Sustainableiniatives}
						/>
						<Route exact path="/qci" component={Qci} />
						<Route exact path="/fpos" component={Fpos} />
						<Route exact path="/speaking" component={Speaking} />
						<Route exact path="/newsread" component={Newsread} />

						{/* <Route exact path="/community" component={Community} />
            <Route
              exact
              path="/communitykrishigap"
              component={CommunityKrishigap}
            /> */}
						{/* <Route exact path="/dashboard" component={Home} /> */}
						{/* </Route> */}
					</div>
				)}
				{/* {userType === "user" && ( */}
				<>
					{/* <Route exact path="/register" component={Register} /> */}

					<div className="wrap_page">
						<Route exact path="/" component={Homelanding} />
						<Route exact path="/home" component={Homelanding} />
						<PrivateRouter exact path="/:page" component={PageRender} />
						<PrivateRouter exact path="/:page/:id" component={PageRender} />
					</div>
				</>
				{/* )} */}
			</div>
		</Router>
	);
}

export default App;
