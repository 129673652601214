import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Sriharikotela = () => {
  return (
    <>
      <Navbar />
      <div style={{backgroundColor:"white"}}>
        <div
          className="container"
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            background: "white",
          }}
        >
          <div className="row">
            <div class="col" style={{}}>
              <center>
                <a href="">
                  <img
                    style={{ width: "170px", height: "170px" }}
                    class=""
                    src="../images/shri-hari.jpg"
                    alt=""
                  />
                </a>
                <h4>Srihari kotela</h4>
              </center>
            </div>
          </div>

          {/* <h1 className="">Key Objectives</h1> */}
          <div className="row">
            <div class="col" style={{}}>
              {/* <p class="mb-4">
              Our objective is to tackle the critical challenges faced by
              farmers' organizations and food processors in adopting and scaling
              global standards. Our key objectives are to:
            </p> */}
              <ul style={{ paddingLeft: "50px" }} className="mx-5">
                <li style={{ listStyle: "disc" }}>
                  Chairperson, India Good Agricultural Practices Certification
                  Committee, appointed by the Quality Council of India (QCI), an
                  autonomous body established by the Government of India in
                  partnership with FICCI, CII, and ASSOCHAM.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Recipient of the prestigious{" "}
                  <b>Lifetime Achievement Award </b> from the President of the
                  United States for lifelong commitment to strengthening the
                  nation through volunteer service.
                </li>
                <li style={{ listStyle: "disc" }}>
                  ISO <b>17065 International Standard</b> technical expert for
                  the certification of <b>Spices and Medicinal Plants.</b>
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>Founder,</b> Food Cert India—formerly a leading accredited
                  inspection and certification body for global food safety and
                  sustainability standards, which TATA Projects Ltd successfully
                  acquired, and now operates as <b>TQ Cert Services Pvt Ltd </b>
                  <a href="https//:www.tqcert.in" target="blank">
                    (www.tqcert.in)
                  </a>
                </li>
                <li style={{ listStyle: "disc" }}>
                  Former <b>Member</b> of several expert committees constituted
                  by the Bureau of Indian Standards (BIS), Food Safety and
                  Standards Authority of India (FSSAI), Quality Council of
                  India, Department of Ayush (Govt. of India), and Confederation
                  of Indian Industry (CII) for the development of key standards,
                  including:
                </li>
                <ul className="mx-5">
                  <li>National Agricultural Code </li>
                  <li>Good Hygiene Practices </li>
                  <li>Retail and Animal Husbandry Practices </li>
                  <li>
                    Basic Food Safety Management Systems for food establishments{" "}
                  </li>
                  <li>
                    Certification Procedures for professionals in food safety
                    compliance{" "}
                  </li>
                  <li>Globally competitive horticulture chains </li>
                  <li>
                    Voluntary Certification Mark for the food processing sector{" "}
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Sriharikotela;
