import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Mr_onkar_choche = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            // class="img-fluid"
            src="../images/Onkar_Choche.jpg"
            alt=""
          />
          <h4 className="">Mr. Onkar Choche</h4>
          Food Safety Audit, Quality and Regulatory Compliance
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                FSSC Lead Auditor.
              </li>

              <br />
            </ul>

            <b>
              Food Technology Consultant, providing services in the domains of:
            </b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                New Product Development
                <ul className="mx-5">
                  <li>
                    <span style={{ fontWeight: 500 }}>
                      Confectionery and Chocolates{" "}
                    </span>{" "}
                    – Hard Boiled Candies; Center filled Candies; Jellies;
                    Centre filled Jellies; Ice-lollies; Toffees; Wafer and Cream
                    based Confectionery products; Cocoa based Chocolates and
                    Confectionery products.
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Beverages </span> – Fruit
                    Pulp / Fruit Concentrate based Beverages; Fruit or Flavour
                    based Syrups and Concentrates, Carbonated Beverages,
                    Fermented Beverages; Non-dairy Beverages.
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Sauces </span> –
                    Emulsified sauces like Mayonnaise and its variants; Tomato
                    Ketchup; Chutneys; Sriracha; Harissa; Pizza / Pasta Sauces
                    like Arrabbiata, Marinara, Alfredo etc.
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>
                      Extruded Products and Snacks{" "}
                    </span>{" "}
                    – Cereal and Millet based extruded snacks; Traditional
                    Extruded Products (like Kurdai, Sevai, Chakli, Murukku
                    etc.); Noodles; Pasta.
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Premixes</span> – Fruit
                    based beverage premixes; Traditional Cereal Pulses and
                    Millet based premixes; Bakery premixes (like Cake premix;
                    Cookie Premix, Whipped cream premix, Custard premix etc.).
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Bakery</span> – Breads;
                    Sponge Cakes; Creams; Biscuits; Cookies.
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Dairy </span> – Milk;
                    Yogurt; Lassi; Dairy based sweets; Dairy based fermented
                    products
                  </li>
                  <li>
                    <span style={{ fontWeight: 500 }}>Seasoning</span> –
                    Seasonings for Snacks, Herb Seasonings; Masala seasonings.
                  </li>
                </ul>
              </li>
              <li>Completed total 1500+consultancy projects.</li>{" "}
              <ul className="mx-5">
                <li>
                  Process Standardization from Prototyping to Commercial
                  Scale-up stage.
                </li>
                <li>
                  Standardizing Product Specifications as per FSSAI and other
                  applicable International Standards.
                </li>
              </ul>
              <li>Factory Support:</li>{" "}
              <ul className="mx-5">
                <li>Factory Compliances.</li>
                <li> Food Safety Management System.</li>
                <li> Hazard Analysis and Risk Assessment Plan. </li>
                <li> Quality Compliance and Documentation Guidance.</li>
                <li> Quality Assurance, GMP, GHP. </li>
                <li>
                  {" "}
                  Training the factory team in product handling, process
                  hygiene, documentation and record keeping, material handling
                  etc.
                </li>
                <li>
                  {" "}
                  Conducting Internal Audits and guide the factory team for 3rd
                  Party audits.{" "}
                </li>
                <li>
                  {" "}
                  Support in implementation of voluntary standards and
                  certification like FSSC 22000, BRCGS, ISO etc.{" "}
                </li>
              </ul>
              <li> Regulatory Support as per applicable Standards:</li>
              <ul className="mx-5">
                <li> Label Declarations.</li>
                <li> Understanding Claim Declarations.</li>
                <li> Product Guidelines and Categorization.</li>
                <li>Import and Export Guidelines and Compliances.</li>
              </ul>
            </ul>

            <br />
            <b>Past</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Worked with Future Consumer Limited, Keva Flavours Private
                Limited, Anshul Life Sciences in the departments of New Product
                Development and Product Applications.
              </li>
              <li>
                Worked with Future Consumer Limited, Keva Flavours Private
                Limited, Anshul Life Sciences in the departments of New Product
                Development and Product Applications.
              </li>{" "}
            </ul>

            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Bachelor of Technology (Food Engineering and Technology) –
                Institute of Chemical Technology, Mumbai (Formerly, U.D.C.T).
              </li>{" "}
              <li>
                Post Graduate Program in Agribusiness Management (Part-time) –
                Prin. L. N. Wellingkar Institute of Management Development and
                Research, Mumbai.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Mr_onkar_choche;
