import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";

const Disclaimer = () => {
	return (
		<>
			<Navbar />
			{/* <div className="container-fluid bg-primary py-5 mb-5 page-header">
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-10 text-center">
							<h1 className="display-3 text-white animated slideInDown">
								Disclaimer
							</h1>
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb justify-content-center">
									<li className="breadcrumb-item">
										<a className="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										className="breadcrumb-item text-white active"
										aria-current="page">
										Disclaimer
									</li>
								</ol>
								<a href="index.php" className="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div> */}
			<div
				className="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div className="container">
					<div className="row g-5">
						<div
							className="col-lg-12 wow fadeInUp"
							data-wow-delay="0.3s"
							style={{
								visibility: "visible",
								animationDelay: "0.3s",
								animationName: "fadeInUp",
							}}>
							<p style={{ textAlign: "justify", marginBottom: "15px" }}>
								<b>Disclaimer:</b>
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> Krishi GAP does not necessarily own each
								component of the content contained in this platform, therefore
								it does not warrant that the use of any third party owned
								individual component or part contained in this platform will not
								infringe on the rights of those parties. The risk of a claim
								resulting from such infringement rests solely with the user. If
								the user wishes to re-use a component of the content, it is user
								responsibility to determine whether permission is needed for re
								use and to obtain permission from the copyright owner.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> The information/data contained in this
								platform is retrieved or sourced from various sources to the
								best of our judgement and should only be construed as guidance.
								and we do not take any responsibility for the accuracy, content,
								completeness, reliability and useful of such information. We
								assert that any business or investment decisions should not be
								based on the information presented in this site. We will not be
								responsible for any losses incurred by the users as a result of
								decisions made based on any information/data included in this
								site.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> The Quality Manual, Procedures, Work
								Instructions, Records etc. for the standards showcased in the
								platform are prepared to the best of our judgement and for the
								guidance of the users. Please note that these are prepared
								keeping in view the general requirements of the particular
								standard. The user is advised to look at his processes,
								products, services, customer requirements, legal and food safety
								regulatory requirements etc. as applicable to the organization,
								while drafting his quality manual and other related documents.
								The requirements are under constant revision and the user is
								advised to go through the standards updates etc. all the time.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> KrishiGAP (
								<a
									href="https://www.krishigap.com"
									target="_blank"
									rel="noreferrer">
									www.krishigap.com
								</a>
								) or its founders or its advisory board members, or employees
								are not responsible or liable for loss of profits or revenue,
								claims or damages direct or indirect, tangible or intangible,
								incidental, consequential or punitive damages or intangible
								losses arising out of the use of information or material or data
								or services as appeared in this site.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> Krishi GAP site provided links to other sites
								wherever found and it is not responsible for the content or its
								accuracy in those sites. The links are provided for your
								reference only.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> User must always visit the relevant websites
								of Standard or Scheme owners or concerned organizations involved
								in agri supply chain activities for information and compliances
								(whether government or private). Some Examples: Quality Council
								of India (
								<a href="https://www.qcin.org" target="_blank" rel="noreferrer">
									www.qcin.org
								</a>
								) GlobalG.A.P (
								<a
									href="https://www.globalgap.org"
									target="_blank"
									rel="noreferrer">
									www.globalgap.org
								</a>
								), Organic NPOP (
								<a href="https://apeda.gov.in" target="_blank" rel="noreferrer">
									www.apeda.gov.in
								</a>
								), Organic NOP (
								<a
									href="https://www.ams.usda.gov"
									target="_blank"
									rel="noreferrer">
									www.ams.usda.gov
								</a>
								), Fair Trade International (
								<a
									href="https://www.fairtrade.net"
									target="_blank"
									rel="noreferrer">
									www.fairtrade.net
								</a>
								), Rain Forest Alliance (
								<a
									href="hhttps://www.rainforest-alliance.org"
									target="_blank"
									rel="noreferrer">
									www.rainforest-alliance.org
								</a>
								), ICAR institutions, Agricultural Universities, other
								regulatory bodies.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> User can purchase or download the specific
								Standard documents from the Standard owner’s websites.
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								<strong>•</strong> NO WARRANTY, NO REPRESENTATION, NO GUARANTEE,
								NO LIABILITY DIRECT OR INDIRECT FOR THE CONTENTS IN THE
								DOCUMENTATION PROVIDED THROUGH KRISHGAP PLATFORM.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Disclaimer;
