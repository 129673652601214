import "emoji-mart/css/emoji-mart.css";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Addpost from "./addpost";
import "./communitystyles.css";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";

const CommunityKrishigap = (props) => {
	// console.log(props.data, window.location.pathname.split("/"));
	const dispatch = useDispatch();

	const { profile, auth } = useSelector((state) => state);
	const { theme } = useSelector((state) => state);
	const communitiesArray = [1, 2, 3, 4, 1];
	const [postImagesArray, setPostImagesArray] = useState([]);
	const mostActiveCommunitiesArray = [1, 2, 3, 4, 5, 6, 7, 8];
	const [showShareModal, setShowShareModal] = useState(false);
	const [showNewPostModal, setShowNewPostModal] = useState(false);
	const [showAudienceModal, setShowAudienceModal] = useState(false);
	const [community, setCommunity] = useState({});
	const [userRole, setUserRole] = useState("");
	const [size, setSize] = useState("");
	const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
	const [text, setText] = useState("");
	const [showPicker, setShowPicker] = useState(false);

	const handleCloseShareModal = () => {
		setShowShareModal(false);
	};
	const handleOpenShareModal = () => {
		setShowShareModal(true);
	};

	const handleOpenAudienceModal = () => {
		setShowAudienceModal(true);
		setShowNewPostModal(false);
	};
	const inputFileCoverPhoto = useRef(null);

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (window.location.pathname.split("/")[1] === "community") {
			getDataAPI(
				`community/${auth.user.id}/${window.location.pathname.split("/")[2]}`,
				auth.token
			).then((res) => {
				console.log("res single community", res.data);
				setCommunity(res.data.community);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});

			getDataAPI(
				`getcommunityuser/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);
			});
		}
		setTimeout(() => {
			// dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}, 500);
	}, []);

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{ backgroundColor: "#3CCE8F", border: "none" }}>
					Create Community
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		// setShow3(true);
	};
	// useEffect(() => {
	// 	console.log("text", text);
	// }, [text]);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<Modal
				show={showShareModal}
				onHide={handleCloseShareModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Share Community URL
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseShareModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body" style={{ padding: "20px 30px" }}>
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div className="row">
								<div
									className="col-9"
									style={{ paddingRight: "0px", color: "red" }}>
									<input
										type="text"
										className="uc-formControl input"
										id="copytext"
										defaultValue={
											window.location.host + window.location.pathname
										}
										disabled
										// readonly=""
									/>
								</div>
								<div className="col-3" style={{ paddingLeft: "0px" }}>
									<a
										href="javascript:void(0)"
										// onClick="copyCommunityUrl()"
										className="copybutton">
										<img src="../images/Copyshare_link.svg" alt="copy" />
										<span style={{ color: "#fff", paddingLeft: "4px" }}>
											Copy
										</span>
										{/* <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> */}
									</a>
								</div>
							</div>
						</div>
						<div
							className="uc-modal-footer share-footer"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "20px 12px 12px 12px",
							}}>
							<a
								href="mailto:?Subject=Community Url&amp;Body=https://ulektz.com/c/krishigap"
								className="email-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-email.svg"
									alt="email"
									className="image"
								/>
								<br />
								Email
							</a>
							<a
								href="https://www.linkedin.com/sharing/share-offsite/?url=https://ulektz.com/c/krishigap"
								className="linkedin-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-linkedin.svg"
									alt="linkedin"
									className="image"
								/>
								Linkedin
							</a>
							<a
								href="https://www.facebook.com/sharer.php?u=https://ulektz.com/c/krishigap"
								className="facebook-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-facebook.svg"
									alt="facebook"
									className="image"
								/>
								Facebook
							</a>
							<a
								href="https://twitter.com/share?text=Community Url&amp;url=https://ulektz.com/c/krishigap"
								className="twitter-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-twitter.svg"
									alt="twitter"
									className="image"
								/>
								Twitter
							</a>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showAudienceModal}
				onHide={handleOpenAudienceModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
									marginBottom: "0px",
								}}>
								Select Audience
							</h2>
						</div>
						{/* <div
              className="col-1"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleCloseNewPostModal}
            >
              <img src="./images/closeOne.png"></img>
            </div> */}
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
					<div style={{ paddingTop: "10px" }}>
						<div style={{ fontSize: "16px", fontWeight: "400" }}>
							Who can see your post?
						</div>
						<div style={{ fontSize: "12px", marginBottom: "25px" }}>
							Your post will be visible on feed, on your profile and see in
							search results
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								background: "#f1f1f1",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="../images/Public_icon.svg" alt="" /> All Member
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="Public"
										value="Community Public"
										checked=""
									/>
								</label>
							</div>
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="../images/Announcement.svg" alt="" /> Announcement
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="announcement"
										value="Announcement"
									/>
								</label>
							</div>
						</div>
					</div>

					<div
						className="row"
						style={{
							padding: "15px 15px 15px 15px",
							display: "flex",
							alignItems: "center",
							background: "#F1F1F1",
							//   marginLeft: "0px",
							//   marginRight:"0px"
						}}>
						<div className="col-8"></div>
						<div className="col-4">
							<a
								href="javascript:void(0);"
								className="uc-share-opts"
								// onClick="addmedia()"
								style={{
									border: "1px solid #ddd",
									padding: "6px 15px",
									borderRadius: "15px",
									background: "#fff",
									marginRight: "10px",
								}}
								onClick={(e) => {
									setShowAudienceModal(false);
									setShowNewPostModal(true);
								}}>
								<span style={{ color: "#000", paddingLeft: "5px" }}>Back</span>
							</a>
							<span
								className="col-1"
								style={{
									//   border: "1px solid #ddd",
									borderRadius: "15px",
									padding: "4px 15px 6px 20px",
									//   width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									background: "#36BB7D",
								}}>
								Save
							</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div>
				<div
					style={{
						backgroundImage: "url(./images/common-background.png)",
						height: "112px",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}></div>
				<div className="row">
					<div className="col-1"></div>
					<div
						className="card col-10"
						style={{
							marginTop: "-90px",
							padding: "13px",
							borderRadius: "15px",
						}}>
						<div className="row">
							<div
								className="col-1"
								style={{ display: "flex", alignItems: "center" }}>
								<img
									alt="logo"
									src={community.profilepic}
									onError={(e) => {
										e.target.src = "../images/community-def-logo.png";
									}}
									width="100px"
									height="100px"
									style={{
										border: "1px solid #ddd",
										borderRadius: "50%",
									}}></img>
							</div>
							<div className="col-6" style={{ paddingLeft: "30px" }}>
								<div style={{ fontSize: "22px", fontWeight: "600" }}>
									{community.name}
									{/* {userRole} */}
								</div>

								<div
									className="row"
									style={{
										paddingLeft: "0px",
										display: "flex",
										alignItems: "center",
									}}>
									<div className="col-7">
										<img
											alt="profile pic"
											onError={(e) => {
												e.target.src = "../images/community-def-logo.png";
											}}
											src={community.profilepic}
											style={{
												height: "30px",
												width: "30px",
												borderRadius: "50%",
											}}></img>
										&nbsp;{community.members} members
									</div>

									<div
										className="public-text col-5"
										// onClick="communityChangeNew(5421)"
									>
										<span className="uc-star-count">
											<a
												href="javascript:void(0)"
												className="cmn-btn uc-normal-btn">
												<img src="../images/Public_icon.svg" /> Public
											</a>
											<a
												href="javascript:void(0)"
												className="cmn-btn uc-blue-btn type">
												<span className="">Professional</span>
											</a>
										</span>
									</div>
								</div>
							</div>
							<div className="col-5">
								<div
									className="row"
									style={{ display: "flex", justifyContent: "flex-end" }}>
									<div
										className="col-3"
										style={{ width: "auto", paddingRight: "0px" }}>
										<Button
											className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
											// onClick="joinForm('MTMy',this)"
											style={{
												background: "#20ce8f",
												border: "none",
											}}>
											<img src="../images/Joined.svg"></img>&nbsp; Joined
										</Button>
									</div>
									<div
										className="col-3"
										style={{ width: "auto", paddingRight: "0px" }}>
										{" "}
										<Button
											className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
											onClick={() => {
												window.location.href = "../communityinvitations";
											}}
											style={{
												background: "#20ce8f",
												border: "none",
											}}>
											+ Invite
										</Button>
									</div>
									<div
										className="col-4"
										style={{ width: "auto", paddingRight: "15px" }}>
										{" "}
										<Button
											className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
											// onClick="joinForm('MTMy',this)"
											style={{
												background: "#fff",
												border: "none",
												color: "#000",
												border: "1px solid #ddd",
											}}>
											View Profile
										</Button>
									</div>
								</div>
								<br />
								<div
									className="row"
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}>
									<div
										className="col-9"
										style={{
											display: "flex",
											justifyContent: "flex-end",
											fontSize: "14px",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}>
										Community URL:&nbsp;
										<a
											href=""
											style={{
												color: "#41D298",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}>
											{window.location.host}
											{window.location.pathname}
										</a>
									</div>
									<div className="col-3" style={{ width: "auto" }}>
										<button
											id="opensharetoggle"
											className="head-copy-btn"
											// onClick="openShareUrlModalHead()"
											style={{
												background: "#057EE2",
												border: "none",
												color: "#fff",
												padding: "2px",
												borderRadius: "3px",
												fontSize: "12px",
												fontWeight: "400",
											}}
											onClick={handleOpenShareModal}>
											Share
											<img src="../images/share.svg" alt="Share" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row" style={{ marginTop: "20px" }}>
					<div className="col-1"></div>
					<div className="col-3" style={{ paddingLeft: "0px", width: "auto" }}>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../aboutus/" + community.id;
							}}>
							<div
								className="row"
								style={{ display: "flex", alignItems: "center" }}>
								<div className="col-2">
									<img src="../images/Courses.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										About us
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										About Foodsafety Academy
									</p>
								</div>
							</div>
						</div>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../courses/" + community.id;
							}}>
							<div
								className="row"
								style={{ display: "flex", alignItems: "center" }}>
								<div className="col-2">
									<img src="../images/Courses.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Courses
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										Courses for learning and development
									</p>
								</div>
							</div>
						</div>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								boxShadow: "0px 0px 8px 0px #b92525",
								position: "relative",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									display: "flex",
									alignItems: "center",
								}}>
								<div className="col-2">
									<img src="../images/Discussion_board.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Discussion Board
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										Discuss and share your ideas and experiences
									</p>
								</div>
							</div>
						</div>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../digitallibrary/" + community.id;
							}}>
							<div
								className="row"
								style={{
									display: "flex",
									alignItems: "center",
								}}>
								<div className="col-2">
									<img src="../images/Digital_library.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Digital Library
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										eBooks, videos, presentations, docs, etc.
									</p>
								</div>
							</div>
						</div>

						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../communityevents";
							}}>
							<div
								className="row"
								style={{ display: "flex", alignItems: "center" }}>
								<div className="col-2">
									<img src="../images/Events.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Events
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										Webinars, conferences, workshops, etc.
									</p>
								</div>
							</div>
						</div>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../message";
							}}>
							<div
								className="row"
								style={{ display: "flex", alignItems: "center" }}>
								<div className="col-2">
									<img src="../images/Messages.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Messages
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										Community messages
									</p>
								</div>
							</div>
						</div>
						<div
							className="card"
							style={{
								padding: "10px 10px",
								marginBottom: "10px",
								borderRadius: "15px",
								cursor: "pointer",
							}}
							onClick={() => {
								window.location.href = "../members";
							}}>
							<div
								className="row"
								style={{ display: "flex", alignItems: "center" }}>
								<div className="col-2">
									<img src="../images/Members.svg" alt="" />
								</div>
								<div
									className="col-9"
									style={{ paddingRight: "0px", marginLeft: "10px" }}>
									<h4 style={{ fontSize: "14px", fontWeight: "500" }}>
										Members
									</h4>
									<p style={{ fontWeight: "400", fontSize: "12px" }}>
										Connect and engage with members
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-1"></div>

					<div className="col-5" style={{ marginRight: "10px" }}>
						<Addpost />
						<div
							className="card row"
							style={{
								background: "#2DB1B9",
								height: "145px",
								marginTop: "15px",
								marginBottom: "15px",
								padding: "0px 15px 15px 40px",
								borderRadius: "15px",
							}}>
							<div className="row">
								<div
									className="col-11"
									style={{
										color: "#fff",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										paddingTop: "5px",
										paddingBottom: "10px",
									}}>
									<img
										src="../images/announcement_icon.svg"
										className="uc-announce-alert-img"></img>{" "}
									Announcement
								</div>
								<div className="col-1">
									<img
										src="../images/Pin_icon.svg"
										className="uc-announce-pin-img"
									/>
								</div>
							</div>
							<div
								className="card row"
								style={{
									height: "60px",
									borderRadius: "10px",
									padding: "10px 10px 0px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
								}}
								onClick={() => (window.location.href = "../announcement")}>
								<div className="col-1">
									<img
										src="../images/krishigaplogo.png"
										width="35px"
										height="35px"></img>
								</div>
								<div className="col-4">
									<div
										className="row"
										style={{
											display: "flex",
											alignItems: "center",
											//   justifyContent: "center",
										}}>
										<div
											className="col-6"
											style={{
												paddingLeft: "0px",
												fontSize: "15px",
												fontWeight: "500",
												cursor: "pointer",
												color: "#545454",
											}}>
											Dr. Sadiq
										</div>
										<div
											className="col-3"
											style={{
												fontSize: "9px",
												background: "#007ee2",
												paddingLeft: "0px",
												paddingRight: "0px",
												height: "14px",
												color: "#fff",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}>
											Admin
										</div>
									</div>
									<div className="row" style={{ fontSize: "12px" }}>
										13 Feb 2024 at 10:32 am
									</div>
								</div>
								<div className="col-6" style={{ fontSize: "14px" }}>
									Today, KrishiGAP team is meeting with with Agricultural
									Production..
								</div>
							</div>
							<div
								className="card row"
								style={{
									padding: "5px 5px",
									width: "36vw",
									marginLeft: "0px",
								}}></div>
							<div
								className="card row"
								style={{
									padding: "5px 5px",
									width: "35vw",
									marginLeft: "5px",
								}}></div>
						</div>
						<div className="card row" style={{ borderRadius: "15px" }}>
							<div
								className="row"
								style={{
									padding: "15px",
								}}>
								<div className="col-12">
									<div className="row">
										<div
											className="col-2"
											style={{
												width: "auto",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												paddingRight: "0px",
											}}>
											<img
												src="../images/krishigaplogo.png"
												width="40px"
												height="40px"></img>
										</div>
										<div className="col-8">
											<div style={{ fontSize: "15px", fontWeight: "500" }}>
												Dr. Sadiq Sait M.S
											</div>
											<div style={{ fontSize: "12px", fontWeight: "500" }}>
												13 Feb 2024 at 10:32 am
											</div>
										</div>
										<div
											className="col-2"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-end",
											}}>
											<OverlayTrigger
												trigger="click"
												key="bottom"
												placement="bottom"
												rootClose
												overlay={
													<Popover id={`popover-positioned-bottom`}>
														<Popover.Body>
															<div>
																<span>
																	<img src="../images/Hide_post.svg"></img>
																</span>
																&nbsp; Hide Post
															</div>
															<div>
																<span>
																	<img src="../images/Report_post.svg"></img>
																</span>
																&nbsp; Report Post
															</div>
														</Popover.Body>
													</Popover>
												}>
												<img
													src="../images/options.svg"
													alt=""
													style={{ cursor: "pointer" }}
												/>
											</OverlayTrigger>
										</div>
									</div>
								</div>
							</div>
							<div
								className="row"
								style={{
									padding: "0px 15px 5px 30px",
									fontSize: "14px",
									fontWeight: "400",
								}}>
								Horticulture of Dasheri Mango
							</div>
							<div className="row">
								<img
									src="https://ulektzmumbai.sgp1.cdn.digitaloceanspaces.com/ulektzConnect/feeds/170961/images/54c3c4fe3401dbb2.jpeg"
									alt=""
								/>
							</div>
							<div
								className="row"
								style={{
									display: "flex",
									justifyContent: "space-between",
									padding: "10px 10px",
									//   borderBottom: "1px solid #ddd",
								}}>
								<div className="col-9" style={{ fontSize: "13px" }}>
									<img
										src="../images/Like-fill-icon.svg"
										alt=""
										width="20px"
										height="20px"></img>
									<span style={{ paddingLeft: "10px", fontSize: "13px" }}>
										1
									</span>
								</div>
								<div
									className="col-3"
									style={{
										display: "flex",
										alignItems: "end",
										justifyContent: "end",
										fontSize: "13px",
										fontWeight: "400",
									}}>
									0 comments
								</div>
							</div>
							<hr style={{ marginBottom: "0px" }} />
							<div className="row" style={{ padding: "15px 15px" }}>
								<div
									className="col-6"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
									}}>
									<img src="../images/Liked.svg" width="26px"></img>&nbsp;Like
								</div>
								<div
									className="col-6"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
									}}>
									<img src="../images/Comments.svg" width="26px"></img>&nbsp;
									Comments
								</div>
							</div>
						</div>
					</div>

					{/* <div
						className="col-2 card"
						style={{
							maxHeight: "calc(100vh - 235px)",
							border: "none",
							borderRadius: "15px",
						}}>
						<div
							style={{
								backgroundColor: "#F4F7F9",
								padding: "15px 15px",
								marginLeft: "-12px",
								marginRight: "-12px",
								fontWeight: "600",
								fontSize: "16px",
								borderTopLeftRadius: "15px",
								borderTopRightRadius: "15px",
							}}>
							Invite my connections
						</div>
						<div className="pt-2">
							<input type="text" placeholder="Serach connections"></input>
						</div>
						<div
							style={{
								overflowY: "auto",
								maxHeight: "calc(100vh - 235px)",
								overflowX: "hidden",
							}}>
							{" "}
							{mostActiveCommunitiesArray.map((e) => {
								return (
									<>
										<div className="row" style={{ padding: "10px 15px" }}>
											<div
												className="col-3"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												{" "}
												<span>
													<img
														src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
														alt=""
														width="45px"
														heght="45px"
														// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
													/>
												</span>
											</div>
											<div className="col-9" style={{ paddingLeft: "0px" }}>
												<span
													className="uc-community-cnt"
													// onClick="communityChangeNew(132)"
													style={{ display: "contents" }}>
													<div className="row">
														<div className="col-7">
															<p className="uc-communityLike-in">
																<h5>Yogesh G</h5>
																<span>Krishigap</span>
															</p>
														</div>
														<div
															className="col-5"
															style={{ paddingLeft: "0px" }}>
															<Button
																className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
																// onClick="joinForm('MTMy',this)"
																style={{
																	background: "#38AB71",
																	border: "none",
																}}>
																Invite
															</Button>
														</div>
													</div>
												</span>
											</div>
											<a
												data-id="132"
												href="javascript:void(0);"
												className="uc-com-join-prof-toggle"></a>
										</div>
										<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
									</>
								);
							})}
						</div>
					</div> */}
				</div>
				{/* <div
					style={{ display: "flex", justifyContent: "end" }}
					// onClick={handleOpenNewPostModal}
				>
					<img
						src="../images/new-post-float-icon.svg"
						alt=""
						className="newpost"
					/>
					{/* <p>New Post</p> *
				</div> */}
			</div>

			<div className="bottom">
				<div className="row" style={{ padding: "10px 40px" }}>
					<div className="col-6">
						<img src="../images/Play-Store.svg"></img>&nbsp;&nbsp;&nbsp;
						<img src="../images/App-Store.svg"></img>
					</div>
					<div
						className="col-6"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							fontSize: "12px",
							color: "#9e9e9e",
							alignItems: "center",
						}}>
						© uLektz Learning Solution Private Limited - All Rights Reserved
					</div>
				</div>
			</div>
		</>
	);
};

export default CommunityKrishigap;
