import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import ImageCropper from "./imagecroppopup";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";

const initialEventData = {
	title: "",
	organisedby: "",
	startdate: "",
	starttime: "",
	enddate: "",
	endtime: "",
	mode: "",
	eventtype: "",
	description: "",
};
const Editevent = () => {
	const { profile, auth } = useSelector((state) => state);
	const [communityid, setCommunityid] = useState("");
	const [eventid, setEventid] = useState("");
	const [size, setSize] = useState("");
	const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
	const [croppedImageCoverPhoto, setCroppedImageCoverPhoto] =
		useState(undefined);
	const [show, setShow] = useState(false);
	const [eventData, seteventData] = useState(initialEventData);

	const inputFileCoverPhoto = useRef(null);
	const onButtonClickCoverPhoto = () => {
		// `current` points to the mounted file input element
		inputFileCoverPhoto.current.click();
	};
	const dispatch = useDispatch();

	useEffect(() => {
		setEventid(window.location.pathname.split("/")[2]);
		// setEventid(window.location.pathname.split("/")[3]);
		if (auth.user) {
			getDataAPI(
				`getEventById/${window.location.pathname.split("/")[2]}`,
				auth.token
			).then((res) => {
				console.log("res get events", res.data);
				seteventData(res.data.event);
				setCommunityid(res.data.event.communityid);
				// setCroppedImageCoverPhoto(res.data.event.banner);
				// setCommunitiesArray(res.data.community);
				// setUpcomingEventsArray(res.data.upcomingevents);
				// setCompletedEventsArray(res.data.completedevents);
				// setEbookCategoryArray(res.data.categories);
			});
		}
	}, [auth]);

	const handleClose = () => {
		setShow(false);
	};
	const handleOpen = () => {
		setShow(true);
	};
	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		seteventData({ ...eventData, [name]: value });
	};

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#3CCE8F",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
					}}>
					Manage Events
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		window.location.href = "../communityeventslist/" + communityid;
	};
	const onUploadFileCoverPhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropCoverPhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const handleDiscard = () => {
		seteventData(initialEventData);
		setImageToCropCoverPhoto(undefined);
		setCroppedImageCoverPhoto(undefined);
	};

	const handleSubmit = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		console.log("event data", eventData);
		const formData = new FormData();

		if (croppedImageCoverPhoto !== undefined) {
			var file1 = new File([croppedImageCoverPhoto], "coverpic.png");
			formData.append("files", file1);
		}

		const payload = {
			...eventData,
			// banner: croppedImageCoverPhoto,
			communityid: communityid,
		};
		console.log("payload", payload);

		for (var key in payload) {
			formData.append(
				key,
				typeof payload[key] === "object"
					? JSON.stringify(payload[key])
					: payload[key]
			);
		}
		// console.log("item", payload);

		try {
			const res = await postDataAPI("updateevent", formData, auth.token);
			console.log("res", res);

			if (res.status === 200) {
				window.location.reload();
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Select Image
							</h2>
						</div>
						<div className="col-1" style={{ padding: "15px" }}>
							<img
								alt="close"
								style={{ cursor: "pointer" }}
								onClick={handleClose}
								src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr />
					{imageToCropCoverPhoto ? (
						<ImageCropper
							imageToCrop={imageToCropCoverPhoto}
							onImageCropped={(croppedImage) => {
								console.log(croppedImage);
								setCroppedImageCoverPhoto(croppedImage);
							}}
							coverphoto="coverphoto"
						/>
					) : (
						<div
							style={{
								backgroundColor: "#efefef",
								height: "50vh",
								width: "34vw",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<img
								alt="cover"
								src="../images/cover_image_icon.svg"
								style={{ height: "90px" }}></img>
						</div>
					)}

					<div
						style={{
							fontSize: "13px",
							fontWeight: "300",
							textAlign: "center",
							paddingTop: "10px",
						}}>
						Recommended size - 1920 x 1080px, Max. 2 MB
					</div>
					<div
						className="row"
						style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
						<div className="col-8">
							<input
								type="file"
								accept="image/png, image/gif, image/jpeg"
								id="file"
								ref={inputFileCoverPhoto}
								style={{ display: "none" }}
								onChange={onUploadFileCoverPhoto}
							/>
							<button
								onClick={onButtonClickCoverPhoto}
								style={{
									background: "#fff",
									border: "none",
									padding: "5px",
									borderRadius: "5px",
								}}>
								<img src="../images/camera.svg" alt="" />{" "}
								<span style={{ fontSize: "12px" }}>Select Photo</span>
							</button>
							{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
						</div>
						<div className="col-2">
							<div>
								<Button
									style={{
										background: "#fff",
										// border: "none",
										height: "35px",
										color: "#000",
										border: "1px solid #ddd",
										borderRadius: "15px",
									}}
									onClick={handleClose}>
									Cancel
								</Button>
							</div>
						</div>
						<div className="col-2">
							<div>
								<Button
									onClick={handleClose}
									style={{
										background: "#3CCE8F",
										border: "none",
										height: "35px",
										borderRadius: "15px",
									}}>
									Upload
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div style={{ padding: "20px 60px 20px 120px" }}>
				<div className="row" style={{ marginLeft: "5px", display: "contents" }}>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => (window.location.href = "../foodsafetyacademy")}>
						Dashboard
					</span>{" "}
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span
						onClick={() =>
							(window.location.href = "../communityevents/" + communityid)
						}
						style={{ cursor: "pointer" }}>
						Events
					</span>
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span
						onClick={() =>
							(window.location.href = "../communityeventslist/" + communityid)
						}
						style={{ cursor: "pointer" }}>
						Events Organised List
					</span>
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span style={{ fontWeight: "500" }}>Edit Event</span>
				</div>
				<div className="">
					<div className="row pt-3 justify-content-center">
						<div
							className="col-9 card"
							style={{
								marginRight: "75px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-8"
									style={{ display: "flex", alignItems: "center" }}>
									Edit Event
								</div>
							</div>
							<div style={{ paddingTop: "15px" }}>
								<div
									style={{
										backgroundImage:
											croppedImageCoverPhoto === undefined
												? "url(../images/common-background.png)"
												: "url('" +
												  URL.createObjectURL(croppedImageCoverPhoto) +
												  "')",

										height: "200px",
										backgroundSize: "cover",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										color: "#fff",
									}}>
									Event banner
									<div
										style={{
											fontSize: "13px",
											color: "#000",
											background: "#fff",
											padding: "5px",
											position: "absolute",
											top: "10%",
											left: "80%",
											borderRadius: "4px",
											cursor: "pointer",
										}}
										onClick={handleOpen}>
										<input
											type="file"
											accept="image/png, image/gif, image/jpeg"
											id="file"
											ref={inputFileCoverPhoto}
											style={{ display: "none" }}
											onChange={onUploadFileCoverPhoto}
										/>
										<img alt="add event" src="../images/Edit_post.svg"></img>
										Edit event banner
									</div>
									<div
										style={{
											fontSize: "13px",
											color: "#fff",
											background: "#3F5151",
											padding: "5px",
											position: "relative",
											top: "30.5%",
											left: "25%",
											borderRadius: "4px",
										}}>
										Recommended size - 1920 x 1080px, Max. 2 MB
									</div>
								</div>
								{croppedImageCoverPhoto === undefined &&
									eventData.banner !== undefined && (
										<img
											alt="book"
											src={eventData.banner}
											onError={(e) => {
												e.target.src = "../images/common-background.png";
											}}
											style={{ width: "100%", height: "200px" }}></img>
									)}
							</div>
							<div
								style={{
									background: "#F4F7F9",
									padding: "10px",
									marginTop: "15px",
									border: "1px solid #ddd",
								}}>
								{/* <div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "10px",
										}}>
										Select audience <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "10px",
										}}>
										<select>
											<option>Private (Only to Community Members)</option>
										</select>
									</div>
								</div> */}
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Title of the event <span style={{ color: "red" }}>*</span>
										{/* {eventData.title} */}
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										<input
											type="text"
											name="title"
											value={eventData?.title}
											onChange={handleChangeInput}
											className="form-control"
											placeholder="Event name"></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Organised by <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										<input
											type="text"
											name="organisedby"
											value={eventData?.organisedby}
											onChange={handleChangeInput}
											className="form-control"
											placeholder="Eg.: KrishiGAP"></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Event start date & time
										<span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
											display: "flex",
										}}>
										<input
											type="date"
											name="startdate"
											value={eventData?.startdate}
											onChange={handleChangeInput}
											className="form-control"
											style={{ width: "150px" }}></input>
										&nbsp;&nbsp;
										<span
											style={{
												background: "#fff",
												padding: "10px",
												borderRadius: "50%",
												height: "30px",
												display: "flex",
												alignItems: "center",
												marginTop: "5px",
											}}>
											&
										</span>
										&nbsp;&nbsp;
										<input
											type="time"
											name="starttime"
											value={eventData?.starttime}
											onChange={handleChangeInput}
											className="form-control"
											style={{ width: "150px" }}></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Event end date & time
										<span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
											display: "flex",
										}}>
										<input
											type="date"
											name="enddate"
											value={eventData?.enddate}
											onChange={handleChangeInput}
											className="form-control"
											style={{ width: "150px" }}></input>
										&nbsp;&nbsp;
										<span
											style={{
												background: "#fff",
												padding: "10px",
												borderRadius: "50%",
												height: "30px",
												display: "flex",
												alignItems: "center",
												marginTop: "5px",
											}}>
											&
										</span>
										&nbsp;&nbsp;
										<input
											type="time"
											name="endtime"
											value={eventData?.endtime}
											onChange={handleChangeInput}
											className="form-control"
											style={{ width: "150px" }}></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Event mode <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
											display: "flex",
										}}>
										<input
											type="radio"
											name="mode"
											checked={eventData?.mode === "online"}
											// value={eventData.mode}
											onChange={handleChangeInput}
											id="academic_community"
											value="online"
											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp; Online
										<span style={{ paddingLeft: "30px" }}></span>
										<input
											type="radio"
											// value={eventData.mode}
											checked={eventData?.mode === "offline"}
											name="mode"
											onChange={handleChangeInput}
											id="academic_community"
											value="offline"
											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp; Offline
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Event Type <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-7"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
											display: "flex",
										}}>
										<input
											type="radio"
											name="eventtype"
											checked={eventData?.eventtype === "educational"}
											id="academic_community"
											value="educational"
											onChange={handleChangeInput}

											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp;
										<span style={{ display: "flex", alignItems: "center" }}>
											Educational
										</span>
										<span style={{ paddingLeft: "30px" }}></span>
										<input
											type="radio"
											name="eventtype"
											id="academic_community"
											checked={eventData?.eventtype === "recreational"}
											value="recreational"
											onChange={handleChangeInput}
											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp;{" "}
										<span style={{ display: "flex", alignItems: "center" }}>
											Recreational
										</span>
										<span style={{ paddingLeft: "30px" }}></span>
										<input
											type="radio"
											name="eventtype"
											onChange={handleChangeInput}
											id="academic_community"
											checked={eventData?.eventtype === "business"}
											value="business"
											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp;
										<span style={{ display: "flex", alignItems: "center" }}>
											Business
										</span>
										<span style={{ paddingLeft: "30px" }}></span>
										<input
											type="radio"
											name="eventtype"
											onChange={handleChangeInput}
											id="academic_community"
											checked={eventData?.eventtype === "others"}
											value="others"
											//   onChange={(e) => setCommunityType(e.target.value)}
										></input>
										&nbsp;
										<span style={{ display: "flex", alignItems: "center" }}>
											Others
										</span>
									</div>
								</div>
								<div className="row" style={{ marginBottom: "30px" }}>
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Event Description <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-9"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
											display: "flex",
										}}>
										<textarea
											className="form-control"
											name="description"
											value={eventData?.description}
											onChange={handleChangeInput}
											placeholder="Please provide a description of the event"
											rows="3"></textarea>
									</div>
								</div>
							</div>
							{/* <div
								style={{
									background: "#F4F7F9",
									padding: "10px",
									border: "1px solid #ddd",
									marginTop: "15px",
								}}>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Registration form <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-9"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										<div
											// className="col-7"
											style={{
												fontSize: "13px",
												fontWeight: "500",
												// paddingTop: "20px",
												display: "flex",
											}}>
											<div
												style={{
													background: "#fff",
													width: "32vw",
													display: "flex",
												}}>
												{" "}
												<input
													type="radio"
													name="type"
													id="academic_community"
													value="educational"
													//   onChange={(e) => setCommunityType(e.target.value)}
												></input>
												&nbsp; &nbsp;
												<span style={{}}>
													Form type
													<br />
													<span
														style={{ fontSize: "10px", paddingLeft: "0px" }}>
														(Collect registered users data within website)
													</span>
												</span>
											</div>
											<span style={{ paddingLeft: "30px" }}></span>
											<div
												style={{
													background: "#fff",
													width: "32vw",
													display: "flex",
												}}>
												<input
													type="radio"
													name="type"
													id="academic_community"
													value="recreational"
													//   onChange={(e) => setCommunityType(e.target.value)}
												></input>
												&nbsp;{" "}
												<span>
													URL type
													<br />
													<span
														style={{ fontSize: "10px", paddingLeft: "0px" }}>
														(Redirecting to another webpage or form)
													</span>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Name <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-9"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "20px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's Name"></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Email ID <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-9"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "20px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's email"></input>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "20px",
										}}>
										Mobile number <span style={{ color: "red" }}>*</span>
									</div>
									<div
										className="col-9"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "20px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's Mobile"></input>
									</div>
								</div>
								<div className="row">
									<div className="col-10"></div>
									<div className="col-2" style={{ fontSize: "13px" }}>
										Mandatory <span style={{ color: "red" }}>*</span>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "10px",
										}}>
										Institution/Organisation Name
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "5px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's Institution/Organisation"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "5px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "15px",
										}}>
										Designation
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "10px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's Designation"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "5px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "15px",
										}}>
										Date of Birth
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's date of birth"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "15px",
										}}>
										Gender
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's gender"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "15px",
										}}>
										City
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's city"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
								<div className="row">
									<div
										className="col-3"
										style={{
											fontSize: "13px",
											fontWeight: "500",
											paddingTop: "15px",
										}}>
										State
									</div>
									<div
										className="col-7"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<input
											type="text"
											className="form-control"
											placeholder="Attendee's state"></input>
									</div>
									<div
										className="col-1"
										style={{
											// fontSize: "13px",
											// fontWeight: "500",
											paddingTop: "15px",
										}}>
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											// label="Check this switch"
										/>
									</div>
								</div>
							</div> */}
							<div
								className="row"
								style={{ paddingTop: "10px", paddingBottom: "20px" }}>
								<div className="col-8"></div>
								<div
									className="col-4"
									style={{ display: "flex", justifyContent: "end" }}>
									<Button
										style={{
											background: "#fff",
											color: "#000",
											border: "1px solid #ddd",
											padding: "5px",
											// height:"15px"
										}}
										onClick={() => handleDiscard()}>
										Discard
									</Button>
									&nbsp;
									<Button
										style={{
											background: "#20ce8f",
											color: "#fff",
											border: "none",
											padding: "5px",
										}}
										onClick={() => handleSubmit()}>
										Proceed
									</Button>
								</div>
							</div>
						</div>
						{/* <div
							className="col-3"
							style={{
								// maxHeight: "calc(100vh - 235px)",
								border: "none",
								borderRadius: "15px",
							}}>
							<Calendar />
							<div
								className="card"
								style={{ padding: "10px", marginTop: "15px" }}>
								<img src="../images/MyEvents-Empty.svg"></img>
								<div
									className="uc-discover-ele-cnt-div"
									style={{
										fontSize: "18px",
										fontWeight: "300",
										color: "#3CCE8F",
										textAlign: "center",
									}}>
									No event in current date
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Editevent;
