import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./popupnav.css";
// import { useNavigate, Link } from "react-router-dom";

const Popupnav = () => {
	const [open, setOpen] = useState(false);
	const closeModal = () => setOpen(false);
	//   const navigate = useNavigate();
	return (
		<div style={{ border: "0px" }}>
			<button
				style={{ border: "0px", backgroundColor: "#ffffff00" }}
				type="button"
				className="button"
				onClick={() => setOpen((o) => !o)}>
				<i class="fa fa-bars fa-lg"></i>
				{/* <img
          className=""
          loading="lazy"
          alt=""
          src="../images/Frame 17.png"
          style={{ width: "24px", height: "24px" }}
        ></img> */}
			</button>
			<Modal
				show={open}
				style={{
					height: "100%",
					overflow: "hidden",
					marginLeft: "-17px",
				}}
				className="modalpopp">
				<div
					style={{
						width: "200px",
						height: "640px",
						backgroundColor: "green",
						marginRight: "-7px",
						marginTop: "-7px",
					}}>
					<div
						onClick={closeModal}
						style={{
							color: "white",
							float: "right",
							paddingRight: "24px",
							paddingTop: "23px",
							cursor: "pointer",
						}}>
						<img
							className=""
							loading="lazy"
							alt=""
							src="../images/Frame 7.png"
							style={{ width: "", height: "" }}></img>
					</div>

					<div
						style={{
							width: "100%",
							paddingTop: "100px",
							display: "flex",
							flexDirection: "column",
						}}>
						<a
							className="pl fm-sw f-rl "
							href="/myprofile"
							style={{
								color: "#ffffff",
								textDecoration: "none",
							}}>
							My Profile
						</a>

						<a
							className="pl fm-sw f-rl "
							href="/mybooks"
							style={{
								color: "#ffffff",
								textDecoration: "none",
							}}>
							My Books
						</a>

						<a
							className="pl fm-sw f-rl "
							href="/myvideos"
							style={{
								color: "#FFFFFF",
								textDecoration: "none",
							}}>
							My Videos
						</a>

						<a
							className="pl fm-sw f-rl "
							href="/mycources"
							style={{
								color: "#FFFFFF",
								textDecoration: "none",
							}}>
							My Courses
						</a>

						<a
							className="pl fm-sw f-rl "
							href="/myjobs"
							style={{
								color: "#FFFFFF",
								textDecoration: "none",
							}}>
							My Jobs
						</a>

						<a
							className="pl fm-sw f-rl "
							href="/myorders"
							style={{
								color: "#FFFFFF",
								textDecoration: "none",
							}}>
							My Orders
						</a>
					</div>

					{/* <div style={{ paddingBottom: "100px" }}>
            <hr style={{}} className="hr1"></hr>
          </div> */}
					{/* <div className="d-flex burger1 pl" style={{}}>
            <a
              href="../downloads/app-debug.apk"
              download
              style={{}}
            >
              <button
                className="btn-rounded-white1"
                style={{
                  border: "1px solid #000",
                }}
              >
                Download the App
              </button>
            </a>
            <button
              className="btn-rounded-black1"
              style={{
                border: "1px solid #fff",
              }}
              onClick={() => navigate("/signup/social")}
            >
              Business Sign Up
            </button>
          </div> */}
				</div>
			</Modal>
		</div>
	);
};
export default Popupnav;
