import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/rama_reddy_kovvuri.jpg"
            alt=""
          />

          <h4 className="">Rama Reddy Kovvuri</h4>
          <p> Technology Expert</p>
          <p> Vice President Tyisha Technologies</p>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <p>
              Presently working in capacity of a Vice President for Tvisha
              Technologies Incorporation, Hyderabad.
            </p>

            <b>Past</b>
            <br />

            <p>CEO, Vihaan Digital Marketing, Hyderabad, Telangana.</p>
            <p>
              Mr. Rama Reddy is currently serving as CEO of Vihaan Digital
              Marketing. He is featured in the CEO Story and Economic Times for
              his thought leadership and industry expertise. He is a visionary
              and has broad experience in business strategy, digital marketing,
              technology, operations, client engagement, partner development,
              and people management to name a few.
            </p>
            <p>
              Earlier he was serving as Vice President at eFresh India, leading
              IT and Agritech Solutions from envisioning "Digital Platform for
              Agribusiness (DPA)" to setting up Command Hubs to monitor Farmer
              Development Centers (FDC) and Marketplace integration. Mr. Rama
              Reddy technical expertise in choosing and building robust IT
              infrastructure has helped the company lower its capital and
              operating cost and increase its savings.
            </p>
            <br />
            <b>SIGNIFICANT ACHIEVEMENTS:</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Mr. Rama Reddy is a Certified Lean Six Sigma Green Belt holder
                and Certified Lead Auditor ISO QMS 9001:2008, trained &
                certified on Official Course for ‘Capability Maturity Model
                Integration’ (SEI-CMMI) and also holds ITIL V3 Foundation
                Certificate in IT Service Management.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Prior to joining Tvisha Technologies Incorporation, Mr.Rama
                Reddy has held a variety of IT management positions including
                Project Management, Head of Information Security and Quality
                Assurance for BSE listed IT services company AJEL Limited and
                brings 20 years of vast experience.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                His expertise is in Digital Transformation, PMO, IT
                modernization, Projects Automation, Client Expertise, Business
                Consulting, Program Management and KPI Management.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Leadership and Process Excellence: Act as a thought leader in
                defining success criteria and understand business needs of
                customers in an ever-changing business environment. Contribute
                to and leads strategic plans and documents for Organization.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Instrumental in implementing technology initiatives at eFresh
                Agribusiness Solutions.
              </li>{" "}
            </ul>
            <br />
            <b>EDUCATION</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                B.Sc Computer Sciences
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Microsoft Certified Professional.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Certified Lead Auditor ISO QMS
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                ITIL V3 in IT Service Management
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Lean Sigma Green Belt
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Trained and Certified on SEI-CMMI
              </li>{" "}
            </ul>
            <br />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Shashi_sareen;
