import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Targetaudience = () => {
	return (
		<>
			<Navbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<h1 className="">Target Audience</h1>

				<div className="row">
					<div class="col" style={{}}>
						<ul className="mx-5" style={{ paddingLeft: "50px" }}>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								Farmer organizations.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Food processors and packers.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								Food exporters/importers.
							</li>

							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Food retailers.
							</li>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Institutions in the food supply chain.
							</li>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Faculty and students.
							</li>
							<li
								style={{
									listStyle: "disc",
									fontSize: "20px",
									fontWeight: "500",
								}}>
								{" "}
								Suppliers of farm inputs.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Targetaudience;
