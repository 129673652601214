import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Navbar from "../components/Navbar";
import "./communitystyles.css";

const Communityeventslists = () => {
	const dispatch = useDispatch();
	const { profile, auth } = useSelector((state) => state);
	const [searchValue, setSearchValue] = useState("");
	const [communityid, setCommunityid] = useState("");
	const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
	const [completedEventsArray, setCompletedEventsArray] = useState([]);

	useEffect(() => {
		setCommunityid(window.location.pathname.split("/")[2]);
		if (auth.user) {
			getDataAPI(
				`getAllEvents/${auth.user.id}/${
					window.location.pathname.split("/")[2]
				}`,
				auth.token
			).then((res) => {
				console.log("res get events", res.data);
				// setCommunitiesArray(res.data.community);
				setUpcomingEventsArray(res.data.upcomingevents);
				setCompletedEventsArray(res.data.completedevents);
			});
		}
	}, [auth]);

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#3CCE8F",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
					}}>
					Manage Events
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		//   setShow3(true);
	};

	const handleSetEventInactive = async (event) => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		console.log("event", event);
		getDataAPI(`inactiveevent/${event.id}`, auth.token).then((res, err) => {
			console.log("res inactive event", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<div style={{ padding: "20px 60px 20px 120px" }}>
				<div className="row" style={{ marginLeft: "5px", display: "contents" }}>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => (window.location.href = "../foodsafetyacademy")}>
						Dashboard
					</span>{" "}
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span
						onClick={() =>
							(window.location.href = "../communityevents/" + communityid)
						}
						style={{ cursor: "pointer" }}>
						Events
					</span>
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span style={{ fontWeight: "500" }}>List of Organised Events</span>
				</div>
				<div className="">
					<div className="row pt-3">
						<div
							className="col-12 card"
							style={{
								marginRight: "75px",
								border: "none",
								borderRadiusTop: "15px",
								marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-6"
									style={{ display: "flex", alignItems: "center" }}>
									List of Organised Events
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-5 uc-searchBar">
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input>

									<span style={{ marginLeft: "10px" }}>
										<Button
											onClick={() =>
												(window.location.href = "../newevent/" + communityid)
											}
											style={{
												backgroundColor: "#3CCE8F",
												border: "none",
												borderRadius: "4px",
												marginTop: "-6px",
											}}>
											+ Create Event
										</Button>
									</span>
								</div>
							</div>
							<div className="row" style={{ padding: "15px" }}>
								<Tabs
									defaultActiveKey="Invitations sent to Connections"
									id="uncontrolled-tab-example"
									style={{
										backgroundColor: "#F4F7F9",
										borderRadius: "5px",
										borderBottom: "none",
									}}
									className="eventtabs">
									<Tab
										eventKey="Invitations sent to Connections"
										title="Upcoming Events">
										<div style={{ paddingTop: "20px" }}>
											<Table bordered hover>
												<thead>
													<tr>
														<th>S.No</th>
														<th>Event Name</th>
														<th>Start date</th>
														<th>End Date</th>
														{/* <th>Location</th>
														<th>Participants Count</th> */}
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{upcomingEventsArray.length === 0 ? (
														<tr
															style={{
																borderLeft: "1px solid #ddd",
																borderRight: "1px solid #ddd",
																textAlign: "center",
															}}>
															<td colSpan="5">No data availble in table</td>

															{/* <td>hii</td> */}
														</tr>
													) : (
														upcomingEventsArray.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{item.title}</td>
																	<td>
																		{item.startdate} {item.starttime}
																	</td>
																	<td>
																		{item.enddate} {item.endtime}
																	</td>
																	<td>
																		{" "}
																		<Button
																			style={{
																				// background:
																				// 	"#E23F1C",
																				fontSize: "13px",
																				borderRadius: "4px",
																				border: "none",
																				marginRight: "5px",
																			}}
																			className="btn btn-warning mt-2"
																			onClick={() =>
																				(window.location.href =
																					"../editevent/" + item.id)
																			}>
																			<i class="fas fa-edit"></i>
																			Edit Event
																		</Button>
																		<Button
																			style={{
																				background: "#E23F1C",
																				fontSize: "13px",
																				borderRadius: "4px",
																				border: "none",
																			}}
																			className="btn btn-danger mt-2"
																			onClick={() =>
																				handleSetEventInactive(item)
																			}>
																			<i
																				class="fa fa-trash"
																				aria-hidden="true"></i>{" "}
																			Delete event
																		</Button>
																	</td>
																</tr>
															);
														})
													)}
												</tbody>
											</Table>
										</div>
									</Tab>
									<Tab eventKey="Join Request Sent" title="Completed Events">
										<div style={{ paddingTop: "20px" }}>
											<Table bordered hover>
												<thead>
													<tr>
														<th>S.No</th>
														<th>Event Name</th>
														<th>Start date</th>
														<th>End Date</th>
														{/* <th>Location</th>
														<th>Participants Count</th> */}
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{completedEventsArray.length === 0 ? (
														<tr
															style={{
																borderLeft: "1px solid #ddd",
																borderRight: "1px solid #ddd",
																textAlign: "center",
															}}>
															<td colSpan="5">No data availble in table</td>

															{/* <td>hii</td> */}
														</tr>
													) : (
														completedEventsArray.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{item.title}</td>
																	<td>
																		{item.startdate} {item.starttime}
																	</td>
																	<td>
																		{item.enddate} {item.endtime}
																	</td>
																	<td>
																		{" "}
																		<Button
																			style={{
																				// background:
																				// 	"#E23F1C",
																				fontSize: "13px",
																				borderRadius: "4px",
																				border: "none",
																				marginRight: "5px",
																			}}
																			className="btn btn-warning mt-2"
																			onClick={() =>
																				(window.location.href =
																					"../editevent/" + item.id)
																			}>
																			<i class="fas fa-edit"></i>
																			Edit Event
																		</Button>
																		<Button
																			style={{
																				background: "#E23F1C",
																				fontSize: "13px",
																				borderRadius: "4px",
																				border: "none",
																			}}
																			className="btn btn-danger mt-2"
																			onClick={() =>
																				handleSetEventInactive(item)
																			}>
																			<i
																				class="fa fa-trash"
																				aria-hidden="true"></i>{" "}
																			Delete event
																		</Button>
																	</td>
																</tr>
															);
														})
													)}
												</tbody>
											</Table>
										</div>
									</Tab>
									{/* <Tab title={<FirstTab />}></Tab> */}
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Communityeventslists;
