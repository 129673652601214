import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../utils/fetchData";
import InviteYourContacts from "./inviteyourcontacts";
import LoginRequest from "./loginRequest";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";
import ProfileBanner from "./profileBanner";

const Myevents = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const communitiesArray = [1, 2];
	const [value, setValue] = useState("");
	const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
	const [completedEventsArray, setCompletedEventsArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (auth.user) {
			getDataAPI(`getSavedEvents/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get events", res.data);
				// setCommunitiesArray(res.data.community);
				setUpcomingEventsArray(res.data.upcomingevents);
				setCompletedEventsArray(res.data.completedevents);
			});
		}
	}, [auth]);

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#3CCE8F",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
					}}>
					Manage Events
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		//   setShow3(true);
	};
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<ProfileBanner />
					<div style={{ padding: "20px 60px 20px 120px" }}>
						<div className="">
							<div className="row pt-3 justify-content-center">
								<div
									className="col-9 card"
									style={{
										marginRight: "55px",
										border: "none",
										borderRadiusTop: "15px",
										// marginLeft: "10px",
										borderRadius: "15px",
									}}>
									<div
										className="row"
										style={{
											backgroundColor: "#F4F7F9",
											padding: "15px 15px",
											marginLeft: "-12px",
											marginRight: "-12px",
											fontWeight: "600",
											fontSize: "16px",
											borderTopLeftRadius: "15px",
											borderTopRightRadius: "15px",
										}}>
										<div
											className="col-8"
											style={{ display: "flex", alignItems: "center" }}>
											Events
										</div>
										<div
											className="col-1"
											style={{
												width: "auto",
												paddingRight: "0px",
												paddingLeft: "0px",
											}}>
											{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="./images/sort_icon.svg"
													alt=""
												/>
											</button> */}
										</div>
										<div className="col-3 uc-searchBar">
											<input
												type="text"
												placeholder="Search"
												onChange={(e) => setSearchValue(e.target.value)}
												className="uc-formControl1"></input>
										</div>
									</div>
									<div className="row" style={{ padding: "15px" }}>
										<Tabs
											defaultActiveKey="Invitations sent to Connections"
											id="uncontrolled-tab-example"
											style={{
												backgroundColor: "#F4F7F9",
												borderRadius: "5px",
												borderBottom: "none",
											}}
											className="eventtabs">
											<Tab
												eventKey="Invitations sent to Connections"
												title="Upcoming Events">
												{" "}
												<div className="pt-3">
													<div className="row" style={{}}>
														{upcomingEventsArray.length === 0 ? (
															<div
																className="col-12 card"
																style={{
																	marginRight: "75px",
																	border: "none",
																	borderRadius: "15px",
																}}>
																<div>
																	<div className="uc-discover-ele-div">
																		<div
																			className=""
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			<img
																				alt="myevents"
																				src="../images/MyEvents-Empty.svg"
																				height="200px"
																			/>
																		</div>
																		<div className="uc-discover-ele-cnt-div">
																			<h3
																				style={{
																					fontSize: "28px",
																					paddingTop: "15px",
																					fontWeight: "300",
																				}}>
																				No Upcoming Event
																			</h3>
																		</div>
																		<div>
																			Try shortening or rephrasing your search
																		</div>
																	</div>
																</div>
															</div>
														) : (
															upcomingEventsArray.map((e) => {
																return (
																	<div key={e.id} style={{ padding: "5px" }}>
																		<div
																			className="card"
																			style={{ padding: "5px" }}>
																			<div className="row">
																				<div
																					className="col-3"
																					style={{
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}>
																					<img
																						alt="book"
																						src={e.banner}
																						onError={(e) => {
																							e.target.src =
																								"../images/common-background.png";
																						}}
																						style={{ width: "100%" }}></img>
																				</div>
																				<div className="col-6">
																					<div>
																						<span className="ebook">
																							Title:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.title}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Organised by:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.organisedby}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Start time:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.startdate} {e.starttime}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							End time:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.enddate} {e.endtime}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">Mode:</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.mode}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">Type:</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.eventtype}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Description:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.description}
																						</span>
																					</div>
																				</div>
																				<div
																					className="col-3"
																					style={{
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}></div>
																			</div>
																		</div>
																	</div>
																);
															})
														)}
													</div>
												</div>
											</Tab>
											<Tab eventKey="Join Request Sent" title="Past Events">
												{" "}
												<div className="pt-3">
													<div className="row" style={{}}>
														{completedEventsArray.length === 0 ? (
															<div
																className="col-12 card"
																style={{
																	marginRight: "75px",
																	// marginLeft: "10px",
																	border: "none",
																	borderRadius: "15px",
																}}>
																<div>
																	<div className="uc-discover-ele-div">
																		<div
																			className=""
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			<img
																				alt="my events"
																				src="../images/MyEvents-Empty.svg"
																				height="200px"
																			/>
																		</div>
																		<div className="uc-discover-ele-cnt-div">
																			<h3
																				style={{
																					fontSize: "28px",
																					paddingTop: "15px",
																					fontWeight: "300",
																				}}>
																				No Past Event
																			</h3>
																		</div>
																		<div>
																			Try shortening or rephrasing your search
																		</div>
																	</div>
																</div>
															</div>
														) : (
															completedEventsArray.map((e) => {
																return (
																	<div key={e.id} style={{ padding: "5px" }}>
																		<div
																			className="card"
																			style={{ padding: "5px" }}>
																			<div className="row">
																				<div
																					className="col-3"
																					style={{
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}>
																					<img
																						alt="book"
																						src={e.banner}
																						onError={(e) => {
																							e.target.src =
																								"../images/common-background.png";
																						}}
																						style={{ width: "100%" }}></img>
																				</div>
																				<div className="col-7">
																					<div>
																						<span className="ebook">
																							Title:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.title}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Organised by:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.organisedby}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Start time:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.startdate} {e.starttime}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							End time:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.enddate} {e.endtime}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">Mode:</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.mode}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">Type:</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.eventtype}
																						</span>
																					</div>
																					<div>
																						<span className="ebook">
																							Description:
																						</span>
																						&nbsp;
																						<span
																							style={{
																								textDecoration: "underline",
																								fontWeight: "500",
																							}}>
																							{e.description}
																						</span>
																					</div>
																				</div>
																				<div
																					className="col-3"
																					style={{
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}></div>
																			</div>
																		</div>
																	</div>
																);
															})
														)}
													</div>
												</div>
											</Tab>
											{/* <Tab title={<FirstTab />}></Tab> */}
										</Tabs>
									</div>
								</div>
								{/* <div
									className="col-3"
									style={{
										// maxHeight: "calc(100vh - 235px)",
										border: "none",
										borderRadius: "15px",
									}}>
									<Calendar />
									<div
										className="card"
										style={{ padding: "10px", marginTop: "15px" }}>
										<img src="./images/MyEvents-Empty.svg"></img>
										<div
											className="uc-discover-ele-cnt-div"
											style={{
												fontSize: "18px",
												fontWeight: "300",
												color: "#3CCE8F",
												textAlign: "center",
											}}>
											No event in current date
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Myevents;
