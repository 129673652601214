import React from "react";
import { useSelector } from "react-redux";
import LoginRequest from "./loginRequest";
import Header from "../components/header/Header";
import Navbar from "../components/Navbar";

const Myjobs = () => {
	const { theme, auth } = useSelector((state) => state);
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<div style={{ padding: "20px 60px 20px 120px" }}>
						<div className="card" style={{ padding: "15px 15px" }}>
							<div style={{ fontSize: "16px", fontWeight: "500" }}>My jobs</div>
							<hr />
							<div style={{ paddingBottom: "30px", paddingTop: "30px" }}>
								<div style={{ display: "flex", justifyContent: "center" }}>
									<img src="./images/My-jobs-Empty.svg"></img>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "300",
										color: "#3FD093",
									}}>
									No Jobs added to your My Jobs
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "14px",
										fontWeight: "400",
									}}>
									Go to uLektz Jobs to find and apply for any Internship and Job
									Opportunities
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Myjobs;
