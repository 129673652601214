import React from "react";
import { useSelector } from "react-redux";

const Avatar = ({ src, size }) => {
	// console.log("src", src);
	const { theme } = useSelector((state) => state);
	return (
		<img
			src={src}
			onError={(e) => {
				e.target.src = "../images/common-user.svg";
			}}
			alt="Avatar"
			className={size}
			style={{ filter: `${theme ? "invert(1)" : "invert(0)"}` }}
		/>
	);
};

export default Avatar;
