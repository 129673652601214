import React from "react";
import Footer from "../common/footer";
import Navbar from "./Navbar";

const Qci = () => {
  return (
    <>
      <Navbar />
      <div class="container-fluid bg-primary py-5 mb-5 page-header">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
              <h1 class="display-3 text-white animated slideInDown">Events</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item">
                    <a class="text-white" href="index.php">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Events
                  </li>
                </ol>
                <a href="/home" class="btn btn-success btn-sm">
                  Go Back
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-xxl py-5"
        style={{
          paddingTop: "0rem !important",
          paddingBottom: "0rem !important",
        }}
      >
        <div class="container">
          <div class="page-detail-line">
            <h3 class="text-center mb-2 mt-5 title-m-1">
              QCI Ind G.A.P Portal
            </h3>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Exciting News in Agriculture! We're thrilled to share that the
              Quality Council of India has officially launched the IndG.A.P
              Portal for Practices on December 19th 2023 In
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Design inputs were provided (www.krishigap.com), this IndG.A.P
              scheme Recognition from GlobalG.A.P, equivalent to Version 5.2 for
              Fruits and Vegetables!
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              What does this mean? IndG.A.P is now recognized in over 120
              countries, paving the way for global collaboration in promoting
              sustainable and high quality agricultural practices.
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              This is a significant milestone for the agricultural community!
              #IndGAP #AgricultureInnovation #SustainableFarming
              #GlobalRecognition
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Srihari Kotela
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Founder, Krishigap Digital Solutions Pvt Ltd
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Mobile 9848034740
            </p>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              www.krishigap.com
            </p>
          </div>
          <div class="row"></div>
        </div>
      </div>
      <div>
        <Footer className="jnav"></Footer>
      </div>
    </>
  );
};

export default Qci;
