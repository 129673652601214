import React from "react";
import Footer from "../common/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";

const About = () => {
  return (
    <>
      <Navbar />
      {/* <div>
        <img
          src="../images/aboutbanner.jpg"
          alt="krishi-gap-logo"
          style={{ height: "500px", width: "auto" }}
        />
      </div> */}
      <div class="container-fluid bg-primary py-5 mb-5 page-header">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
              <h1 class="display-3 text-white animated slideInDown">
                About &amp; Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item">
                    <a class="text-white" href="index.php">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
                <a href="/home" class="btn btn-success btn-sm">
                  Go Back
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-6"> veera </div>
        <div className="col-6">brahmam</div>
      </div> */}
      <div class="container">
        {/* <!---<h6 class="section-title bg-white text-start text-primary pe-3">About Us</h6>---> */}
        <h1 class="mb-4 text-center">MISSION</h1>
        <div className="row">
          <div
            class="col-lg-7 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <p class="mb-4">
              Our Mission is to promote food safety and sustainable standards by
              enhancing the availability of skilled manpower and inculcating a
              culture of food safety in the entire agri value chain and to
              create enhanced income to the farming community and safe food to
              the consumers
            </p>

            <p class="mb-4">
              This will be achieved through Digital Platform as a one stop
              solution by networking among food safety institutions/stakeholders
              in agri echo system.
            </p>
          </div>
          <div class="col-md-5">
            <div class="img">
              <img src="../images/missionimage.jpg" />
            </div>
          </div>
        </div>
        <div className="row">
          <div
            class="col-lg-12 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <h1 class="text-center pt-4">OUR APPROACH</h1>
            <p class="mb-4 text-center">
              Taking digital learning to crop production/food processing centers
              to empower farmer's organizations and food processors with ease
              and scale of implementation.
            </p>
          </div>
        </div>
        <div className="row">
          <div
            class="col-lg-6 mt-0 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="phase-box">
              <div class="phase-click">
                <a href="">Phase-1 : India</a>
              </div>
              <div class="img">
                <img
                  src="../images/phase1.jpg"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 mt-0 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="phase-box">
              <div class="phase-click">
                <a href="">Phase-2 : Global</a>
              </div>
              <div class="img">
                <img
                  src="../images/phase2.jpg"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </div>
          <p class="mb-4 text-center pt-5">
            Maintaining the integrity and independence of its activities, Krishi
            GAP will not be involved in Certification Activities, which are done
            by Accredited Certification Bodies.
          </p>
        </div>
        <div className="row">
          <div
            class="col-lg-12 mb-4 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <h1 class="mt-5">OUR CUSTOMERS</h1>
            <p class="mb-4">
              {" "}
              Farmers organizations, Food growers, Food processors/ packers,
              Food exporters/importers , Food retailers, Transport &amp;
              Logistic providers, Institutions in food supply chain, Faculty
              &amp; Students ,Suppliers of farm inputs
            </p>
            <p>
              <a
                href="#"
                class="btn btn-success pull-left"
                style={{ boxShadow: " 0 2px 5px 0 rgb(0 0 0 / 20%)" }}
              >
                Customer
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div
            class="col-lg-8 mb-4 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <h1 class="mt-5">OUR TEAM</h1>
            <p class="mb-4">
              Mr Srihari Kotela, a standard specialist and former founder of
              Foodcert India ( Now TQ Cert Services Pvt Ltd, a TATA group
              Company). He is assisted by experts who lend their skills,
              guidance and strategic advice to grow and achieve its mission
            </p>
            <p>
              <a
                href="/teams"
                class="btn btn-success pull-left"
                style={{ boxShadow: "0 2px 5px 0 rgb(0 0 0 / 20%)" }}
              >
                Team
              </a>
            </p>
          </div>
          <div class="col-md-4">
            <div class="img border">
              <img
                src="../images/shrihari.jpg"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              />
            </div>
          </div>
        </div>
        <h4 className="pt-4">
          <strong>
            Krishi GAP activities are aligned with The Sustainable Development
            Goals of United Nations
          </strong>
        </h4>
        <div className="row" style={{ paddingTop: "40px" }}>
          <div
            class="col-lg-4 wow fadeInLeft"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInLeft",
            }}
          >
            <div class="six-box-re green-bg">
              <div class="six-box-icon">
                <img src="../images/icon1.png" />
              </div>
              <div class="six-box-title">Good Health and Well-Being</div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="six-box-re skyblue-bg">
              <div class="six-box-icon">
                <img src="../images/icon2.png" />
              </div>
              <div class="six-box-title">Quality Education</div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInRight"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInRight",
            }}
          >
            <div class="six-box-re lightred-bg">
              <div class="six-box-icon">
                <img src="../images/icon3.png" />
              </div>
              <div class="six-box-title">Decent Work &amp; Economic Growth</div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "30px" }}>
          <div
            class="col-lg-4 wow fadeInLeft"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInLeft",
            }}
          >
            <div class="six-box-re greenlight-bg">
              <div class="six-box-icon">
                <img src="../images/icon4.png" />
              </div>
              <div class="six-box-title">
                Responsible Consumption &amp; Production
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInUp"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="six-box-re orange-bg">
              <div class="six-box-icon">
                <img src="../images/icon5.png" />
              </div>
              <div class="six-box-title">Climate Action</div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInRight"
            data-wow-delay="0.3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInRight",
            }}
          >
            <div class="six-box-re green-bg">
              <div class="six-box-icon">
                <img src="../images/icon6.png" />
              </div>
              <div class="six-box-title">Live on Land</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer className="jnav"></Footer>
      </div>
    </>
  );
};

export default About;
