import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	likePost,
	savePost,
	unLikePost,
	unSavePost,
} from "../../../redux/actions/postAction";
import Avatar from "../../Avatar";
import LikeButton from "../../LikeButton";
import ShareModal from "../../ShareModal";
// import { BASE_URL } from '../../../utils/config';
import Comments from "../Comments";
import InputComment from "../InputComment";

const CardFooter = ({ post }) => {
	const [isLike, setIsLike] = useState(false);
	const [saved, setSaved] = useState(false);
	const [loadLike, setLoadLike] = useState(false);
	const [saveLoad, setSaveLoad] = useState(false);
	const [isShare, setIsShare] = useState(false);
	const [showCommentBox, setShowCommentBox] = useState(false);
	// Function to toggle the comment box visibility

	const dispatch = useDispatch();
	const { auth, theme, socket } = useSelector((state) => state);

	useEffect(() => {
		console.log("auth.user", auth.user, post.likes);
		if (auth.user) {
			if (post.likes.find((like) => like.id === auth.user.id)) {
				setIsLike(true);
			} else {
				setIsLike(false);
			}
		} else {
			setIsLike(false);
		}
	}, [post.likes, auth.user, loadLike]);

	const handleLike = async () => {
		if (loadLike) return;
		if (auth.user) {
			setLoadLike(true);
			await dispatch(likePost({ post, auth, socket }));
			setLoadLike(false);
		} else {
			alert("Please login to like this post");
		}
	};

	const toggleCommentBox = () => {
		setShowCommentBox((prevState) => !prevState);
	};

	const handleUnLike = async () => {
		if (loadLike) return;
		setLoadLike(true);
		await dispatch(unLikePost({ post, auth, socket }));
		setLoadLike(false);
	};

	const handleSavePost = async () => {
		if (saveLoad) return;
		setSaveLoad(true);
		await dispatch(savePost({ post, auth }));
		setSaveLoad(false);
	};

	const handleUnSavePost = async () => {
		if (saveLoad) return;
		setSaveLoad(true);
		await dispatch(unSavePost({ post, auth }));
		setSaveLoad(false);
	};

	useEffect(() => {
		if (auth.user) {
			if (auth.user.saved.find((id) => id === post.id)) {
				setSaved(true);
			} else {
				setSaved(false);
			}
		} else {
			setSaved(false);
		}
	}, [post.id, auth.user]);

	return (
		// <div className="card_footer">
		//   <div className="card_icon_menu">
		//     <div className="d-flex">
		<div className="row" style={{ padding: "15px 15px" }}>
			<LikeButton
				isLike={isLike}
				handleLike={handleLike}
				handleUnLike={handleUnLike}
			/>
			{/* <Link to={`/post/${post._id}`} className="text-dark">
				<i className="far fa-comments" />
			</Link> */}
			{/* <div
				className="col-6"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
				}}>
				<img alt="comments" src="./images/Comments.svg" width="26px"></img>
				&nbsp; Comments
			</div> */}
			<div>
				<div
					className="col-6"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
						marginLeft: "300px",
						marginTop: "-25px",
					}}
					onClick={toggleCommentBox}>
					<img alt="comments" src="./images/Comments.svg" width="26px" />
					&nbsp; Comments
				</div>
				{showCommentBox && (
					<div className="d-flex flex-column">
						<Comments post={post} />
						<div className="d-flex w-100 align-items-center">
							<Avatar src={""} size="medium-avatar2" />
							<div
								style={{
									background: "#fff",
									// padding: "10px",
									borderRadius: "5px",
									// border: "1px solid #ccc",
									marginTop: "10px",
									marginLeft: "10px",
									width: "100%",
								}}>
								{/* <textarea
								rows="1"
								placeholder="Write your comment..."
								style={{
									width: "100%",
									padding: "10px",
									borderRadius: "5px",
									// border: "none",
								}}></textarea> */}

								<InputComment post={post} />
							</div>
						</div>
					</div>
				)}
			</div>

			{/* <div
				className="col-4"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
				}}>
				share
			</div> */}

			{/* <i
				className="fa fa-share"
				alt="Send"
				onClick={() => setIsShare(!isShare)}
			/> */}
			{/* </div> */}
			{/* {saved ? (
            <i
              className="fas text-info fa-bookmark"
              onClick={handleUnSavePost}
            />
          ) : (
            <i
              className="far fa-bookmark"
              onClick={handleSavePost}
            />
          )} */}

			{/* <div className="d-flex justify-content-start">
          <h6 style={{ padding: "0 25px", cursor: "pointer" }}>
            {post.likes.length} likes
          </h6>
          <h6 style={{ padding: "0 25px", cursor: "pointer" }}>
            {post.comments.length} comments
          </h6>
        </div> */}

			{isShare && (
				<ShareModal
					// url={`${BASE_URL}/post/${post._id}`}
					url="http://google.com"
					theme={theme}
					setIsShare={setIsShare}
				/>
			)}
		</div>
	);
};

export default CardFooter;
