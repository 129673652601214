import "font-awesome/css/font-awesome.min.css";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Courselanding from "./courselanding";

const Homelanding = () => {
	const responsive1 = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
	};
	return (
		<div>
			<Navbar />
			<Carousel
				responsive={responsive1}
				arrows={true}
				autoPlay
				infinite
				slidesToSlide={1}>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding1.png')",
							// opacity: 0.7,
							filter: "contrast(.7) brightness(.7)",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								// className="content"
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								Guidance on Compliance with Global
								<br /> Food Safety, Sustainability, USDA, and
								<br />
								USFDA Standards in the Food Supply
								<br /> Chain
							</div>
							{/* </div> */}
						</div>
					</div>
				</div>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding2.png')",
							filter: "contrast(.7) brightness(.7)",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#ffffff",
								}}>
								{" "}
								AI Technology Poised to Revolutionize
								<br /> Skill Development to facilitate compliance
								<br /> with the Standards in the food supply <br />
								chain
							</div>
						</div>
					</div>
				</div>

				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding3.jpg')",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								Unlock Your Potential and Evaluate Your
								<br /> Expertise in Compliance with Food <br />
								Supply Chain Standards
							</div>
							{/* <div className="row">
								<div
									className=""
									style={{
										fontFamily: "Manrope",
										fontSize: "16px",
										fontWeight: "500",
										lineHeight: "19.2px",
										// letterSpacing: "-2em",
										textAlign: "left",
										color: "#FFFFFF",
									}}>
									Lorem ipsum dolor sit amet consectetur. Augue quam volutpat
									cursus eu viverra integer. Risus interdum quis orci egestas
									venenatis. Turpis feugiat leo sit ultrices leo. Libero laoreet
									pellentesque scelerisque proin odio enim aliquet nisl
									porttitor.
								</div>
								<div className="row" style={{ paddingTop: "10px" }}>
									<div className="col-2">
										<button
											style={{
												width: "100px",
												height: "45px",
												borderRadius: "12px",
												background: " #E8A700",
											}}>
											Contact
										</button>
									</div>

									<div className="col-2" style={{ paddingLeft: "30px" }}>
										<button
											style={{
												width: "100px",
												height: "45px",
												borderRadius: "12px",
												background: "#EEEEEE1F",
												color: "#ffffff",
											}}>
											Learn&nbsp;more
										</button>
									</div>
								</div>
							</div> */}
						</div>
						{/* <div
							className="row"
							style={{
								paddingLeft: "40px",
								display: "flex",
								alignItems: "center",
								paddingTop: "140px",
							}}>
							<div className="col-8">
								<div
									className=""
									style={{
										fontFamily: "Manrope",
										fontSize: "24px",
										fontWeight: "600",
										lineHeight: "24px",
										letterSpacing: "-0.02em",
										textAlign: "",
										color: "#ffffff",
									}}>
									An Overview on Krishi GAP
								</div>

								<div className="row" style={{}}>
									<div className="col-12">
										<div
											style={{
												fontFamily: "Manrope",
												fontSize: "14px",
												fontWeight: "500",
												lineHeight: "15.4px",
												textAlign: "left",
												color: "#ffffff",
												top: "500",
											}}>
											The major challenge faced by farmers' organizations and
											food processors is the lack of understanding of the
											standards and guidance necessary for implementation,
											particularly in crop production clusters. Krishigap
											platform provides comprehensive guidance in the form of
											Quality Manuals, Procedures. Records templates, and other
											essential information required for certification.
											facilitating ease in implementation on a scale. The
											standards covered by Krishigap Digital Platform include..
											Read more
										</div>
									</div>
								</div>
							</div>
							<div className="col-4" style={{ textAlign: "right" }}>
								<iframe
									width="220px"
									height="120px"
									src="https://www.youtube.com/embed/mzLiWviehso?si=Ym8mO7FXVnTGmUY7"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen=""
									//   borderRadius="15px"
								></iframe>
							</div>
						</div> */}
					</div>
				</div>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding4.jpg')",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								At the Global Academy, we advocate for
								<br /> inclusive, human-centered
								<br /> learning,enhanced by the power of
								<br /> technology
							</div>
							{/* <div className="row">
								<div
									className=""
									style={{
										fontFamily: "Manrope",
										fontSize: "16px",
										fontWeight: "500",
										lineHeight: "19.2px",
										// letterSpacing: "-2em",
										textAlign: "left",
										color: "#FFFFFF",
									}}>
									Lorem ipsum dolor sit amet consectetur. Augue quam volutpat
									cursus eu viverra integer. Risus interdum quis orci egestas
									venenatis. Turpis feugiat leo sit ultrices leo. Libero laoreet
									pellentesque scelerisque proin odio enim aliquet nisl
									porttitor.
								</div>
								<div className="row" style={{ paddingTop: "10px" }}>
									<div className="col-2">
										<button
											style={{
												width: "100px",
												height: "45px",
												borderRadius: "12px",
												background: " #E8A700",
											}}>
											Contact
										</button>
									</div>

									<div className="col-2" style={{ paddingLeft: "30px" }}>
										<button
											style={{
												width: "100px",
												height: "45px",
												borderRadius: "12px",
												background: "#EEEEEE1F",
												color: "#ffffff",
											}}>
											Learn&nbsp;more
										</button>
									</div>
								</div>
							</div> */}
						</div>
						{/* <div
							className="row"
							style={{
								paddingLeft: "40px",
								display: "flex",
								alignItems: "center",
								paddingTop: "140px",
							}}>
							<div className="col-8">
								<div
									className=""
									style={{
										fontFamily: "Manrope",
										fontSize: "24px",
										fontWeight: "600",
										lineHeight: "24px",
										letterSpacing: "-0.02em",
										textAlign: "",
										color: "#ffffff",
									}}>
									An Overview on Krishi GAP
								</div>

								<div className="row" style={{}}>
									<div className="col-12">
										<div
											style={{
												fontFamily: "Manrope",
												fontSize: "14px",
												fontWeight: "500",
												lineHeight: "15.4px",
												textAlign: "left",
												color: "#ffffff",
												top: "500",
											}}>
											The major challenge faced by farmers' organizations and
											food processors is the lack of understanding of the
											standards and guidance necessary for implementation,
											particularly in crop production clusters. Krishigap
											platform provides comprehensive guidance in the form of
											Quality Manuals, Procedures. Records templates, and other
											essential information required for certification.
											facilitating ease in implementation on a scale. The
											standards covered by Krishigap Digital Platform include..
											Read more
										</div>
									</div>
								</div>
							</div>
							<div className="col-4" style={{ textAlign: "right" }}>
								<iframe
									width="220px"
									height="120px"
									src="https://www.youtube.com/embed/mzLiWviehso?si=Ym8mO7FXVnTGmUY7"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen=""
									//   borderRadius="15px"
								></iframe>
							</div>
						</div> */}
					</div>
				</div>
				{/* <div
          className=""
          style={{
            backgroundImage: "url('../images/homelanding2.png')",
            height: "400px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row">
            <div
              className="col-5"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              <div
                style={{
                  fontFamily: "Manrope",
                  fontSize: "52px",
                  fontWeight: "700",
                  lineHeight: "52px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {" "}
                FSSAI
              </div>

            </div>

          </div>
        </div> */}
			</Carousel>
			<div className="row">
				<div className="col-4" style={{ padding: "40px 0px 0px 40px" }}>
					<div
						style={{
							// fontFamily: "Manrope",
							fontSize: "52px",
							fontWeight: "600",
							lineHeight: "52px",
						}}>
						<div style={{ color: "green" }}>Global Academy</div> does
					</div>
					<div className="row mt-2">
						<div
							className=""
							style={{
								// fontFamily: "Manrope",
								fontSize: "14px",
								fontWeight: "400",
								lineHeight: "14px",
							}}>
							We are committed to digitally empowering stakeholders across the
							food supply chain by promoting compliance with global Food Safety
							and Sustainability Standards.
						</div>
					</div>
					<div className="row">
						<div className="col" style={{ paddingTop: "10px" }}>
							<button
								onClick={() => (window.location.href = "/aboutus")}
								style={{
									width: "100px",
									height: "45px",
									borderRadius: "12px",
									background: "#000000",
								}}>
								<div className="" style={{ color: "#ffffff" }}>
									Learn More
								</div>
							</button>
						</div>
					</div>
				</div>
				<div className="col-8" style={{ padding: "40px 0px 0px 40px" }}>
					<div
						className="row"
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
						// style={{ cursor: "pointer" }}
						onClick={() =>
							(window.location.href =
								"/courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
						}>
						<div className="col-8">
							<div
								style={{
									// fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Courses
							</div>
							<div className="row mt-2">
								<div
									style={{
										// fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
									}}>
									Skill Development Courses on Global Food Safety and
									Sustainability Standards to strengthen your expertise.
								</div>
							</div>
						</div>
						<div className="col-4">
							<img
								alt="courses"
								src="../images/coursesnew.jpg"
								style={{
									width: "230px",
									height: "130px",
									border: "1px solid green",
									textAlign: "end",
									borderRadius: "20px",
								}}
							/>
						</div>
					</div>
					<hr style={{ height: "1px", color: "black" }}></hr>

					<div
						className="row"
						style={{
							display: "flex",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={() =>
							(window.location.href =
								"/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
						}>
						<div className="col-8">
							<div
								style={{
									// fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Digital Library
							</div>
							<div className="row mt-2">
								<div
									style={{
										// fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
									}}>
									Collection of videos on good agricultural practices in the
									implementation of on Farm Production related Standards.
								</div>
							</div>
						</div>
						<div className="col-4">
							<img
								alt="digital library"
								src="../images/digitallibrarynew.jpg"
								style={{
									width: "230px",
									height: "130px",
									textAlign: "end",
									border: "1px solid green",
									borderRadius: "20px",
								}}
							/>
						</div>
					</div>
					<hr style={{ height: "1px", color: "black" }}></hr>

					<div
						className="row"
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
						onClick={() => (window.location.href = "/foodsafetyacademy")}>
						<div className="col-8">
							<div
								style={{
									// fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Discussion Board
							</div>
							<div className="row mt-2">
								<div
									style={{
										// fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
									}}>
									Join the discussion Board to engage with peers and
									professionals in the food safety and sustainability sector.
								</div>
							</div>
						</div>
						<div className="col-4">
							<img
								alt="discussion board"
								src="../images/discusionboardimg.jpg"
								style={{
									width: "230px",
									border: "1px solid green",
									height: "130px",
									textAlign: "end",
									borderRadius: "20px",
								}}
							/>
						</div>
					</div>
					<hr style={{ height: "1px", color: "black" }}></hr>

					{/* <div
						className="row"
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
						onClick={() =>
							(window.location.href =
								"./communityevents/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
						}>
						<div className="col-8">
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Events
							</div>
							<div className="row">
								<div
									style={{
										fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
									}}>
									Connecting buyers to certified organizations.
								</div>
							</div>
						</div>
						<div className="col-4">
							<img
								alt="events"
								src="../images/eventsmeet.jpg"
								style={{
									width: "230px",
									height: "130px",
									textAlign: "end",
									borderRadius: "20px",
								}}
							/>
						</div>
					</div>
					<hr style={{ height: "1px", color: "black" }}></hr> */}

					<div
						className="row"
						style={{
							display: "flex",
							alignItems: "center",
							paddingBottom: "30px",
							cursor: "pointer",
						}}
						onClick={() =>
							(window.location.href =
								"/jobs/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
						}>
						<div className="col-8">
							<div
								style={{
									// fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Placement Opportunities
							</div>
							<div className="row mt-2">
								<div
									style={{
										// fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
									}}>
									Opportunities for for food safety professionals and graduates
									across the food supply chain.
								</div>
							</div>
						</div>
						<div className="col-4">
							<img
								alt="jobs"
								src="../images/jobsnew.jpg"
								style={{
									width: "230px",
									height: "130px",
									textAlign: "end",
									borderRadius: "20px",
									border: "1px solid green",
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Homelanding;
