import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Ourteam = () => {
	return (
		<div style={{ backgroundColor: "#ffffff" }}>
			<Navbar />
			<div
				className="container"
				style={{
					paddingTop: "30px",
					paddingBottom: "30px",
					backgroundColor: "#ffffff",
				}}>
				<h1 className="" style={{ textAlign: "center" }}>
					Our Team
				</h1>

				{/* <h3 className="">Company formation</h3> */}
				<div className="row">
					<div class="col" style={{}}>
						<p class="mb-4">
							Founders are backed by an experienced <b>Advisory Board</b> and
							experts with diverse specializations, including{" "}
							<b>Global Standards in Food Safety</b> and{" "}
							<b>Sustainability, Product Development, Organic, Dairy,</b> and
							<b>
								Aquaculture Value Chains, Information Technology, Finance,
							</b>{" "}
							and <b>Go-to-Market Strategy.</b>
						</p>
					</div>
				</div>

				<div id="exTab2">
					<ul class="nav nav-tabs">
						<li class="active">
							<a href="#1" data-toggle="tab">
								Founder
							</a>
						</li>
						<li>
							<a href="#9" data-toggle="tab">
								Co-Founder
							</a>
						</li>
						<li>
							<a href="#10" data-toggle="tab">
								Advisory Board
							</a>
						</li>
					</ul>
				</div>

				<div class="tab-content ">
					<div class="tab-pane active" id="1">
						<h3 class="pt-3">Founder</h3>
						<div class="row">
							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./srihari_kotela">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/shri-hari.jpg"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0" style={{ color: "#000" }}>
												Srihari Kotela
											</h5>
										</a>
										<small>Standard Specialist</small>
										<br />
										<small>
											Ex Founder Foodcert India (Now TQ Cert Services, A TATA
											group company)
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="tab-pane" id="9">
						<h3 class="pt-3 pb-3">Co-Founder</h3>
						<div class="row">
							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./surbhi_agarwal">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/Surbhi_Agarwal.jpg"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Surbhi Agarwal</h5>
										</a>
										<small>Co Founder</small>
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="10">
						<h3 class="pt-3 pb-3">Advisory Board</h3>
						<div class="row">
							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./mr_sanjay_dave">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/MrSanjayDave.png"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Mr. Sanjay Dave</h5>
										</a>
										<small>Food Safety Specialist</small>
										<br />
										<small>Ex Global Chair Person Codex</small>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./mr_anil_jauhri">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/anil-jauhri.png"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Mr. Anil Jauhri</h5>
										</a>
										<small>Standard Specialist</small>
										<br />
										<small>Ex CEO NABCB</small>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./ms_shashi_sareen">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/Ms-Shashi-Sareen.jpg"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Ms Shashi Sareen</h5>
										</a>
										<small>Ex CEO &amp; Director</small>
										<br />
										<small>
											Export Inspection Council of India and Ex Director, Bureau
											of Indian Standards
										</small>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./dr_leslie">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/DrLeslieDBourquin.png"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Dr. Leslie D. Bourquin</h5>
										</a>
										<small>Food Safety Specialist</small>
										<br />
										<small>
											Dept Chairperson Food safety, Michigan State University
										</small>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-md-6">
								<div class="team-item bg-light">
									<div class="overflow-hidden" style={{ background: "white" }}>
										<center>
											<a href="./mr_baskar_kotte">
												<img
													style={{ width: "231px", height: "231px" }}
													class="img-fluid"
													src="../images/BaskarKotte.jpg"
													alt=""
												/>
											</a>
										</center>
									</div>
									<div
										class="position-relative d-flex justify-content-center"
										style={{ marginTop: "-23px", background: "white" }}>
										<div
											class="bg-light d-flex justify-content-center pt-2 px-1"
											style={{ background: "white" }}>
											<a
												class="btn btn-sm-square  mx-1 text-white"
												href="#"
												style={{ background: "#00a039", borderRadius: "0px" }}>
												<i class="fa fa-facebook"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												{" "}
												<i class="fa fa-twitter"></i>
											</a>
											<a
												class="btn btn-sm-square btn-primary mx-1 text-white"
												href="#"
												style={{
													background: "#00a039",
													borderRadius: "0px",
													border: "none",
													display: "flex",
												}}>
												<i class="fa fa-instagram"></i>
											</a>
										</div>
									</div>
									<div class="text-center p-4" style={{ background: "white" }}>
										<a href="">
											<h5 class="mb-0">Mr. Baskar Kotte</h5>
										</a>
										<small>Standard Specialist</small>
										<br />
										<small>Founder Quality System Enhancement Inc, USA</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Ourteam;
