import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Surbhi_Agarwal = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/Surbhi_Agarwal.jpg"
            alt=""
          />

          <h4 className="">Surbhi Agarwal</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
           
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Director, Arete Consultants Pvt Ltd, a customised software
                development company with a focus on working with both national
                and international clients, I bring a unique perspective and
                skillset to the table. With over 8 years of experience in the
                software industry, I have honed my expertise in delivering
                software solutions that meet the needs of diverse clients across
                different markets and industries.
              </li>
              <li>
                {" "}
                At Arete Software, a division of Arete Consultants, she played a
                pivotal role in building and leading a team of experts in
                software development, project management, and customer service.
                Arete successfully delivered high-quality software solutions to
                clients both locally and abroad, helping them achieve their
                business objectives and stay ahead of their competition.
              </li>
              <li>
                Worked with the government, interacted at the Secretary level
                with various Central and State Ministries. Played a pivotal part
                in development of various projects within the Ministry of
                Agriculture and have represented them at multiple forums
                including FICCI and Assocham.
              </li>{" "}
              <li>
                Also worked with startups, corporates and their mergers working
                on the client strategies, projections, valuations, as well as
                presentations for investor meetings along with MIS presentations
                for the Board.
              </li>{" "}
             
            </ul>

            <br />
            <b>Education</b>
            <br />
            
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Master of Business Administration (MBA), Finance, International
                Business and Strategy from FORE School of Management, New Delhi
                in 2015.
              </li>{" "}
              
              
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Surbhi_Agarwal;
