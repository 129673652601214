import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/chacko_jacob.jpg"
            alt=""
          />

          <h4 className="">Mr. Chacko Jacob</h4>
          <p className="">Co-founder and Chief Business Officer Mist EO</p>
          <p className="">Precision and Remote Sensing Expert</p>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <p>
              Co-founder and Chief Business Officer of MistEO. In this role,
              Chacko leads setting up strategic direction, overall business
              development and subject matter expert within for the agriculture
              business.
            </p>

            <b>Past</b>
            <br />
            <p>
              Before founding MistEO, Chacko served as a Global Agribusiness
              Lead at IBM where he led the implementation of weather based
              solutions globally in the digital agriculture marketplace
              providing expertise in innovative technologies, such as precision
              agriculture, geospatial technologies and other information
              technology tools. He played a pivotal role in rolling out Watson
              Decision Support Platform for Agriculture and establishing several
              business partnerships for the IBM agriculture offerings.
            </p>

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Bringing over 20 years of experience from within the agriculture
                industry, he has held various positions across the industry
                where he was responsible for implementing precision agriculture
                solutions and remote sensing technologies into major precision
                agriculture consulting firms and crop science companies.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                He worked in Syngenta in a global role prior to IBM. Chacko held
                senior management position at Farmers Edge where he oversaw the
                technology development and business partnerships, and assisted
                in the expansion of operations to various countries. He also
                held positions at Decisive Farming Corporation, PCI Geomatics,
                the Minnesota Department of Transportation, and Bayer Crop
                Science.
              </li>{" "}
            </ul>

            <br />

            <b>Educaation</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Masters of Science in Geographic Information Science (GIS) from
                the University of Minnesota.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Bachelor of Science in Agriculture from the University of
                Allahabad.
              </li>{" "}
            </ul>
            <br />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Shashi_sareen;
