import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, getDataPDF } from "../utils/fetchData";
import LoginRequest from "./loginRequest";
import ProfileBanner from "./profileBanner";

export default function Myprofile() {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const communitiesArray = [1, 2];
	const [value, setValue] = useState("");
	const [ebookArray, setEbookArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (auth.user) {
			getDataAPI(`getSavedBooks/${auth.user.id}`, auth.token).then((res) => {
				console.log("res get books", res.data);
				// setCommunitiesArray(res.data.community);
				setEbookArray(res.data.books);
			});
		}
	}, [auth]);

	const fileConversion = (file) => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		var newfile = {
			file: file,
		};
		getDataPDF(`getCoursePdf`, newfile).then((res) => {
			console.log("res my courses 11 ", res.data);

			const file = new Blob([res.data], { type: "application/pdf" });
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			// setPdfFile(fileURL);
			window.open(fileURL);
			// setViewChange(false);
			// setStartTest("");
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		});
	};

	return (
		<>
			{/* <Header /> */}
			<Navbar />
			{auth.user ? (
				<>
					<ProfileBanner />

					<div className="row pt-3 justify-content-around">
						{/* <div className="col-1"></div> */}
						<div
							className="col-8 card"
							style={{
								//   marginRight: "75px",
								border: "none",
								borderRadiusTop: "15px",
								//   marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-8"
									style={{ display: "flex", alignItems: "center" }}>
									My Profile
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-3 uc-searchBar">
									{/* <input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input> */}
								</div>
							</div>
							<div>
								<div className="row p-3 justify-content-around">
									<div
										className="col-12 card"
										style={{
											border: "none",
											borderRadiusTop: "15px",
											borderRadius: "15px",
										}}>
										<div>
											<span className="ebook">Name:</span>
											&nbsp;
											<span
												style={{
													// textDecoration: "underline",
													fontWeight: "500",
												}}>
												{auth.user?.fullname}
											</span>
										</div>
										<div>
											<span className="ebook">Email:</span>
											&nbsp;
											<span
												style={{
													// textDecoration: "underline",
													fontWeight: "500",
												}}>
												{auth.user?.email}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
}
