import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/BaskarKotte.jpg"
            alt=""
          />

          <h4 className="">Mr. Baskar Kotte</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <p>
              Founder and president of Quality Systems Enhancement, Inc., of
              Roswell, GA. United States . An auditing, consulting and training
              organization dedicated to QMS, OHSMS, EMS, EnMS, Lean, Six Sigma
              and Operational Excellence.
            </p>
            <p>
              Original and active member of US Technical Advisory Groups ISO/TC
              176, TC 207 and TC 242, the technical committees responsible for
              writing ISO 9000 family of Quality Management Standards, ISO 14000
              series of Environmental Management Standards and ISO 50000 family
              of Energy Management System Standards. Chair SC3 for US TAG ISO/TC
              176 and ISO/TC 176 Liaison to International Accreditation
              Forum(IAF).
            </p>
            <p>
              Senior member of American Society for Quality, TAPPI and associate
              member of AICC. Served as the Standards Committee Chair of the ASQ
              Quality Audit Division.
            </p>
            <p>
              Assisted over 800 facilities for ISO Related Certifications in
              North America
            </p>

            <b>
              Assisted in developing, implementing and achieving certification
              thorough a proven discipline path for the following standards:
            </b>
          
            <p>
              ISO 9001, ISO 13485, ISO 14001, ISO 15189, ISO 15378, IATF 16949
              (formerly ISO/TS 16949), ISO/IEC 17025, ISO 45001(formerly OHSAS
              18001), ISO 22000, ISO 27001, ISO 50001, ISO 55001, AS 9100 and TL
              9100, Global Gap, FSSC 22000, FDA Gluten Free Labeling &
              Certification, HACCP, HARPC, Hygiene Excellence & Sanitation
              Excellence and PCQI
            </p>

            <b>Some of Honors and Awards</b>

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                U.S. Delegate for ISO 10019 guidelines for selection and use of
                QMS consultants.{" "}
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                U.S Delegate for 10004 guidelines for monitoring and measuring
                customer satisfaction{" "}
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                U.S Delegate for 10008 guidelines for Customer Satisfaction-
                Guidelines for business-to-consumer electronic transactions{" "}
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Member of the TC176 Chairman’s Strategic Advisory Group (Joint
                Group with ISO/CASCO and the IAF) July 2010-July 2014, Geneva,
                Switzerland{" "}
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Chair Subcommittee 3 (SC3) and Membership Chair for United
                States Technical Advisory Group ISO/ TC 176{" "}
              </li>{" "}
            </ul>
            <br />
            <b>Certifications:</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Certified Quality Engineer (CQE)
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Certified Quality Auditor (CQA)
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Exemplar Global Certified QMS Lead Auditor
              </li>{" "}
            </ul>
            <br />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Shashi_sareen;
