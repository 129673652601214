import React from "react";
import Navbar  from "../components/Navbar";
import Footer from "../common/footer";
const Digitallibrary = () => {
  return (
    <>
      <Navbar />
      <div className="row" style={{ paddingLeft: "50px", paddingTop: "50px" }}>
        <div className="col-4">
          <div
            style={{
              fontFamily: "Manrope",
              fontSize: "52px",
              fontWeight: "600",
              lineHeight: "52px",
              letterSpacing: "-0.02em",
              textAlign: "left",
            }}
          >
            Digital Library
          </div>

          <div className="row">
            <div
              style={{
                fontFamily: "Manrope",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "14px",
                letterSpacing: "-0.02em",
              }}
            >
              Lorem ipsum dolor sit amet consectetur. Augue quam volutpat cursus
              eu viverra integer. Risus interdum quis orci egestas venenatis.
            </div>
          </div>
        </div>
        <div className="row" style={{paddingBottom:"100px",paddingTop:"100px"}}>
          <div className="col">
            <div>
              <img src="../images/circle1.png" alt="" />
            </div>
            <div style={{ textAlign: "end" }}>
              <img src="../images/circle5.png" alt="" />
            </div>
          </div>
          <div className="col">
            <div>
              <img src="../images/circle2.png" alt="" />
            </div>
            <div>
              <img src="../images/circle4.png" alt="" />
            </div>
          </div>
          <div className="col">
            <div style={{ marginLeft: "-150px" }}>
              <img src="../images/circle3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Digitallibrary;
