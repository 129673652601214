import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/Omveer-Singh.jpg"
            alt=""
          />

          <h4 className="">Dr. Omveer Singh</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Advisor to National Dairy Development Board from March 2023.
                Helping in setting up multistate cooperative business entities
                in the areas of Seeds, Organic Food under the aegis of Ministry
                of Cooperation .Govt. of India.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                In the role of Director in NDDB Dairy Services, Delhi and Mother
                Dairy Fruits & Vegetables Pvt. Ltd and member of Advisory Board
                in Amit university –College of Agriculture.
              </li>{" "}
            </ul>

            <b>Past</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Deputy Managing Director of Mother Dairy Fruits & Vegetables
                Pvt. Ltd . heading Dairy, Horticulture and Oil businesses of
                Mother Dairy and taking organization to the new growth
                trajectory with his leadership abilities.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Managing Director of NDDB Dairy Services (NDS), a Wholly Owned
                Subsidiary of NDDB for more than six years.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Joined NDDB’s initiative in 1995 Horticulture Business which
                later came to be known as the SAFAL Business of MDFVPL.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Field Fresh Foods Pvt. Ltd.as Head- Agri. Business Development
                and Corporate Social Responsibility (CSR) for more than 5 years.
              </li>{" "}
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Chief Executive Officer ,Champion Agro Fresh Pvt. Ltd.
              </li>{" "}
            </ul>
            <br />
            <b>Education</b>
            <br />

            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Doctorate Degree in Genetics & Plant Breeding from Agra
                University, Agra.
              </li>{" "}
             
            </ul>
            <br />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Shashi_sareen;
