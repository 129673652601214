import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Dr_santosh_j_eapen = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/DrSanthoshJEapen.png"
            alt=""
          />

          <h4 className="">Dr. Santhosh J. Eapen</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                President, Indian Society for Spices
              </li>
              <li>
                {" "}
                Member ,Food Safety Standards Authority of India Scientific
                Panel on Spices & Culinary Herbs
              </li>

              <br />
            </ul>
            <b>Past</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Former Principal Scientist &Head ICAR Indian Institute of Spices
                Research 2016-2022
              </li>
              <li>
                A research Scientist with over 30 Years of experience in Spices
                and Plantation Crops
              </li>{" "}
            </ul>
            <br />
            <b> Recognitions</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Research Publications: 95 (International 39, National 56)
              </li>
              <li>Patents filed 2</li>
              <li>
                Nematology consultant in Vietnam: Sep - Oct 2009 (FAO, Rome)
              </li>{" "}
              <li>
                Chairman, 8th Meeting of the R&D Committee of International
                Pepper
              </li>
              <li>
                Community, Kuching, Malaysia: 02-03 May 2019 (IPC, Jakarta)
              </li>{" "}
            </ul>

            <br />
            <b> Member in National Bodies</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Fellow, Indian Society for Spices, Kozhikode
              </li>
              <li>Fellow Indian Society for Plantation Crops ,Kasaragod</li>{" "}
            </ul>

            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Ph.D. in Zoology
              </li>{" "}
              <li>MSc in Zoology</li>{" "}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Dr_santosh_j_eapen;
