import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Dr_ms_rao = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <center>
          <img
            style={{ width: "201px", height: "201px" }}
            //   class="img-fluid"
            src="../images/Dr-MS-Rao.png"
            alt=""
          />

          <h4 className="">Dr MS Rao</h4>
        </center>
        <div className="row">
          <div class="col" style={{ paddingLeft: "50px" }}>
            <br />
            <b>Present</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Former Head & Principal Scientist of ICAR – Indian Institute of
                Horticultural Research, Government of India.
              </li>
              <li>
                {" "}
                30 years of comprehensive experience in Project Management and
                Sustainable Agriculture, Campaigner for Sustainable Agriculture,
                Former Task Force Committee member of Department of Science and
                Technology & Department of Bio Technology, Govt of India.
              </li>
              <li>
                Former Consultant of FAO for promoting the use of bio pesticides
                in organic farming in Karnataka State
              </li>{" "}
              <li>
                {" "}
                INSA-Visiting scientist Royal Society of London, UK (1995)
              </li>
              <li>Fellow of National Academy of Agricultural Sciences</li>
              <br />
            </ul>
            <b>Awards and Recognitions</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                DST-FICCI Award - Selected among the top 10 innovators in India.
                His technology has been selected for commercialization by IC2
                Institute of Texas University, Austin, USA under DST- Lockheed
                Martin Innovative Program and Department of Science and
                Technology, New Delhi.
              </li>
              <li>
                Received Dr. Vijyalakshmi Mojunder Memorial Award from ICAR-IARI
              </li>{" "}
            </ul>
            <br />
            <b>Member in National Bodies</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                Former Member of Task Force Committee, Department of
                Biotechnology, Ministry of Science and Technology
              </li>
              <li>
                Former Member of Task Force Committee, Department of Science and
                Technology ,Ministry of Science and Technology
              </li>
            </ul>
            <br />
            <b>Patents</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                He was granted seven International and one National Patent on
                Bio Pesticides
              </li>{" "}
            </ul>
            <br />
            <b>Education</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                Ph.D. in Agriculture Nematology – Indian Agriculture Research
                Institute, New Delhi
              </li>{" "}
              <li>
                {" "}
                Masters in Agriculture Nematology -Indian Agriculture Research
                Institute New Delhi{" "}
              </li>
              <li>
                {" "}
                Graduation in B. Sc (Ag) from Andhra Pradesh Agricultural
                University{" "}
              </li>
            </ul>

            <br />
            <b>Contribution to Nation</b>
            <br />
            <br />
            <ul className="mx-5">
              <li
                style={{
                  listStyle: "disc",
                  fontSize: "16px",
                  //   fontWeight: "500",
                }}
              >
                {" "}
                He was instrumental in transferring Bio Pesticide technologies
                to 620 industry licensees in India. These industries are
                producing more than 800 crores Bio Pesticides per year in India,
                which is used in millions of hectares.
              </li>{" "}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Dr_ms_rao;
