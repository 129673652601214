import React, { useState } from "react";

const Announcement = () => {
	const [announcementArray, setAnnouncementArray] = useState([1, 2]);
	return (
		<>
			<div style={{ background: "#2DB1B9", padding: "10px 15px" }}>
				<div className="row">
					<div
						className="col-4"
						style={{
							display: "flex",
							justifyContent: "flex-start",
							paddingLeft: "90px",
							alignItems: "center",
							color: "#fff",
							cursor: "pointer",
						}}
						onClick={() => (window.location.href = "./communitykrishigap")}>
						<img
							src="./images/Mobile-back-arrow.svg"
							className="uc-announce-pin-img"
							width="20px"
							height="20px"
						/>{" "}
						Back to feeds
					</div>
					<div
						className="col-5"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							color: "#fff",
						}}>
						Announcement
					</div>
					<div
						className="col-3"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							color: "#fff",
						}}>
						<img
							src="./images/Pin_icon.svg"
							className="uc-announce-pin-img"
						/>
					</div>
				</div>
			</div>
			{announcementArray.map(() => {
				return (
					<>
						<div className="row pt-4">
							<div className="col-4"></div>
							<div className="card row col-5" style={{ borderRadius: "15px" }}>
								<div
									className="row"
									style={{
										padding: "15px",
									}}>
									<div className="col-12">
										<div className="row">
											<div
												className="col-2"
												style={{
													width: "auto",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													paddingRight: "0px",
												}}>
												<img
													src="./images/krishigaplogo.png"
													width="40px"
													height="40px"></img>
											</div>
											<div className="col-8">
												<div style={{ fontSize: "15px", fontWeight: "500" }}>
													Dr. Sadiq Sait M.S
												</div>
												<div style={{ fontSize: "12px", fontWeight: "500" }}>
													13 Feb 2024 at 10:32 am
												</div>
											</div>
											<div
												className="col-2"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<img
													src="./images/options.svg"
													alt=""
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									className="row"
									style={{
										padding: "0px 15px 5px 30px",
										fontSize: "14px",
										fontWeight: "400",
									}}>
									Today, KrishiGAP team is meeting with with Agricultural
									Production Commissioner to discuss the potential opportunities
									for the implementation of IndiGAP Certification.
								</div>

								<div
									className="row"
									style={{
										display: "flex",
										justifyContent: "space-between",
										padding: "10px 10px",
										//   borderBottom: "1px solid #ddd",
									}}>
									<div className="col-9" style={{ fontSize: "13px" }}>
										<img
											src="./images/Like-fill-icon.svg"
											alt=""
											width="20px"
											height="20px"></img>
										<span style={{ paddingLeft: "10px", fontSize: "13px" }}>
											1
										</span>
									</div>
									<div
										className="col-3"
										style={{
											display: "flex",
											alignItems: "end",
											justifyContent: "end",
											fontSize: "13px",
											fontWeight: "400",
										}}>
										0 comments
									</div>
								</div>
								<hr style={{ marginBottom: "0px" }} />
								<div className="row" style={{ padding: "15px 15px" }}>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img src="./images/Liked.svg" width="26px"></img>&nbsp;Like
									</div>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img src="./images/Comments.svg" width="26px"></img>&nbsp;
										Comments
									</div>
								</div>
							</div>
							<div className="col-3"></div>
						</div>
					</>
				);
			})}
		</>
	);
};

export default Announcement;
