import React from "react";
import Footer from "../common/footer";
import Navbar from "../components/Navbar";
// import Header from "../components/header/Header";

const Platformfeatures = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <h1 className="" style={{ textAlign: "center" }}>
          Platform Features
        </h1>

        <div className="row">
          <div class="col" style={{}}>
            <ul style={{ paddingLeft: "" }}>
              <h5>Food Safety Academy: </h5>{" "}
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                An AI-powered, cloud-based platform for global standards
                education.
              </li>
              <h5>Self-Paced & Instructor-Led Courses: </h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                Designed for skill enhancement in food safety and
                sustainability.
              </li>
              <h5>Digital Library: </h5>{" "}
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                A rich repository of eBooks, videos, and other learning
                resources.
              </li>
              <h5>Professional Networking Space: </h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                A discussion board for connecting professionals across the food
                industry.
              </li>
              <h5>Internships & Job Placements: </h5>
              <li className="mx-5 mb-4" style={{ listStyle: "disc" }}>
                {" "}
                Opportunities for hands-on experience and career advancement.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Platformfeatures;
