import React from "react";
import Footer from "../common/footer";
import Navbar from "./Navbar";

const Contactus = () => {
  return (
    <>
      <Navbar />
      {/* <div>
        <img
          src="../images/homebanner.jpg"
          alt="krishi-gap-logo"
          style={{ height: "500px", width: "auto" }}
        />
      </div> */}
      <div class="container-fluid bg-primary py-5 mb-5 page-header">
        <div class="container py-5">
          <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
              <h1 class="display-3 text-white animated slideInDown">
                Contact &amp; Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item">
                    <a class="text-white" href="index.php">
                      Home
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Contact &amp; Us
                  </li>
                </ol>
                <a href="/home" class="btn btn-success btn-sm">
                  Go Back
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xxl py-5">
        <div class="container">
          <div
            class="text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <h6
              class="section-title  text-center  px-3"
              style={{ color: "#2FBBCC" }}
            >
              CONTACT US
            </h6>
            <h1 class="mb-5">Contact For Any Query</h1>
          </div>
          <div class="row g-4">
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h5>Kotela Srihari</h5>
              <div class="d-flex align-items-center mb-3">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#2FBBCC",
                  }}
                >
                  <i class="fa fa-light fa-location-arrow text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="" style={{ color: "#2FBBCC" }}>
                    Office
                  </h5>
                  <p class="mb-0">Krishigap Digital Solutions Pvt ltd</p>
                  <p class="mb-0">CIN U62013TS2023PTC172865</p>
                  <p class="mb-0">
                    House No 5-106/281B, Narsing Municipality Manchirevula K.V
                    Ranga Reddy Hyderabad 500075 Telangana State
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#2FBBCC",
                  }}
                >
                  <i class="fa fa-light fa-phone text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="" style={{ color: "#2FBBCC" }}>
                    Mobile
                  </h5>
                  <p class="mb-0">
                    <a style={{ color: "#52565b" }} href="tel:+919848034740">
                      +91-9848034740
                    </a>
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="d-flex align-items-center justify-content-center flex-shrink-0"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#2FBBCC",
                  }}
                >
                  <i class="fa fa-envelope-open text-white"></i>
                </div>
                <div class="ms-3">
                  <h5 class="" style={{ color: "#2FBBCC" }}>
                    Email
                  </h5>
                  <p class="mb-0">
                    <a
                      style={{ color: "#52565b" }}
                      href="mailto:Srihari@krishigap.com"
                    >
                      Srihari@krishigap.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <iframe
                class="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.65940366535!2d78.49873941384925!3d17.38011470759183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb984b1d296f4f%3A0x89139a561673ed35!2s3-4%2C%20Ajantha%20Colony%2C%20Narayanguda%2C%20Hyderabad%2C%20Telangana%20500036!5e0!3m2!1sen!2sin!4v1665507300441!5m2!1sen!2sin"
                frameborder="0"
                style={{ minHeight: "300px", border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div
              class="col-lg-4 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <form method="get">
                <div class="row g-3">
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        required=""
                        value=""
                      />
                      <label for="name">Your Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        type="email"
                        name="mail"
                        class="form-control"
                        id="email"
                        placeholder="Your Email"
                        required=""
                        value=""
                      />
                      <label for="email">Your Email</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="text"
                        name="subject"
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                        required=""
                        value=""
                      />
                      <label for="subject">Subject</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        name="message"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: "150px" }}
                        required=""
                      ></textarea>
                      <label for="message">Message</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <h4> Solve this, 5+4?</h4>
                    <input type="hidden" name="answer" value="9" />
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      class="form-control quiz-control"
                      id="quiz"
                      placeholder="Enter Your Answer"
                      name="input_answer"
                      required=""
                    />
                  </div>
                  {/* <!--<div class="col-12">-->
                            <!--    <div class="form-floating">-->
                            <!--        <div class="g-recaptcha" data-sitekey="6LfwWTwnAAAAAEewaNprSQgIb4_ykWFWioQTntjh"></div>-->
                            <!--    </div>-->
                            <!--</div>--> */}
                  <div class="col-12">
                    <button
                      class="btn btn-primary w-100 py-3"
                      name="send"
                      type="submit"
                      id="sendmsg"
                      style={{
                        background: "green",
                        border: "none",
                        borderRadius: "0px",
                      }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer className="jnav"></Footer>
      </div>
    </>
  );
};

export default Contactus;
