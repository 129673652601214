import React from "react";
import Footer from "../common/footer";
import Navbar from "./Navbar";

const Onfarmproduction = () => {
  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col" style={{ height: "386px" }}>
          <img
            src="../images/homebanner2.jpg"
            style={{ width: "", height: "" }}
          />
        </div>
      </div>
      <div
        class="container-xxl py-5"
        style={{
          paddingTop: "0rem !important",
          paddingBottom: "0rem !important",
        }}
      >
        <div class="container">
          <h1 class="text-center">On Farm Production</h1>
          <ul class="text-justify">
            <li>
              In this section, we have covered India's Good Agricultural
              Practices, Global G.A.P, and Organic Certification NPOP &amp; NOP.
              Standard-wise Quality Manuals, Procedures, Farmers' records (in
              local anguages), Information on Plant Protection Products, Package
              of practices, Harvest, and Postharvest standards, inspectors'
              qualifications, Workers' Health and Welfare, and Government
              promotion schemes are available.
            </li>
            <li>
              {" "}
              You can search for the required information standard-wise by
              clicking on the respective icons.
            </li>
            <li>
              {" "}
              Additionally, we offer skill development programs on the
              implementation of these standards and the qualification of
              inspectors conducted digitally.
            </li>
          </ul>

          <div class="row g-4">
            <div class="col-md-12">
              <div class="row">
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <img
                      style={{ height: "auto", width: "100%" }}
                      src="./images/foodsafetystandard.jpg"
                    />

                    <div class="fontWhite">Food Safety Standards</div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="farmer-hand-book.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/farmerhandbook.jpg"
                        />
                      </div>
                      <div class="fontWhite">Farmer Hand Book</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="demo-farm.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/demofarm.jpg"
                        />
                      </div>
                      <div class="fontWhite">DEMO FARM</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="training-module.php">
                      <img
                        style={{ height: "auto", width: "100%" }}
                        src="./images/skills-development.jpg"
                      />

                      <div class="fontWhite">SKILL DEVELOPMENT</div>
                    </a>
                  </div>
                </div>

                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="internal-inspection.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/Internalinspection.jpg"
                        />
                      </div>
                      <div class="fontWhite">Internal Inspection</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="internal-audit.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/internalaudit.jpg"
                        />
                      </div>
                      <div class="fontWhite">Internal Audit</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="plant-protection.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/plantprotectionproduct.jpg"
                        />
                      </div>
                      <div class="fontWhite">Plant Protection Products</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="Package-of-practices.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/PackageofPractices.jpg"
                        />
                      </div>
                      <div class="fontWhite">Package of Practices</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="harvesting.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/harvesting.jpg"
                        />
                      </div>
                      <div class="fontWhite">Harvest &amp; Post Harvest</div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="other-option.php?nm=APEDA Approved Pack Houses&amp;type=search">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/farm.jpg"
                        />
                      </div>
                      <div class="fontWhite">
                        Government Promoting Institutions
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="workers-health.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/workershealth.jpg"
                        />
                      </div>
                      <div class="fontWhite">
                        Workers Health, Safety &amp; Welfare
                      </div>
                    </a>
                  </div>
                </div>

                <div
                  class="col-lg-3 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div class="cardbgc hovercard text-center position-relative">
                    <a href="crops-standards.php">
                      <div class="m-img">
                        <img
                          style={{ height: "auto", width: "100%" }}
                          src="./images/plantnutritionalmanagement.jpg"
                        />
                      </div>
                      <div class="fontWhite">Crops Standards</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer className="jnav"></Footer>
      </div>
    </>
  );
};

export default Onfarmproduction;
