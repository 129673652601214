import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

export default function LinkAction(props) {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [showUploadLinkModal, setShowUploadLinkModal] = useState(false);
	const [linkName, setLinkName] = useState("");
	const [linkLink, setLinkLink] = useState("");
	const [linkSource, setLinkSource] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		// getDataAPI(`getlanguages`, auth.token).then((res) => {
		// 	// console.log("res getlanguages", res.data);
		// 	// setCommunitiesArray(res.data.community);
		// 	setLanguages(res.data.languages);
		// });
		// setDlCategorieArray(props.categoriesArray);
		setLanguages(props.languagesArray);
	}, [props.languagesArray]);

	const handleEditLink = async (link) => {
		// console.log("link", link);
		setLinkName(link.name);
		setLinkLink(link.link);
		setLinkSource(link.source);
		setSelectedLanguage(link.languageid);
		setTimeout(() => {
			setShowUploadLinkModal(true);
		}, 100);
	};

	const handleSetLinkInactive = async (link) => {
		// console.log("link", link);
		getDataAPI(`inactivelink/${link.id}`, auth.token).then((res, err) => {
			// console.log("res inactivelink", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};

	const handleUploadLink = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowUploadLinkModal(false);

		let item = {
			id: props.link.id,
			name: linkName,
			link: linkLink,
			source: linkSource,
			languageid: selectedLanguage,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
		};
		// console.log("item", item);
		const formData = new FormData();

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("updatelink", formData, auth.token);
			// console.log("res", res.data);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal
				show={showUploadLinkModal}
				onHide={() => setShowUploadLinkModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Edit Link
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={() => setShowUploadLinkModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div className="row">
								<div style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
									<div style={{ fontSize: "13px" }}>Language:</div>
									<select
										name="language"
										value={selectedLanguage}
										onChange={(e) => setSelectedLanguage(e.target.value)}>
										<option value="">Select</option>
										{languages.map((e) => {
											return (
												<option value={e.id} key={e.id}>
													{e.name}
												</option>
											);
										})}
									</select>
									<span style={{ marginLeft: "30px" }}>
										<Button
											onClick={() => {
												props.languages();
												// setShowLanguageModal(true);
												// setShowUploadModal(false);
											}}
											style={{ background: "#20ce8f", border: "none" }}>
											Add
										</Button>
									</span>
								</div>
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={linkName}
										placeholder="Name/ Title *"
										onChange={(e) => setLinkName(e.target.value)}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
								<div className="col-12" style={{ marginTop: "15px" }}>
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={linkSource}
										placeholder="Source *"
										onChange={(e) => setLinkSource(e.target.value)}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
								<div className="uc-modal-body">
									<div className="uc-detail-btn-wrap uc-share-btn-wrap">
										<div className="row">
											<div className="col-12" style={{ marginTop: "15px" }}>
												<input
													type="text"
													className="form-control"
													id="copytext"
													value={linkLink}
													onChange={(e) => setLinkLink(e.target.value)}
													//   defaultValue="ulektz.com/c/krishigap"
													style={{ backgroundColor: "#fff" }}
													placeholder="Enter link *"
												/>
											</div>
											{/* <div
												className="col-2"
												style={{
													paddingLeft: "0px",
													marginTop: "15px",
												}}>
												<a
													href="javascript:void(0)"
													// onClick="copyCommunityUrl()"
													className="copybuttonaddlink">
													{/* <img src="../images/Copyshare_link.svg" alt="copy" />
													<span style={{ color: "#fff", paddingLeft: "4px" }}>
														Copy
													</span> */}
											{/* <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> *
												</a>
											</div> */}
										</div>
									</div>
								</div>
								{/* <div
                  className="col-3"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <a
                    href="javascript:void(0)"
                    onClick="copyCommunityUrl()"
                    className="copybutton"
                  >
                    <img src="../images/Copyshare_link.svg" alt="copy" />
                    <span style={{ color: "#fff", paddingLeft: "4px" }}>
                      Copy
                    </span>
                    <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div>
                  </a>
                </div> */}
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
								}}>
								{/* <input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post */}
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadLink()}>
										Edit
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<div>
				<Button
					style={{
						// background:
						// 	"#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-warning mt-2 col-12"
					onClick={() => handleEditLink(props.link)}>
					<i class="fas fa-edit"></i>
					Edit link
				</Button>
				<Button
					style={{
						background: "#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-danger mt-2 col-12"
					onClick={() => handleSetLinkInactive(props.link)}>
					<i class="fa fa-trash" aria-hidden="true"></i> Delete link
				</Button>
			</div>
		</>
	);
}
